import React from 'react';

import { useIsMobile } from 'hooks/useIsMobile';
import { formatCPF } from 'utils/textFormatUtils';

import { useLangContext } from 'components/Globalization';

import { MobileGrid, StyledTableCell, Tag } from '../../styles';

const CustomTableRow = ({ row }) => {
  const isMobile = useIsMobile();
  const translate = useLangContext();
  const { name, cpf, schoolName, inep, learningPhaseName, requestStatus } = row;

  const tableRows = (
    <>
      <StyledTableCell order={1}>{name}</StyledTableCell>
      <StyledTableCell align="left" order={3}>
        {formatCPF(cpf, true)}
      </StyledTableCell>
      <StyledTableCell align="left" order={4}>
        {schoolName} - {inep}
      </StyledTableCell>
      <StyledTableCell align="left" order={5}>
        {learningPhaseName}
      </StyledTableCell>
      <StyledTableCell align="center" order={2}>
        <Tag>{translate(`REQUEST_STATUS:${requestStatus}`)}</Tag>
      </StyledTableCell>
    </>
  );

  if (!isMobile) {
    return tableRows;
  }
  return (
    <MobileGrid container item sx={{ flexDirection: 'column', flexGrow: 3 }}>
      {tableRows}
    </MobileGrid>
  );
};

export default CustomTableRow;
