import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, IconButton, Typography } from '@mui/material';

import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import SelectSchoolModal from 'components/SelectSchoolModal';
import wrapper from 'components/Wrapper';

import { useRequestAccessContext } from '../../../../Context';
import { SchoolName } from '../../../RequestAccessTeacher/styles';
import ActionsButtons from '../ActionsButtons';
import { StyledBox } from '../styles';

const EducationUnit = () => {
  const translate = useLangContext();
  const { fnNext } = useStepperContext();
  const { requestData, setRequestData, requestFunctions, isEditing } =
    useRequestAccessContext();
  const { editRequestSchool } = requestFunctions;
  const {
    resetEducationUnit,
    schoolName: requestedSchool,
    inep,
    id,
    role
  } = requestData || {};

  const formMethods = useForm({});

  const [openModal, setOpenModal] = useState(false);
  const [school, setSchool] = useState({
    name: requestedSchool,
    id: inep
  });

  const closeModal = selectedSchool => {
    setOpenModal(false);
    setSchool({ name: selectedSchool.schoolName, id: selectedSchool.inep });
  };

  const submitEducationUnit = useCallback(async () => {
    const isDirty = requestData.inep !== school.id;
    const { id: schoolId, name } = school;

    if (!isDirty) {
      fnNext();
      return;
    }

    if (isEditing) {
      setRequestData(prevState => ({
        ...prevState,
        inep: schoolId,
        learningPhase: null,
        learningPhaseName: null,
        schoolName: name,
        resetEducationUnit: false,
        role
      }));
      fnNext();
      return;
    }

    try {
      const resp = await editRequestSchool({
        inep: schoolId,
        id
      });
      setRequestData({ ...resp, role });
      fnNext();
    } catch (error) {
      console.error(error);
    }
  }, [
    requestData.inep,
    school,
    isEditing,
    fnNext,
    setRequestData,
    role,
    editRequestSchool,
    id
  ]);

  useEffect(() => {
    if (resetEducationUnit) setSchool(null);
  }, [resetEducationUnit]);

  return (
    <StyledBox>
      <SelectSchoolModal
        open={openModal}
        handleModal={closeModal}
        school={school}
        filterBy={{ ibge: requestData?.city }}
      />
      <Typography component="h3" variant="h6">
        {translate('SELECT_EDUCATION_UNIT')}
      </Typography>
      <Form formMethods={formMethods} onSubmit={submitEducationUnit}>
        <Box mt={3} mb={3}>
          <Grid container>
            <Grid item md={11} xs={10}>
              <SchoolName onClick={() => setOpenModal(true)}>
                <Typography component="h4" variant="body1">
                  {!school?.name ? translate('SEARCH_SCHOOL') : school.name}
                </Typography>
              </SchoolName>
            </Grid>
            <Grid item xs>
              <Box mt={1}>
                <IconButton
                  variant="outlined"
                  onClick={() => setOpenModal(true)}
                >
                  <SearchIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <ActionsButtons confirmButtonDisable={!school?.id} />
      </Form>
    </StyledBox>
  );
};

export default wrapper(EducationUnit, { namespace: 'REQUEST_ACCESS_COMMONS' });
