import { createContext, useContext } from 'react';

export const RequestAccessContext = createContext({
  requestData: {},
  setRequestData: () => {},
  requestFunctions: () => {},
  setRequestFunctions: () => {},
  redirectIfRoleIsUndefined: () => {}
});

export const useRequestAccessContext = () => useContext(RequestAccessContext);
