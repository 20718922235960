import styled from 'styled-components';

import InfoIcon from '@mui/icons-material/Info';
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Typography
} from '@mui/material';

import Form from 'components/Form';
import SelectField from 'components/SelectField';

export const FilterContent = styled(Grid)`
  align-items: center;
  display: flex;
  width: 100%;
`;

export const StyledContainer = styled(Container)`
  max-width: 90% !important;

  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    max-width: 100% !important;
  }
`;

export const StyledTitle = styled(Typography)`
  &.MuiTypography-root {
    color: ${props => props.theme.palette.primary.main};
    font-size: 34px;
    ${props => props.theme.breakpoints.down('md')} {
      font-size: 28px;
    }
  }
`;

export const StyledFontBold = styled.b`
  font-weight: 500;
`;

export const NewStyledSelectField = styled(SelectField)`
  max-width: 140px;
  min-width: 100%;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    .MuiSelect-select {
      padding: 10px 0 10px 10px !important;
      font-size: 14px !important;
    }
  }
`;

export const StyledBox = styled(Box)`
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 4px;
  width: 340px;

  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    width: 100% !important;
    margin-bottom: 16px !important;
  }

  @media (max-width: ${props => props.theme.breakpoints.values.lg}px) {
    height: 100%;
  }
`;

export const ButtonGrid = styled(Grid)`
  @media (max-width: ${props => props.theme.breakpoints.values.md}px) {
    justify-content: flex-end;

    & .MuiButton-root {
      margin-top: 0px !important;
    }
  }
`;

export const StyledForm = styled(Form)`
  width: 100%;
  margin-bottom: 24px;
`;

export const StyledDividerVertical = styled(Divider)`
  &.MuiDivider-root {
    border-color: ${props => props.theme.palette.primary.main};
    border-width: 1px;
    height: 24px;
    margin-bottom: 4px;
    align-self: flex-end;
  }
`;

export const StyledSegmentName = styled(Typography)`
  color: ${props => props.theme.palette.primary.main};
  text-transform: uppercase;
`;

export const ThickDivider = styled(Divider)`
  &.MuiDivider-root {
    border-color: #d27723;
    border-width: 1.8px;
  }
`;

export const ThinDivider = styled(Divider)`
  &.MuiDivider-root {
    border-color: #d27723;
    border-width: 1px;
  }
`;

export const ButtonClearFilters = styled(Button)`
  &.MuiButton-root {
    margin-top: 24px;
    min-width: 130px;
    background-color: ${props => props.theme.palette.primary.contrastText};
  }
`;

export const SubtitleInteractiveReader = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    color: ${props => props.theme.palette.primary.main};
    font-weight: 600;
    margin-top: 16px;
    ${props => props.theme.breakpoints.down('md')} {
      text-align: center;
      margin-bottom: 23px;
      font-weight: 600;
    }
  }
`;

export const StyledAlert = styled(Alert)`
  &.MuiAlert-root {
    background-color: ${props => props.theme.palette.info.light};
    padding: 24px 16px;
    margin-top: 96px;
  }
`;

export const StyledInfoIcon = styled(InfoIcon)`
  color: ${props => props.theme.palette.primary.main};
`;
export const StyledButtonResourcesWorksTag = styled(Button)`
  &.MuiButton-root {
    background-color: ${props => props.theme.palette.primary.contrastText};
    color: ${props => props.theme.palette.primary.main};
    border: 1px solid ${props => props.theme.palette.primary.main};
  }
`;
