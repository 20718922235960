/* eslint-disable react/prop-types */
import React from 'react';

import { Button, Grid } from '@mui/material';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '../Dialog';

export const ConfirmDialog = ({
  open,
  title,
  confirmButtonText = 'Sim',
  cancelButtonText = 'Não',
  onConfirm,
  onCancel,
  children,
  disabled
}) => (
  <Dialog open={open}>
    {title && <DialogTitle>{title}</DialogTitle>}
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Grid container spacing={2} justifyContent="flex-end">
        {onCancel ? (
          <Grid item>
            <Button id="btnCancel" variant="outlined" onClick={onCancel}>
              {cancelButtonText}
            </Button>
          </Grid>
        ) : null}
        {onConfirm ? (
          <Grid item>
            <Button
              id="btnConfirm"
              variant="contained"
              onClick={onConfirm}
              disabled={disabled}
            >
              {confirmButtonText}
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </DialogActions>
  </Dialog>
);
