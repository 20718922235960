import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Drawer, ListItem, ListItemText } from '@mui/material';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  overflow-y: auto;
`;

export const NavBrand = styled.img`
  margin-right: auto;
  margin-left: auto;
  cursor: pointer;
`;

export const MenuLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0px 16px;
  text-decoration: none;

  ${props => css`
    color: ${props.theme.palette.grey[400]};
  `}
`;

export const MenuItem = styled(ListItemText)`
  color: ${props => props.theme.palette.primary.main};
`;

export const StyledListItem = styled(ListItem)`
  &.MuiButtonBase-root {
    height: 57px;
    &:hover {
      background: ${props => props.theme.palette.primary.main};
      color: ${props => props.theme.palette.primary.contrastText};

      a {
        color: ${props => props.theme.palette.primary.contrastText};
      }

      .MuiListItemText-root {
        color: ${props => props.theme.palette.primary.contrastText};
      }
    }
  }
`;

export const StyledDrawer = styled(Drawer)`
  & .MuiPaper-root {
    width: 360px;
    height: calc(100vh - ${props => props.headerHeight}px);
    margin-top: ${props => props.headerHeight}px;
    overflow-y: auto;
  }
`;
