import React from 'react';
import { Trans } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { useUserAccess } from 'hooks/useConfigureUserAccess';

import AddIcon from '@mui/icons-material/Add';
import { Box, Container, Grid, Hidden } from '@mui/material';

import { ROLES } from 'helpers/roles';

import { AsyncLoad } from 'components/AsyncLoad';
import DownloadApp from 'components/DownloadCards/DownloadApp';
import DownloadDesktop from 'components/DownloadCards/DownloadDesktop';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import ROLES_MAP from 'modules/RequestAccess/constants/rolesMap';
import { REQUEST_ACCESS_ROUTES } from 'modules/RequestAccess/constants/routes';
import { useRequestAccessContext } from 'modules/RequestAccess/Context';

import {
  ButtonDoneContained,
  ButtonDoneOutlined,
  RequestCompletedDescription,
  RequestCompletedTitle
} from './styles';

const RequestCompleted = () => {
  const translate = useLangContext();
  const history = useHistory();
  const { state } = useLocation();

  const { requestData, setRequestData } = useRequestAccessContext();
  const { configureUserAccess } = useUserAccess();

  const { role = ROLES.ROLE_STUDENT, requestPage } = requestData || {};

  const selectedRole = ROLES_MAP.find(item => item.role === role);

  const handleManagementPage = () => {
    history.push(selectedRole.managementPage);
  };

  const handleRequestPage = () => {
    setRequestData({
      role,
      requestPage
    });
    history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
  };

  return (
    <>
      {!state && <AsyncLoad promiseFn={configureUserAccess} />}
      <Container maxWidth="md">
        <Box mt={3}>
          <Grid
            container
            spacing={2}
            justifyContent={{ xs: 'center', md: 'flex-end' }}
          >
            <Grid item xs={12}>
              <RequestCompletedTitle>
                {translate(`${role}.TITLE`)}
              </RequestCompletedTitle>
              <RequestCompletedDescription>
                <Trans
                  t={translate}
                  i18nKey={`${role}.SUBTITLE`}
                  components={{
                    br: <br />
                  }}
                />
              </RequestCompletedDescription>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Box mt={5}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <DownloadApp />
            </Grid>
            <Hidden mdDown>
              <Grid item sm={6}>
                <DownloadDesktop />
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </Container>
      <Container maxWidth="md">
        <Grid item xs={12}>
          {role !== ROLES.ROLE_STUDENT && (
            <Box mt={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                direction={{ xs: 'column', md: 'row' }}
              >
                <Grid item xs={12} md="auto">
                  <ButtonDoneOutlined
                    variant="outlined"
                    onClick={handleManagementPage}
                    id="btn-management-page"
                    fullWidth
                  >
                    {translate(`${role}.MANAGEMENT_PAGE`)}
                  </ButtonDoneOutlined>
                </Grid>
                <Grid item xs={12} md="auto">
                  <ButtonDoneContained
                    variant="contained"
                    onClick={handleRequestPage}
                    id="btn-request-page"
                    fullWidth
                  >
                    <AddIcon />
                    {translate(`${role}.REQUEST`)}
                  </ButtonDoneContained>
                </Grid>
              </Grid>
            </Box>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default wrapper(RequestCompleted, {
  namespace: 'REQUEST_COMPLETED',
  route: { path: REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED }
});
