import React, { useCallback, useState } from 'react';
import { useWatch } from 'react-hook-form';

import { FormLabel, Grid } from '@mui/material';

import { AsyncLoad } from 'components/AsyncLoad';
import ControlGroup from 'components/ControlGroup';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';

import { requestAccessUseCases } from 'modules/RequestAccess/provider';

const StudentForm = ({ formMethods }) => {
  const translate = useLangContext();
  const [disabilityList, setDisabilityList] = useState([]);

  const getDisabilityList = useCallback(async () => {
    try {
      const resp = await requestAccessUseCases.getDisabilityList();

      const disabilityListOptions = resp.map(item => ({
        id: item,
        name: item
      }));

      setDisabilityList(disabilityListOptions);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const { control } = formMethods;

  const hasPhysicalDisabilityWatch = useWatch({
    control,
    name: 'hasPhysicalDisability'
  });

  const booleanOptions = [
    { id: true, name: 'COMMONS:YES' },
    { id: false, name: 'COMMONS:NO' }
  ];

  return (
    <>
      <Grid item xs={12}>
        <FormLabel
          component="legend"
          aria-label={translate('REQUEST_ACCESS_COMMONS:IS_UPPER_TWELVE')}
        >
          {translate('REQUEST_ACCESS_COMMONS:IS_UPPER_TWELVE')}
        </FormLabel>

        <ControlGroup
          labelRenderer={({ name }) => translate(name)}
          formMethods={formMethods}
          type="radio"
          options={booleanOptions}
          name="isUpperTwelveYearsOld"
          row
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel
          component="legend"
          aria-label={translate(
            'REQUEST_ACCESS_COMMONS:DISABILITIES.STUDENT_DISABILITY'
          )}
        >
          {translate('REQUEST_ACCESS_COMMONS:DISABILITIES.STUDENT_DISABILITY')}
        </FormLabel>
        <ControlGroup
          labelRenderer={({ name }) => translate(name)}
          formMethods={formMethods}
          type="radio"
          options={booleanOptions}
          name="hasPhysicalDisability"
          row
        />
      </Grid>
      {hasPhysicalDisabilityWatch && (
        <AsyncLoad promiseFn={getDisabilityList}>
          <Grid item xs={12}>
            <SelectField
              name="physicalDisability"
              label={translate(
                'REQUEST_ACCESS_COMMONS:DISABILITIES.DISABILITY_DESCRIPTION'
              )}
              placeholder={translate(
                'REQUEST_ACCESS_COMMONS:ACCESSIBILITY_PHYSICAL_DEBILITATION'
              )}
              displayEmpty
              options={disabilityList}
              optionRenderer={option =>
                translate(`REQUEST_ACCESS_COMMONS:DISABILITIES.${option.name}`)
              }
              renderDynamically={false}
              formMethods={formMethods}
              multiple
              aria-label={translate(
                'REQUEST_ACCESS_COMMONS:ACCESSIBILITY_PHYSICAL_DEBILITATION'
              )}
            />
          </Grid>
        </AsyncLoad>
      )}
    </>
  );
};

export default StudentForm;
