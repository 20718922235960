import ROLES_MAP from './rolesMap';

export const requestFunctions = ({ selectedRole }) => {
  const selectedRoleMap = ROLES_MAP.find(item => item.role === selectedRole);

  const {
    fetchData: fetchDataByRole,
    createRequest,
    editRequestStepOne,
    editRequestEmail,
    editRequestLocality,
    editRequestLearningPhase,
    editRequestSchool,
    editAllData,
    deleteRequest,
    editRequest,
    submitRequestVerifyInformation,
    finish,
    requestPage,
    role,
    managementPage,
    summary
  } = selectedRoleMap;

  const requestListFunctions = {
    create: payload => createRequest(payload),
    edit: payload => editRequest(payload),
    editRequestStepOne: payload => editRequestStepOne(payload),
    editRequestLocality: payload => editRequestLocality(payload),
    editRequestLearningPhase: payload => editRequestLearningPhase(payload),
    editRequestSchool: payload => editRequestSchool(payload),
    editRequestEmail: payload => editRequestEmail(payload),
    editAllData: payload => editAllData(payload),
    deleteRequest: payload => deleteRequest(payload),
    submitRequestVerifyInformation: payload =>
      submitRequestVerifyInformation(payload),
    finish: payload => finish(payload),
    redirect: requestPage,
    role,
    summary
  };

  return {
    requestListFunctions,
    fetchDataByRole,
    role,
    managementPage,
    requestPage
  };
};
