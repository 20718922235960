/* eslint-disable react/prop-types */
import React from 'react';
import { Async } from 'react-async';

import { isFunction } from 'lodash';

import { SKELETON_TYPES } from 'helpers/constants/skeletonTypes';

import { DefaultLoader } from 'components/AsyncLoad/DefaultLoader';
import SkeletonModels from 'components/SkeletonModels';

export const AsyncLoad = ({
  children,
  color,
  minHeight,
  showSkeleton = false,
  skeletonModel = SKELETON_TYPES.TABLE_SKELETON,
  ...promiseParams
}) => (
  <Async {...promiseParams}>
    <Async.Pending>
      {showSkeleton ? (
        <SkeletonModels type={skeletonModel} />
      ) : (
        <DefaultLoader color={color} minHeight={minHeight} />
      )}
    </Async.Pending>
    <Async.Fulfilled>
      {response => (isFunction(children) ? children(response) : children)}
    </Async.Fulfilled>
    <Async.Rejected>
      {error => (isFunction(children) ? children(error) : children)}
    </Async.Rejected>
  </Async>
);
