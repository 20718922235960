import React from 'react';

import { useStepper } from 'hooks/useStepper';

import { Grid } from '@mui/material';

import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from 'modules/RequestAccess/constants/routes';
import { useRequestAccessContext } from 'modules/RequestAccess/Context';
import EditWarning from 'modules/RequestAccess/fragments/EditWarning';

import EducationUnit from '../Steps/fragments/EducationUnit';
import LearningLevel from '../Steps/fragments/LearningLevel';
import Locality from '../Steps/fragments/Locality';
import SummaryInformation from '../Steps/fragments/SummaryInformation';

const stepPages = [
  {
    title: 'STEPS.LOCALITY',
    Content: Locality
  },
  {
    title: 'STEPS.EDUCATION_UNIT',
    Content: EducationUnit
  },
  {
    title: 'STEPS.LEARNING_PHASE',
    Content: LearningLevel
  },
  {
    title: 'STEPS.FINISH',
    Content: SummaryInformation
  }
];

function RequestAccessStudent() {
  const { requestData, isEditing, redirectIfRoleIsUndefined } =
    useRequestAccessContext();
  const { stepNumber = 1, role } = requestData || {};

  const [Stepper] = useStepper({
    stepPages,
    initialStep: stepNumber - 1
  });

  redirectIfRoleIsUndefined(role);

  return (
    <Grid container spacing={3} py={3} xs={12}>
      <Grid item xs={12}>
        <Grid container justifyContent="center" flexDirection="column">
          <EditWarning show={isEditing} />
          <Stepper />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default wrapper(RequestAccessStudent, {
  namespace: 'REQUEST_ACCESS_COMMONS',
  route: {
    path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_STUDENT,
    exact: false,
    breadcrumb: 'REQUEST_ACCESS_STUDENT'
  }
});
