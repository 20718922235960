import React, { useCallback, useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { PulseLoader } from 'react-spinners';

import { useUserAccess } from 'hooks/useConfigureUserAccess';
import jwt_decode from 'jwt-decode';
import queryString from 'query-string';
import { authService, authUseCases } from 'services/providers/auth';
import { ThemeContext } from 'styled-components';

import { authActions } from 'framework/store/slices/auth.slice';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { APP_ROUTES } from 'modules/App/constants/route';
import { ERROR_MESSAGE } from 'modules/Auth/constants/errorMessage';
import { AUTH_ROUTES } from 'modules/Auth/constants/routes';
import { INITIAL_PAGE_ROUTES } from 'modules/InitialPage/constants/routes';
import { notificationUseCases } from 'modules/Notification/providers';
import { REDIRECT } from 'modules/RequestAccess/constants/redirect';
import {
  REQUEST_ACCESS_ROUTES,
  REQUEST_ACCESS_STUDENT_ROUTES
} from 'modules/RequestAccess/constants/routes';

import { LogoRNP } from '../../../../components/Image';
import { LogoContainer } from '../../../../components/PublicContainer/style';
import history from '../../../../framework/history';
import { Container } from '../../../App/submodules/NoAccess/style';
import { TokenLoaderContainer, TokenWrapperContainer } from './style';

const redirectMap = {
  [REDIRECT.MANAGER_SCHOOL]: REQUEST_ACCESS_ROUTES.SCHOOL_MANAGEMENT,
  [REDIRECT.MANAGER_STUDENT]: REQUEST_ACCESS_ROUTES.STUDENT_MANAGEMENT,
  [REDIRECT.SELECT_PROFILE]: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS,
  [REDIRECT.INITIAL_PAGE]: INITIAL_PAGE_ROUTES.INITIAL_PAGE,
  [REDIRECT.PENDING_ACCESS]: REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED
};

const REDIRECT_FROM_TOKEN = 'REDIRECT_FROM_TOKEN';

const Token = ({ addUserGovBr }) => {
  const theme = useContext(ThemeContext);
  const translate = useLangContext();

  const isValidSession = ({ session, user }) => session && user;
  const { configureUserAccess } = useUserAccess();

  const handleSuccessfulLogin = useCallback(
    ({ session, user }) => {
      const name =
        session.idToken.payload?.name || translate('LOGIN.COGNITO_USER');

      addUserGovBr({
        cpf: user.username,
        email: session.idToken.payload.email,
        name
      });
    },
    [addUserGovBr, translate]
  );

  const navigateToRedirect = useCallback(redirectPage => {
    const redirect = redirectMap[redirectPage];

    return history.push({ pathname: redirect, state: { REDIRECT_FROM_TOKEN } });
  }, []);

  const navigateToNotAllowed = () => history.push(APP_ROUTES.NOT_ALLOWED);

  const login = useCallback(
    async (jwtDecoded, token, username) => {
      try {
        const response = await authUseCases.accessTokenLogin(
          jwtDecoded,
          token,
          username
        );

        const { redirectPage } = await configureUserAccess();
        const { errorMessage } = response;

        if (errorMessage === ERROR_MESSAGE.LOGOUT_REQUIRED) {
          return history.push(REQUEST_ACCESS_STUDENT_ROUTES.REQUEST_APPROVED);
        }

        if (isValidSession(response)) {
          handleSuccessfulLogin(response);
          return navigateToRedirect(redirectPage);
        }

        return navigateToNotAllowed();
      } catch (error) {
        notificationUseCases.error(error, { namespace: 'ERROR' });
        return navigateToNotAllowed();
      }
    },
    [configureUserAccess, handleSuccessfulLogin, navigateToRedirect]
  );

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    const getFullToken = async () => {
      const token = await authService.getFullToken(queryParams.code);

      if (token) {
        const jwtDecoded = jwt_decode(token.access_token);
        const username = jwtDecoded.username.split('_').slice(-1)[0];

        await login(jwtDecoded, token, username);
      }
    };

    const init = async () => {
      const isAuthenticated = await authUseCases.isAuthenticated();

      if (queryParams.code && !isAuthenticated) {
        await getFullToken();
      }
      return !isAuthenticated && <Redirect to={AUTH_ROUTES.LOGIN} />;
    };

    init();
  }, [configureUserAccess, login]);

  return (
    <Container>
      <TokenWrapperContainer>
        <TokenLoaderContainer>
          <PulseLoader
            size="10px"
            margin="2px"
            color={theme.palette.primary.light}
          />
          <span>{translate('VALIDATION')}</span>
        </TokenLoaderContainer>

        <LogoContainer>
          <LogoRNP />
        </LogoContainer>
      </TokenWrapperContainer>
    </Container>
  );
};

export default wrapper(Token, {
  namespace: 'TOKEN',
  route: {
    path: AUTH_ROUTES.TOKEN
  },
  mapDispatch: dispatch => ({
    addUserGovBr: key => {
      dispatch(authActions.setUserGovBr(key));
    },

    handleUserUpdate: boolean => {
      dispatch(authActions.userNeedsUpdate(boolean));
    }
  }),
  mapState: state => ({
    userAccess: state.userAccess
  })
});
