import { IndexedDBStorage } from 'adapters/indexedDB';
import CookieManager from 'managers/cookieManager';

const storage = new IndexedDBStorage();
const cookieManager = new CookieManager();
const cookieKeys = [];

const getStorage = key => {
  const keyReduce = key.split('.').pop();
  return cookieKeys.includes(keyReduce) ? cookieManager : storage;
};

export default class MyStorage {
  static setItem(key, value) {
    return getStorage(key).set(key, value);
  }

  static getItem(key) {
    return getStorage(key).get(key);
  }

  static removeItem(key) {
    getStorage(key).remove(key);
  }

  static clear() {
    cookieManager.clear();
    storage.clear();
  }

  static sync() {
    return new Promise(res => {
      storage
        .sync()
        .then(() => {
          if (!storage.isCognitoValid()) {
            storage.removeTokenCognito();
            cookieManager.removeTokenCognito();
          }
          res(null, 'SUCCESS');
        })
        .catch(() => {
          res(null, 'ERROR');
        });
    });
  }
}
