import React, { useContext } from 'react';

import { ThemeContext } from 'styled-components';

import {
  CloseRounded as Close,
  InfoRounded as IconINFO,
  Check as IconSUCCESS,
  ErrorRounded as IconERROR,
  WarningRounded as IconWARNING
} from '@mui/icons-material';
import { Grid, Box } from '@mui/material';

import { TagWarning, CloseButton } from './style';

const WarningTag = params => {
  const { warning, children } = params;
  const variant = warning.options.variant.toUpperCase();
  const theme = useContext(ThemeContext);

  const styles = {
    INFO: {
      backgroundColor: theme.palette.info.light,
      iconColor: theme.palette.info.main,
      icon: <IconINFO style={{ color: theme.palette.info.main }} />
    },
    WARNING: {
      backgroundColor: theme.palette.warning.light,
      iconColor: theme.palette.warning.main,
      icon: <IconWARNING style={{ color: theme.palette.warning.main }} />
    },
    SUCCESS: {
      backgroundColor: theme.palette.success.light,
      iconColor: theme.palette.success.main,
      icon: <IconSUCCESS style={{ color: theme.palette.success.main }} />
    },
    ERROR: {
      backgroundColor: theme.palette.error.light,
      iconColor: theme.palette.error.main,
      icon: <IconERROR style={{ color: theme.palette.error.main }} />
    }
  };

  return (
    <>
      <TagWarning
        style={{
          background: styles[variant].backgroundColor,
          ...warning.style
        }}
      >
        <Grid container alignItems="center">
          <Box item xs={2}>
            {styles[variant].icon}
          </Box>
          <Grid item xs={11}>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: warning.message }}
              style={{ margin: '12px' }}
            />
          </Grid>

          {children}
          {!warning.options.hideCloseButton && (
            <Grid item sm={1}>
              <CloseButton
                type="button"
                onClick={() => warning.options.close()}
                style={{ float: 'right' }}
              >
                <Close style={{ color: styles[variant].iconColor }} />
              </CloseButton>
            </Grid>
          )}
        </Grid>
      </TagWarning>
    </>
  );
};

export default WarningTag;
