/* eslint-disable class-methods-use-this */
import { Auth } from 'aws-amplify';

export class AwsTokenManager {
  async getToken() {
    try {
      const currentSession = await Auth.currentSession();

      if (!currentSession.isValid()) return null;

      return `Bearer ${currentSession.getIdToken().getJwtToken()}`;
    } catch (error) {
      return null;
    }
  }

  /**
   */
  clearToken() {
    Auth.signOut();
  }
}
