import { createSlice } from '@reduxjs/toolkit';

export const USER_ACCESS_REDUCER = 'userAccess';

const userAccess = createSlice({
  name: USER_ACCESS_REDUCER,
  initialState: {
    requestAvailableOptions: [],
    menuOptions: [],
    redirectPage: ''
  },
  reducers: {
    setUserAccess: (state, { payload }) => {
      const { requestAvailableOptions, menuOptions, redirectPage } = payload;

      state.requestAvailableOptions = requestAvailableOptions;
      state.menuOptions = menuOptions;
      state.redirectPage = redirectPage;
    }
  }
});

export const userAccessActions = userAccess.actions;

export default userAccess.reducer;
