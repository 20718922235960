import React from 'react';

import { isArray } from 'lodash';

import { Tooltip } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import SECTION_TITLE from 'modules/RequestAccess/submodules/RequestAccessGuardian/constants/sectionTitle';
import { SummaryItem } from 'modules/RequestAccess/submodules/RequestAccessTeacher/fragments/RequestCompleted/styles';

import {
  EditButton,
  SummarySectionTitle,
  Section,
  SectionTitleWrapper,
  Separator,
  SummaryInfo,
  BorderColorIcon
} from '../styles';

const tooltipKeys = {
  [SECTION_TITLE.PERSONAL_INFO]: 'TOOLTIP:EDIT_PERSONAL_INFO',
  [SECTION_TITLE.LOCALITY]: 'TOOLTIP:EDIT_LOCALITY',
  [SECTION_TITLE.SCHOOL]: 'TOOLTIP:EDIT_EDUCATION_UNIT',
  [SECTION_TITLE.STAGE_EDUCATION]: 'TOOLTIP:EDIT_LEARNING_PHASE'
};

const SectionComponent = ({ section, handlePressEdit, requestData }) => {
  const translate = useLangContext();

  const { title, items = [] } = section;
  const tooltipKey = tooltipKeys[title] || 'COMMONS:EDIT';

  return (
    <Section>
      <SectionTitleWrapper>
        <SummarySectionTitle>
          {translate(`REQUEST_ACCESS:${title}`)}
        </SummarySectionTitle>
        <Tooltip title={translate(tooltipKey)}>
          <EditButton onClick={() => handlePressEdit(title)}>
            <BorderColorIcon color="primary" />
          </EditButton>
        </Tooltip>
      </SectionTitleWrapper>
      <Separator />
      {items.map(item => {
        const { value, label } = item;
        const requestedValue = value(requestData);

        const renderValue = !isArray(requestedValue)
          ? translate(requestedValue)
          : requestedValue.map(valueItem => translate(valueItem)).join(', ');
        return (
          <>
            <SummaryItem key={item}>{translate(label)}</SummaryItem>
            <SummaryInfo>{renderValue}</SummaryInfo>
          </>
        );
      })}
    </Section>
  );
};

export default SectionComponent;
