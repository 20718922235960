import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Apps } from '@mui/icons-material';
import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from 'modules/RequestAccess/constants/routes';
import { MENU } from 'modules/User/constants/menu';

import { Container } from './styles';

const menuItems = {
  [MENU.MANAGER_SCHOOL]: {
    label: 'MENU_SHORTCUT:SCHOOL_MANAGEMENT',
    route: REQUEST_ACCESS_ROUTES.SCHOOL_MANAGEMENT
  },
  [MENU.MANAGER_STUDENT]: {
    label: 'MENU_SHORTCUT:STUDENT_MANAGEMENT',
    route: REQUEST_ACCESS_ROUTES.STUDENT_MANAGEMENT
  },
  [MENU.NEW_REQUEST]: {
    label: 'MENU_SHORTCUT:ADD_NEW_PROFILE',
    route: REQUEST_ACCESS_ROUTES.SELECT_PROFILE
  }
};

const Shortcut = ({ userAccess }) => {
  const { menuOptions } = userAccess;

  const translate = useLangContext();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen(isPrevOpen => !isPrevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  if (menuOptions.length < 1) return null;

  return (
    <Container>
      <IconButton onClick={handleToggle}>
        <Apps
          ref={anchorRef}
          aria-controls={open && 'menu-list-grow'}
          aria-haspopup="true"
          variant="text"
          color="secondary"
        />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  {menuOptions.map(item => (
                    <MenuItem
                      key={menuItems[item].label}
                      onClick={() => history.push(menuItems[item].route)}
                    >
                      {translate(menuItems[item].label)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Container>
  );
};

export default wrapper(Shortcut, {
  mapState: state => ({
    role: state.auth.role,
    userAccess: state.userAccess
  })
});
