import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Grid, Skeleton } from '@mui/material';

const SKELETON_CARDS = [1, 2, 3];

const CardGroupSkeleton = () => (
  <Grid container spacing={2} direction="row">
    {SKELETON_CARDS.map(() => (
      <Grid item xs={4} key={uuidv4()}>
        <Skeleton
          animation="wave"
          width={320}
          height={475}
          variant="rectangular"
        />
      </Grid>
    ))}
  </Grid>
);

export default CardGroupSkeleton;
