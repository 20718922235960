import React, { useCallback, useState } from 'react';

import { usePagination } from 'hooks/usePagination';

import { Add } from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  Grid,
  Hidden,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import history from 'framework/history';
import { SKELETON_TYPES } from 'helpers/constants/skeletonTypes';
import { ROLES } from 'helpers/roles';

import { ConfirmDialog } from 'components/Dialog';
import { useLangContext } from 'components/Globalization';
import SkeletonModels from 'components/SkeletonModels';
import wrapper from 'components/Wrapper';

import { ThinDivider } from 'modules/LiteraryDidacticWorks/styles';
import { notificationUseCases } from 'modules/Notification/providers';
import { requestFunctions } from 'modules/RequestAccess/constants/requestFunctions';

import { REQUEST_ACCESS_ROUTES } from '../../constants/routes';
import { useRequestAccessContext } from '../../Context';
import { requestAccessUseCases } from '../../provider';
import ActionButtons from './fragments/ActionButtons';
import CustomTableRow from './fragments/TableRow';
import { StyledTableRow, Title } from './styles';

const SchoolManagement = () => {
  const translate = useLangContext();
  const { setRequestData, setRequestFunctions } = useRequestAccessContext();

  const [openModal, setOpenModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const role = ROLES.ROLE_TEACHER;
  const { requestListFunctions } = requestFunctions({ selectedRole: role });
  const { requestPage, deleteRequest } = requestListFunctions;

  const fetchData = useCallback(
    async ({ page, size }) => {
      setIsLoading(true);
      try {
        const resp = await requestAccessUseCases.getRequestAccessTeacher(
          page,
          size
        );
        return resp;
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
      return [];
    },
    [setIsLoading]
  );

  const [Pagination, rows, refresh] = usePagination({
    fetchData,
    showSkeleton: true
  });

  const deleteItem = useCallback(
    async id => {
      setIsLoading(true);

      try {
        await deleteRequest({
          id
        });
        notificationUseCases.success('SCHOOL_MANAGEMENT:DELETE_SUCCESS');
        setOpenModal(false);
        refresh();
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [deleteRequest, refresh, setIsLoading]
  );

  const redirectToIdentifyUser = () => {
    history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
  };

  const redirectEdit = useCallback(
    item => {
      setRequestData({
        ...item,
        requestPage,
        role
      });
      redirectToIdentifyUser();
    },
    [requestPage, role, setRequestData]
  );

  const createRequest = async () => {
    setRequestFunctions(requestListFunctions);

    setRequestData({
      role,
      requestPage
    });
    redirectToIdentifyUser();
  };

  return (
    <Container maxWidth="xl">
      <ConfirmDialog
        open={openModal}
        disabled={isLoading}
        onConfirm={() => deleteItem(deleteItemId)}
        onCancel={() => setOpenModal(false)}
      >
        <Typography variant="h5" style={{ marginBottom: '12px' }}>
          {translate('WARNINGS_TYPE:WARNING')}
        </Typography>
        <Typography variant="body" style={{ marginBottom: '24px' }}>
          {translate('CONFIRM_DELETE')}
        </Typography>
      </ConfirmDialog>
      {isLoading ? (
        <SkeletonModels type={SKELETON_TYPES.TITLE_SKELETON} />
      ) : (
        <Box>
          <Title variant="h3" component="h1">
            {translate('SCHOOL_MANAGEMENT')}
          </Title>
          <ThinDivider />
        </Box>
      )}

      <Grid container>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-end" mb={2} mt={6}>
            {isLoading ? (
              <SkeletonModels type={SKELETON_TYPES.BUTTON_SKELETON} />
            ) : (
              <Button
                variant="contained"
                onClick={createRequest}
                startIcon={<Add />}
              >
                {translate('NEW_SCHOOL')}
              </Button>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Pagination>
            <Hidden mdDown>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    {translate('EDUCATION_UNIT')}
                  </TableCell>
                  <TableCell align="left">{translate('COMMONS:UF')}</TableCell>
                  <TableCell align="left">
                    {translate('COMMONS:CITY')}
                  </TableCell>
                  <TableCell align="left">
                    {translate('LEARNING_PHASE')}
                  </TableCell>
                  <TableCell align="left">{translate('SITUATION')}</TableCell>
                  <TableCell align="left">
                    {translate('COMMONS:ACTIONS')}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Hidden>
            <TableBody>
              {rows.map(row => (
                <StyledTableRow key={row.id}>
                  <CustomTableRow row={row} />
                  <ActionButtons
                    row={row}
                    redirectEdit={redirectEdit}
                    onDelete={() => {
                      setOpenModal(true);
                      setDeleteItemId(row.id);
                    }}
                  />
                </StyledTableRow>
              ))}
            </TableBody>
          </Pagination>
        </Grid>
      </Grid>
    </Container>
  );
};

export default wrapper(SchoolManagement, {
  namespace: 'SCHOOL_MANAGEMENT',
  route: { path: REQUEST_ACCESS_ROUTES.SCHOOL_MANAGEMENT }
});
