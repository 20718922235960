import React, { useCallback, useMemo } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';

import history from 'framework/history';

import Checkbox from 'components/Checkbox';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { useRequestAccessContext } from 'modules/RequestAccess/Context';

import { REQUEST_ACCESS_ROUTES } from '../../../../constants/routes';
import { HyperlinkText } from './styles';

const ConsentAgreement = ({ userGovBr }) => {
  const translate = useLangContext();

  const formMethods = useForm();

  const { requestData, requestFunctions } = useRequestAccessContext();

  const { finish } = requestFunctions;

  const { tutorCpf, degreeOfKinship, name, cpf } = requestData || {};

  const { name: tutorName } = userGovBr;

  const { control } = formMethods;

  const termsOfUseWatch = useWatch({
    control,
    name: 'consentAgreement'
  });

  const SECTIONS = useMemo(
    () => [
      {
        title: translate('GUARDIAN_SECTION'),
        fields: [
          {
            label: translate('COMMONS:NAME'),
            name: tutorName
          },
          {
            label: translate('PEOPLE_CPF'),
            name: tutorCpf
          },
          {
            label: translate('DEGREE_OF_KINSHIP'),
            name: translate(degreeOfKinship)
          }
        ]
      },
      {
        title: translate('CHILD_SECTION'),
        fields: [
          {
            label: translate('COMMONS:NAME'),
            name
          },
          {
            label: translate('PEOPLE_CPF'),
            name: cpf
          }
        ]
      }
    ],
    [translate, tutorName, tutorCpf, degreeOfKinship, name, cpf]
  );

  const handleGoBack = useCallback(() => {
    history.push(REQUEST_ACCESS_ROUTES.SELECT_PROFILE);
  }, []);

  const acceptTermsOfUse = useCallback(async () => {
    if (!requestData) return;

    const { id } = requestData;

    try {
      await finish({
        id
      });

      history.push(REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED);
    } catch (error) {
      console.error(error);
    }
  }, [finish, requestData]);

  return (
    <Container maxWidth="md">
      <Grid item spacing={3} py={3} xs={8}>
        <Typography component="div">
          <Box fontSize={34} lineHeight="42px">
            {translate('TERM_TITLE')}
          </Box>
        </Typography>
        <Box mt={6}>
          <Typography component="div">
            <Box fontSize={16} lineHeight="20px">
              {translate('TERM_DESCRIPTION')}
            </Box>
          </Typography>
        </Box>

        {SECTIONS.map(section => (
          <Box mt={5}>
            <Typography component="div">
              <Box
                fontSize={20}
                lineHeight="24px"
                fontWeight={700}
                textTransform="uppercase"
              >
                {section.title}
              </Box>
            </Typography>
            {section.fields.map(field => (
              <Box mt={2}>
                <Typography component="div" height="auto">
                  <Box
                    height="auto"
                    fontSize={14}
                    fontWeight={600}
                    lineHeight="16px"
                  >
                    {field.label}
                  </Box>
                </Typography>
                <Box mt={1}>
                  <Typography component="div">
                    <Box fontSize={16} lineHeight="20px">
                      {field.name}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ))}

        <Box mt={5}>
          <Typography component="div">
            <Box fontSize={16} lineHeight="20px">
              {translate('TERM_ATTENTION')}
            </Box>
            <Box fontSize={16} lineHeight="20px" mt={2}>
              {translate('TERM_ATTENTION_DESCRIPTION')}
              <HyperlinkText>
                {translate('TERM_ATTENTION_DESCRIPTION_LINK')}
              </HyperlinkText>
              {translate('TERM_ATTENTION_DESCRIPTION_LINK_AFTER')}
            </Box>
            <Box fontSize={16} lineHeight="20px" mt={2}>
              {translate('TERM_ATTENTION_DESCRIPTION_AFTER')}
            </Box>
          </Typography>
        </Box>

        <Box mt={5}>
          <Checkbox
            formMethods={formMethods}
            name="consentAgreement"
            label={
              <Typography component="div">
                <Box fontSize={14} fontWeight={400} lineHeight="16px">
                  {translate('ACCEPT_TERMS_OF_USE')}
                </Box>
              </Typography>
            }
          />
        </Box>

        <Box mt={5}>
          <Grid item container justifyContent="center" xs={12}>
            <Button onClick={() => handleGoBack()} variant="outlined">
              {translate('COMMONS:REFUSE')}
            </Button>
            <Box ml={2}>
              <Button
                variant="contained"
                onClick={() => acceptTermsOfUse()}
                width="188px"
                disabled={!termsOfUseWatch}
              >
                {translate('COMMONS:ACCEPT_CONFIRM')}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>
    </Container>
  );
};

export default wrapper(ConsentAgreement, {
  namespace: 'CONSENT_AGREMMENT',
  mapState: state => ({
    userGovBr: state.auth.userGovBr
  }),
  route: {
    path: REQUEST_ACCESS_ROUTES.CONSENT_AGREEMENT
  }
});
