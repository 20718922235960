/* eslint-disable no-template-curly-in-string */
export default {
  COMMONS: {
    BACK: 'Voltar',
    NAME: 'Nome',
    CANCEL: 'Cancelar',
    DONE: 'Concluir',
    SAVE: 'Salvar',
    ACCEPT: 'Aceitar',
    ACCEPT_CONFIRM: 'Aceitar e Concluir',
    REFUSE: 'Recusar',
    UPDATE: 'Atualizar',
    REGISTER: 'Inserir',
    GO_FORWARD: 'Avançar',
    EDIT: 'Editar',
    NO_DATA: 'Nenhum registro encontrado.',
    CONFIRM: 'Confirmar',
    SEARCH: 'Buscar',
    SAVE_ALTERNATE: 'Gravar',
    MIN: 'Mínimo',
    MAX: 'Máximo',
    HEIGHT: 'Altura',
    WIDTH: 'Largura',
    DEVIATION: 'Desvio',
    ACTIONS: 'Ações',
    YES: 'Sim',
    NO: 'Não',
    VALIDATE: 'Validar',
    VIEW_MORE: 'Ver mais',
    ADD: 'Adicionar',
    IMPORT: 'Importar',
    RESET: 'Reset',
    CLOSE: 'Fechar',
    DELETE: 'Excluir',
    FINISH: 'Finalizar',
    CLEAR: 'Limpar',
    BY: 'Por: ',
    SEND: 'Enviar',
    TIMEZONE_INFO: 'Todas as datas e horários seguem o horário de Brasília.',
    SELECT: 'Selecione',
    LAST_UPDATE: 'Última atualização',
    CPF: 'CPF',
    PHONE: 'Telefone',
    FILLED: 'Preenchido',
    NOT_FILLED: 'Não Preenchido',
    CONFIRM_DELETE: 'Deseja excluir o registro ?',
    EMAIL: 'E-mail',
    ROLE: 'Perfil',
    CONTINUE: 'Continuar',
    ALL: 'Todos',
    QUIT: 'Desistir',
    APPEAL: 'Recurso',
    DATE_GREATHER_THAN_INITIAL:
      'A Data selecionada tem que ser maior que a inicial',
    DATE_GREATHER_THAN_OPENING:
      'A Data selecionada tem que ser maior que a Data de Abertura',
    DATE_GREATHER_THAN_DELIVERY_OPENING:
      'A Data selecionada deve ser maior ou igual a data de abertura da entrega',
    DATE_GREATHER_THAN_EQUAL_PHASE_OPENING:
      'A Data selecionada deve ser maior ou igual a data de abertura da fase',
    DATE_LESS_THAN_EQUAL_PHASE_CLOSING:
      'A Data selecionada deve ser menor ou igual a data de fechamento da fase',
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    OPENING_DATE_TIME: 'Data e hora de abertura',
    CLOSING_DATE_TIME: 'Data e hora de fechamento',
    VIEW: 'Visualizar',
    SHARE: 'Compartilhar',
    SELECT_PLACEHOLDER: 'Digite para buscar...',
    CITY: 'Município',
    UF_PLACEHOLDER: 'Selecione a unidade da federação',
    CITY_PLACEHOLDER: 'Selecione o município',
    SCHOOL: 'Escola',
    SCHOOL_PLACEHOLDER: 'Pesquise o nome ou código INEP da unidade de ensino',
    UF: 'UF',
    INVALID_DATA: 'Dado(s) inválido(s)',
    ACCESSIBILITY_GO_FORWARD: 'Avançar para etapa de cadastro do estudante',
    ACCESSIBILITY_CANCEL_ACCESS_REQUIREMENT: 'Cancelar requisição de acesso'
  },
  VALIDATION: {
    REQUIRED_SELECT_ONE: 'Selecione ao menos um',
    REQUIRED_FIELD: 'Campo obrigatório',
    STRING_LENGTH: 'Deve ter {{length}} caracteres',
    AT_LEAST_VALUE: 'Dever ser ao menos {{min}}',
    INVALID_EMAIL: 'E-mail inválido',
    INVALID_URL: 'Digite uma url válida: http://www.exemplo.com.br',
    MAX_LENGTH: 'Deve ter no máximo {{max}} caracteres',
    MIX_NUMBER: 'Deve ser no mínimo {{min}}',
    MAX_NUMBER: 'Deve ser no máximo {{max}}',
    INVALID_ISBN: 'ISBN inválido',
    INVALID_CNPJ: 'CNPJ inválido',
    INVALID_CPF: 'CPF inválido',
    INVALID_TELEPHONE: 'Telefone inválido',
    INVALID_CELLPHONE: 'Celular inválido',
    TAG_MAX_LENGTH:
      'A soma de todas as palavras-chave deve ser no máximo {{max}} caracteres'
  },
  ERROR: {
    CALL_PHASE_NOT_ACTIVE:
      'Não é possível responder convocação: fase desabilitada.',
    CALL_RESPONSE_OUT_OF_TIME: 'Fora do prazo para envio de documentos.',
    QUESTION_FORM_ALREADY_IN_USE:
      'O formulário possui vínculos e não pode ser excluído.',
    APPEAL_ALREADY_REJECTED_OR_DEFERRED:
      'Este recurso já deferido ou indeferido',
    USER_CANNOT_RETURN_DILIGENCE_THAT_DOES_NOT_EXIST:
      'Diligência inexistente! Entre em contato com o suporte',
    USER_CANNOT_RETURN_WITHOUT_JUSTIFICATION:
      'O campo observações é obrigatório.',
    POSTSUBSCRIPTION_DATE_REQUIRED:
      'As datas de início e fim ao habilitar a pós inscrição são obrigatórias.',
    POSTSUBSCRIPTION_INVALID_DATE_RANGE:
      'A data final de pós inscrição é maior que a data inicial.',
    INVALID_SECURITY_ANSWER: 'Resposta de segurança inválida.',
    VOLUME_IS_NOT_OPTIONAL: 'Este não é um volume do tipo Facultativo!',
    NOT_ALL_VOLUMES_ARE_VALIDATED:
      'Somente validar a coleção quando todos os volumes estiverem validados.',
    ACCESSIBILITY_COMMUNICATION_IS_NOT_RANGE_TIME_REGISTER_PUBLISHER:
      'Fora do prazo da fase de inscrição da editora.',
    PUBLISHER_REQUEST_EMPTY_REASON: 'O campo justificativa deve ser preenchido',
    PUBLISHER_INFORMATION_COMPANY_NOT_COMPLETED:
      'Não é possível preencher este formulário antes de preencher completamente o formulário da editora.',
    DEFAULT_ERROR:
      'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
    REGISTER_PERIOD_CLOSED: 'Período de cadastramento do objeto finalizado.',
    EDITAL_DUPLICATE_INFO_FOUND:
      'Existe um edital cadastrado com as mesmas informações. ',
    EDITAL_ALREADY_EXISTS: 'Edital já cadastrado.',
    OBJECT_CATEGORY_DUPLICATED_INFO_FOUND:
      'A categoria informada já possui cadastro no objeto.',
    COLLECTION_ALREADY_EXISTS: 'Coleção já cadastrada.',
    CATEGORY_DETAIL_DUPLICATE_INFO_FOUND: 'Detalhe ja cadastrado na categoria.',
    INVALID_TOTAL_SELECTED_COLLECTION:
      'Quantidade de volumes selecionado maior que o permitido.',
    PUBLISHER_ALREADY_EXISTS: 'O CNPJ informado já possui cadastro no sistema.',
    PUBLISHER_ALREADY_LINKED: 'Editora já vinculada.',
    NO_PUBLISHER_SELECTED:
      'É necessário selecionar ao menos uma editora para vincular.',
    RECEIPT_NOT_FOUND: 'Recibo não encontrado!',
    SUBSCRIPTION_VOLUME_ALREADY_FINISHED: 'Volume ja finalizado.',
    SUBSCRIPTION_VOLUME_NOT_FILLED:
      'Para finalizar este volume, ele deve estar ao menos 50% preenchido.',
    SUBSCRIPTION_COLLECTION_DILIGENCE_NOT_FOUND:
      'Não é possível encontrar dados sobre esta diligência!',
    SUBSCRIPTION_COLLECTION_ALREADY_IN_DILIGENCE:
      'Diligência já criada para esta coleção!',
    TOKEN_INVALID: 'Token inválido ou expirado',
    TOKEN_EXPIRED: 'O token expirou',
    TOKEN_ALREADY_CONSUMED: 'Token já utilizado',
    USER_ALREADY_EXISTS_OTHER_EMAIL: 'CPF ja cadastrado para outro e-mail',
    USER_ALREADY_EXISTS_EMAIL: 'E-mail já cadastrado no sistema.',
    USER_ALREADY_EXISTS_CPF: 'O CPF informado possui cadastro no sistema.',
    USER_NOT_LOGGED: 'Usuário não autenticado',
    USER_NOT_FOUND: 'Usuário não autorizado a acessar esta aplicação',
    FIXED_FIELD_ITEM_EXISTS: 'Existe item cadastrado!',
    CAPTCHA_INVALID: 'Erro ao validar ReCAPTCHA',
    NotAuthorizedException: 'Usuário ou senha inválidos',
    VOLUME_FILE_INVALID_NUM_PAGES_TOTAL:
      'Número de páginas excedido conforme limite total do volume',
    VOLUME_FILE_INVALID_NUM_PAGES_MIN:
      'Número de páginas inferior conforme limite mínimo do exemplar',
    VOLUME_FILE_INVALID_NUM_PAGES_MAX:
      'Número de páginas excedido conforme limite máximo do exemplar',
    AUXILIARY_FIELD_ITEM_IN_USE:
      'Não é possível excluir um item que esteja vinculado a um objeto!',
    AUXILIARY_FIELD_ITEM_EXISTS: 'Existe item cadastrado!',
    CPF_INVALID: 'CPF inválido',
    VOLUME_HAS_SUBSCRIPTION:
      'Não é possível excluir o volume que possui inscritos.',
    VOLUME_DELETE_ERROR: 'Houve um erro ao excluir o volume.',
    VALIDATION_APPEAL_PERIOD_OVER_OR_NOT_ACTIVATED:
      'Período do recurso invalido ou desabilitado.',
    APPEAL_ALREADY_REGISTERED_FOR_PHASE:
      'Já existe uma solicitação de recurso para esta fase e coleção.',
    PUBLISHER_RULING_RG_OR_CPF_ALREADY_EXITS_TO_THE_OBJECT:
      'O CPF ou RG do dirigente da editora já foi cadastro para esse objeto',
    PUBLISHER_RULING_SUBSCRIPTION_MUST_TO_BE_CHANGED_BEFORE_DELETE:
      'A quantidade de dirigentes deve ser no mínimo igual a quantidade de informada no campo ‘Quantos assinam’. ',
    PUBLISHER_RULING_ALREADY_EXITS: 'Dirigente já está cadastrado',
    QUALIFICATION_YEAR_ALREADY_EXISTS:
      'Ano de Atendimento já foi cadastrado para edital/objeto.',
    SUBSCRIPTION_COLLECTION_DILIGENCE_FINISH_DATE_GT_START_DATE:
      'Data final deve ser maior que data inicial.',
    PUBLISHER_NOT_FOUND: 'Editora não encontrada.',
    PUBLISHER_NOT_ALLOWED_TO_BE_CALLED:
      'Editora não habilitada para participar da convocação',
    EDITAL_OBJECT_CALL_ALREADY_SENT: 'Call já foi enviada',
    QUESTION_FORM_ALREADY_LINKED:
      'Formulário repetido. Não é permitido vincular.',
    STATUS_NOT_SUITABLE_FOR_PEDAGOGICAL_ANALYSIS:
      'Status da coleção não está apta para realizar o recurso da análise pedagógica',
    QUALIFICATION_YEAR_HAS_LINKED_FORMS:
      'Não foi possível excluir pois o ano de atendimento possui formulário vinculado.',
    COLLECTION_AND_PEDAGOGICAL_ANALYSIS_HISTORY_NOT_SYNC:
      'Não foi possível encontrar o parecer final do recurso correspondente à fase atual.',
    QUALIFICATION_YEAR_HAS_LINKED_CALLS:
      'Não é possível excluir esse ano de atendimento porque existe uma convocação vinculada!',
    QUALIFICATION_FORM_ALREADY_IN_USE:
      'Este registro possui informações cadastradas no checklist que impedem sua exclusão. Verifique os cadastros relacionados e tente novamente.',
    QUALIFICATION_ANALYSIS_DILIGENCE_CANNOT_BE_SENT_MORE_THEN_ONCE:
      'A diligência só pode ser enviada uma vez',
    INVALID_QUALIFICATION_ANALYSIS_DILIGENCE_DEADLINE:
      'O prazo da diligência não pode ser menor que a data atual.',
    WRONG_ALERT_OPENING_AND_CLOSING:
      'Data e hora da abertura não pode ser menor que data e hora e fechamento',
    TERMS_REFUSED: 'Alerta. Termo recusado, não é possível prosseguir!',

    CPF_NOT_FOUND: 'Caso ainda não esteja cadastrado, crie sua conta no gov.br',
    TEACHER_ACCESS_ALREADY_GRANTED:
      'Alerta. Não foi possível efetuar sua solicitação, pois já existe acesso vigente, ou solicitação de acesso pendente de aprovação. Entre em contato com o Gestor da Unidade de ensino para sanar a pendência.',
    SAME_REQUESTS:
      'Alerta. Não foi possível finalizar pois existem solicitações iguais na tabela.',
    400: 'Não foi possível concluir a requisição',
    401: 'Usuário não autorizado',
    403: 'Este usuário não tem permissão para fazer esta operação.',
    500: 'Ocorreu um erro inesperado',
    'Network Error': 'Ocorreu um erro de rede',
    ACCESS_REQUEST_ALREADY:
      'Não foi possível efetuar sua solicitação, o vínculo solicitado já existe ou está pendente de aprovação.',
    INSTITUTION_NOT_FOUND:
      'Não foi possível efetuar sua solicitação, Não encontramos a escola, ou etapa de ensino ou o relacionamento entre os 2.'
  },

  NOTIFICATIONS: {
    CREATED_SUCCESS: 'Registro incluído com sucesso.',
    UPDATED_SUCCESS: 'Registro alterado com sucesso.',
    DELETED_SUCCESS: 'Registro excluído com sucesso.',
    APROVED_SUCCESS: 'Registro aprovado com sucesso.',
    REPROVED_SUCCESS: 'Registro reprovado com sucesso.',
    SEND_APPEAL: 'Solicitação enviada com sucesso.',
    SEND_SUCCESS: 'Solicitação enviada com sucesso.',
    DEFAULT_ERROR:
      'Não foi possível realizar a operação. Caso o problema persista contate o administrador do sistema.',
    WILL_RECEIVE_AN_EMAIL:
      'Sucesso. Você receberá um e-mail de notificação quando o diretor de sua unidade de ensino aprovar seu acesso.',
    WILL_RECEIVE_AN_EMAIL_WITH_PASSWORD:
      'Sucesso. Você receberá um e-mail com a senha para acesso.',
    TERMS_OF_USE_REFUSED:
      'Atenção. Ao confirmar a recusa do Termo de Consentimento de Uso de Dados, não será possível prosseguir com sua solicitação de acesso à Plataforma do PNLD. ',
    REFRESH_SUCCESS: 'A atualização foi concluída. Realize a pesquisa.'
  },

  DOCUMENT_STATUS: {
    DOCUMENT_SENT: 'Documentos enviados',
    DOCUMENT_IN_COMPLETION: 'Documentos em preenchimento',
    DOCUMENT_NOT_SENT: 'Documentos não enviados'
  },

  CELL_ACTIONS: {
    DUPLICATE: 'Duplicar',
    VIEW: 'Visualizar',
    EDIT: 'Editar',
    ADD: 'Adicionar',
    DELETE: 'Excluir',
    CONFIRM: 'Finalizar',
    ACTIVATE: 'Ativar',
    DEACTIVATE: 'Desativar',
    RESET_PASSWORD: 'Resetar a senha',
    RESET_PASSWORD_QUESTIONS: 'Resetar senha e perguntas de segurança',
    DOWNLOAD: 'Baixar',
    VALIDATE: 'Validar',
    ANALYZE: 'Analisar',
    MORE_ACTIONS: 'Mais ações',
    HISTORY: 'Ver histórico',
    SHOW_MORE: 'Mostrar mais',
    UP: 'Ordem decrescente',
    DOWN: 'Ordem crescente',
    COPY: 'Copiar',
    SEND: 'Enviar',
    SEE_MORE: 'Ver mais',
    UNDO_ANALYZE: 'Desfazer análise da coleção'
  },

  PRINTED_DETAIL_FIELDS: {
    COVER: {
      GRAMMAGE: 'Gramatura para capa (g/m2)',
      LONGITUDINAL_STIFFNESS: 'Rigidez Longitudinal (mN)',
      TRANSVERSE_STIFFNESS: 'Rigidez Transversal (mN)',
      THICKNESS: 'Espessura da capa (µm)',
      WHITENESS: 'Alvura da capa (%)'
    },
    PAGES: {
      GRAMMAGE: 'Gramatura para miolo (g/m2)',
      OPACITY: 'Opacidade do miolo (%)',
      WHITENESS: 'Alvura do miolo (%)',
      THICKNESS: 'Espessura do miolo (µm)'
    }
  },

  COLLECTION_VOLUME_MATERIAL_SPECIFICATION: {
    BOOK_DIMENSION: 'Largura x Altura (mm)',
    PAGE_TITLE: 'Especificações do material'
  },

  DIMENSION_TYPES: {
    FREE: 'Livre',
    FIXED: 'Fixa'
  },

  FORMAT_TYPE: {
    digital: 'Digital',
    printed: 'Impresso'
  },

  PRINTED_BOOK_TYPES: {
    CONSUMABLE: 'Consumível',
    REUSABLE: 'Reutilizável'
  },

  PRINTED_DIMENSIONS: {
    TITLE: 'Especificações do material',
    DESCRIPTION: 'Descrição'
  },

  PRINTED_BOOK_COVER: {
    TITLE: 'Especificações de capa do livro',
    DESCRIPTION: 'Descrição'
  },

  PRINTED_BOOK_CORE: {
    TITLE: 'Especificações do miolo do livro',
    DESCRIPTION: 'Descrição'
  },

  METADATA: {
    CREATED_DATE: '<strong>Data de criação:</strong> {{value}}',

    NUMPAGES: '<strong>Paginas:</strong> {{value}}',
    SIZE: '<strong>Tamanho:</strong> {{value}}',
    DURATION: '<strong>Duração:</strong> {{value}}',
    DIMENSIONS: '<strong>Dimensões:</strong> {{value.width}}x{{value.height}}',
    FORMAT_BOTH:
      '<strong>Formato:</strong> {{value.video}} video e {{value.audio}} audio',
    FORMAT_VIDEO: '<strong>Formato:</strong> {{value}} video',
    FORMAT_AUDIO: '<strong>Formato:</strong> {{value}} audio',
    BIT_RATE: '<strong>Taxa:</strong> {{value}} Mbps',
    FRAME_RATE: '<strong>Quadros:</strong> {{value}} fps',
    VIDEO_DIMENSIONS:
      '<strong>Resolução:</strong> {{value.width}}x{{value.height}}'
  },

  VOLUME_FILE_TYPES: {
    CHARACTERIZED: 'Caracterizado',
    UNCHARACTERIZED: 'Descaracterizado'
  },

  NATIONALITY: {
    BRAZILIAN: 'Brasileiro',
    FOREIGNER: 'Estrangeiro'
  },

  INPUT_UPLOAD: {
    INVALID_MIN_SIZE: 'Tamanho menor que o mínimo permitido.',
    INVALID_MAX_SIZE: 'Tamanho maior que o máximo permitido.',
    INVALID_MIN_TIME: 'Tempo menor que o mínimo permitido.',
    INVALID_MAX_TIME: 'Tempo maior que o máximo permitido.',
    INVALID_MAX_PAGES: 'Quantidade de paginas maior que o máximo permitido.'
  },

  PDF_VIEWER: {
    DIGITAL_SIGNATURE_WARNING: `Este modo de vizualização não suporta "Assinaturas Digitais", para visualiza-las baixe o documento`
  },

  BREADCRUMBS: {
    NOTICES: 'Editais',
    ENTITIES: 'Entidades',
    ATTACHMENTS_NOTICES: 'Anexos do Edital',
    USERS: 'Usuários',
    PUBLISHER_REQUESTS: 'Solicitações de Editoras',
    WARNING: 'Aviso geral',
    NOTICE: 'Edital',
    LIST_NOTIFICATIONS: 'Notificações',
    REPORT: 'Relatório',
    REPORTS: 'Relatórios',
    REPORTS_ATTRIBUTE: 'Relatório de Atributos Físicos',
    REPORTS_SUBSCRIPTION: 'Relatório Geral das Inscrições',
    REPORTS_SEARCH: 'Relatório da busca',
    QUALIFICATION: 'Habilitação',
    SETTINGS: 'Configuração',
    PUBLISHER_BANK: 'Informações bancárias',
    PUBLISHER_INFO: 'Informações da empresa',
    PUBLISHER_ADDRESS: 'Endereço da empresa',
    PUBLISHER_CALL: 'Listar convocação',
    FORM_COMMUNICATION_CHANNEL: 'Canal de Comunicação para Acessibilidade',
    INSERT_COLLECTION: 'Inserir coleção',
    COLLECTION_INFO: 'Dados da coleção',
    COLLECTION_DOCUMENTS: 'Documentos da Coleção',
    COLLECTION_VOLUME_LIST: 'Volumes',
    COLLECTION_VOLUME_DATA: 'Informações',
    COLLECTION_VOLUME_DOCUMENTS: 'Upload de Arquivos',
    COLLECTION_LIST_ATTRIBUTE: 'Listar Coleções da Editora',
    VOLUME_LIST_ATTRIBUTE: 'Listar volumes da coleção',
    PUBLISHER_VOLUME_CONTROL_ATTRIBUTE: 'Tela de controle',
    COLLECTION_VOLUME_AUTORSHIP: 'Autorias Vinculadas',
    COLLECTION_VOLUME_MATERIAL_SPECIFICATION: 'Especificações do material',
    COLLECTION_VOLUME_BOOK_CORE_SPECIFICATION:
      'Especificações do miolo do livro',
    COLLECTION_VOLUME_BOOK_COVER_SPECIFICATION:
      'Especificações da capa do livro',
    DILIGENCE_VOLUME_LIST: 'Diligências',
    DILIGENCE_VOLUME_LIST_ATTRIBUTE: 'Diligências',
    DILIGENCE_VOLUME_EDIT: 'Volume',
    DILIGENCE_COLLECTION_DOCUMENTS: '$t(BREADCRUMBS:COLLECTION_DOCUMENTS)',
    DILIGENCE_COLLECTION_INFO: '$t(BREADCRUMBS:COLLECTION_INFO)',
    DILIGENCE_COLLECTION_VOLUME: 'Especificações da capa',
    DILIGENCE_COLLECTION_VOLUME_AUTORSHIP:
      '$t(BREADCRUMBS:COLLECTION_VOLUME_AUTORSHIP)',
    DILIGENCE_COLLECTION_VOLUME_BOOK_CORE:
      '$t(BREADCRUMBS:COLLECTION_VOLUME_BOOK_CORE_SPECIFICATION)',
    DILIGENCE_COLLECTION_VOLUME_BOOK_COVER:
      '$t(BREADCRUMBS:COLLECTION_VOLUME_BOOK_COVER_SPECIFICATION)',
    DILIGENCE_COLLECTION_VOLUME_DATA: '$t(BREADCRUMBS:COLLECTION_VOLUME_DATA)',
    DILIGENCE_COLLECTION_MATERIAL_SPECIFICATION:
      '$t(BREADCRUMBS:COLLECTION_VOLUME_MATERIAL_SPECIFICATION)',
    VALIDATION_COLLECTIONS: 'Coleções em Validação',
    VALIDATION_VOLUMES: 'Volumes',
    VALIDATION_VOLUME: 'Volume',
    VALIDATION_VOLUME_ATTRIBUTE: 'Validar volume',
    PUBLISHER_COLLECTION_REPORTS: 'Relatórios da Coleção',
    CONSULTANT: 'Consultante',
    POST_SUBSCRIPTION_LIST: 'Lista de pós-inscrição',
    APPEAL_REQUEST: 'Solicitação do recurso',
    PUBLISHER_RULING: 'Dirigente',
    LIST_APPEALS: 'Analisar recursos',
    APPEAL_ANALYSIS: 'Validar solicitação de recurso',
    PARAMETRIZATION: 'Parametrização de atributos físicos',
    PEDAGOGICAL_ADJUSMENT_REQUEST: 'Solicitar correção da validação pedagógica',
    ASSIGN_CALL_ENABLER: 'Atribuir Habilitador',
    CALL_DETAILS: 'Visualizar convocação',
    CALL_PUBLISHER_DOCUMENTS: 'Documentos',
    PEDAGOGICAL_ADJUSMENT_SEND_VALIDATION: 'Enviar volumes validados',
    QUALIFICATION_ANALYSIS: 'Analisar Habilitação',
    QUALIFICATION_COLLECTION: 'Analisar Habilitação obra',
    CHOICE: 'Escolha',
    ANSWER_DILIGENCE_DETAILS: 'Responder diligência',
    EMITIR_OPINION_TECHNICIAN_TAB: 'Parecer Técnico',
    ANSWER_DILIGENCE: 'Responder Diligência',
    PUBLISHERS: 'Editoras',
    LIST_NOTIFICATION: 'Notificações',
    SCHOOL: 'Escola',
    SCHOOLS_LIST: 'Lista de Escolas',
    ADHESION: 'Adesão',
    REQUEST_ACCESS: 'Solicitar Acesso',
    REQUEST_ACCESS_V2: 'Solicitar Acesso',
    LITERARY_DIDACTIC_WORKS: 'Início',
    SELECT_PROFILE: 'Selecione o perfil',
    REQUEST_LIST: 'Lista de Vínculos com Unidade de Ensino',
    NEW_REQUEST: 'Gerenciar Vínculo',
    REGISTER_UPDATE: 'Atualização de Cadastro',
    CONFIRM_UPDATE: 'Confirmação de Registro',
    TEACHER: 'Solicitação de professor',
    STUDENT: 'Solicitação de estudante',
    IDENTIFY_USER: 'Identificação do usuário',
    GUARDIAN: 'Solicitação de responsável',
    TERMS_OF_USE: 'Termos de uso',
    SCHOOL_MANAGEMENT: 'Gestão de escolas',
    STUDENT_MANAGEMENT: 'Gestão de estudante',
    SUCCESS: 'Sucesso',
    COMPLETED: 'Solicitação Enviada',
    GUARDIAN_CONSENT_AGREEMENT: 'Termo de Consentimento',
    NOT_ALLOWED: 'Ação não permitida',
    RESOURCES_WORKS: 'Recursos Digitais Abertos',
    APPROVED: 'Solicitação aprovada'
  },

  MENU_SHORTCUT: {
    ADD_NEW_PROFILE: 'Realizar cadastro de novo perfil',
    SCHOOL_MANAGEMENT: 'Gestão de escolas',
    STUDENT_MANAGEMENT: 'Gestão de estudantes'
  },

  MENU: {
    INTERACTIVE_READER: 'Leitor Interativo do PNLD',
    OPEN_DIGITAL_RESOURCES: 'Recursos digitais abertos'
  },

  PUBLISHERS: {
    TITLE: 'Editoras',
    EDIT_PUBLISHER: 'Editar Editora',
    TERM_PLACEHOLDER:
      'Pesquise pelo Nome da Editora, Fantasia e nome do Responsável',
    TERM_RESPONSIBLE_PLACEHOLDER: 'Pesquise pelo Nome do Usuário ou CPF',
    NONE_RESULTS: 'Nenhuma editora encontrada',
    NONE_USER_RESULTS: 'Nenhum usuário encontrado',
    PUBLISHER_NAME: 'Nome da Editora',
    TRADE_NAME: 'Nome Fantasia',
    NAME: 'Nome',
    CNPJ: 'CNPJ',
    CPF: 'CPF',
    EMAIL: 'E-mail da Editora',
    PHONE_NUMBER: 'Telefone da Editora',
    CELLPHONE_NUMBER: 'Celular da Editora',
    RESPONSIBLE: 'Responsável',
    ACTIONS: 'Ações',
    BOUND_RESPONSIBLE: 'Responsável vinculado',
    PARTICIPATION_IN_NOTICE: 'Participações em edital',
    SEARCH_RESULT: 'Resultado da pesquisa',
    TERM_DESCRIPTION:
      'Pesquise usuário com perfil de responsável para vincular com a editora'
  },

  PUBLISHER_COLLECTION_REPORTS: {
    TITLE: 'Relatórios das coleções da editora na inscrição',
    COLLECTION_TITLE: 'Título da coleção',
    CODE: 'Código',
    LAST_UPDATE: 'Última atualização',
    NONE_RESULTS: 'Nenhuma coleção encontrada',
    DOWNLOAD_FULL_REPORT: 'Relatório completo da inscrição',
    DOWNLOAD_FULL_REPORT_ATTRIBUTE: 'Relatório completo com Atributos Físicos',
    DOWNLOAD_PUBLISHER_REPORT: 'Relatório da Empresa',
    DOWNLOAD_COLLECTION_REPORT: 'Relatórios das coleções',
    DOWNLOAD_COLLECTION: 'Baixar relatório da coleção',
    DOWNLOAD_REPORT: 'Relatório da inscrição',
    DOWNLOAD_REPORT_ATTRIBUTE: 'Relatório com Atributos Físicos',
    LIST_ATTRIBUTE: 'Atributos Físicos'
  },

  PUBLISHER_COLLECTION_LIST_ATTRIBUTE: {
    TITLE: 'Listar Coleções da Editora',
    SUB_TITLE: 'Estrutura Editorial',
    COMPANY: 'Empresa: ',
    CODE: 'Código da Coleção',
    COLLECTION: 'Coleção',
    CATEGORY: 'Categoria',
    STATUS: 'Status',
    CONFIRM_FINISH_VOLUME: 'Deseja finalizar o volume',
    CONFIRM_FINISH_ANALYSIS:
      'Ao desfazer a análise, o sistema retira a diligência e retorna a coleção para análise da instituição validadora. Deseja confirmar a ação?'
  },

  PUBLISHER_VOLUME_CONTROL_ATTRIBUTE: {
    OPTIONAL_TITLE:
      'Atenção, o volume é facultativo. Deseja cadastrar a(s) obra(s) para este volume?',
    SUB_TITLE: 'Estrutura Editorial',
    COMPANY: 'Empresa: ',
    CODE: 'Código da Coleção',
    COLLECTION: 'Coleção',
    CATEGORY: 'Categoria',
    STATUS: 'Status',
    NATIONALITY: 'Nacionalidade',
    BRAZILIAN: 'Brasileiro',
    FOREIGNER: 'Estrangeiro',
    CONFIRM_DELETE: ' Deseja excluir o registro?',
    NONE_RESULTS: 'Nenhum registro encontrado',
    UPLOAD_ATRIBUTE_SUBTITLE:
      'Upload de Arquivos do Volume - solicitados na fase de Atributos Físicos',
    UPLOAD_SUBTITLE:
      'Upload de Arquivos do Volume - solicitados na fase de Inscrição'
  },

  PUBLISHER_CALL: {
    TITLE: 'Lista de convocação',
    FILTER_TYPE_CALL: 'Tipo de convocação',
    FILTER_STATUS_CALL: 'Situação',
    COMPANY: 'Empresa',
    EDITAL_OBJECT: 'Edital/Objeto',
    TITLE_CALL: 'Título da convocação',
    TYPE_CALL: 'Tipo da convocação',
    QUALIFICATION_YEAR: 'Ano de atendimento',
    QUALIFICATION_YEAR_TYPE: 'Tipo de Habilitação',
    DATE: 'Data de Envio',
    DATE_LIMIT: 'Data Limite de Resposta',
    STATUS: 'Situação'
  },

  PUBLIC_CONTAINER: {
    TITLE: 'Programa Nacional do Livro e do Material Didático',
    SUB_TITLE: 'Portal do Livro Digital',
    LEGISLATION: 'Legislação',
    ACCESS_OF_INFORMATION: 'Acesso à Informação',
    ACCESSIBILITY: 'Acessibilidade',
    COPYRIGHT:
      'PNLD: há 85 anos democratizando conhecimento e cultura no Brasil',
    LOGO_PNLD: 'Governo Federal - União e reconstrução',
    PNLD: 'PNLD - Programa Nacional do Livro e do Material Didático'
  },

  LOGIN: {
    COGNITO_USER: 'Usuário Cognito',
    DISCLAIMER: 'Transformando vidas por meio da educação.',
    WELCOME: 'Seja bem-vindo ao Portal do Livro Digital do PNLD!',
    ACCESS: 'Acesse o Portal do Livro',
    PRIVACY_POLICY: `Ao utilizar nossos serviços, você concorda com a nossa política de privacidade. Para mais informações, <Link>clique aqui</Link>`,
    PRIVACY_POLICY_PROCEED: 'prosseguir',
    SUCCESS_MESSAGE_TITLE: 'Bem-vindo ao Portal do Livro Digital do PNLD',
    SUCCESS_MESSAGE: '<b>Sucesso</b> Seus dados foram autenticados',
    ACCESS_PNLD: 'Acessar o PNLD',
    SIGNIN: 'Entrar com gov.br',
    ALTERNATIVE_LOGIN: 'Login com o cognito',
    LOGOUT: 'Sair',
    USER: 'Usuário:',
    EMAIL: 'E-mail:',
    CPF: 'CPF:',
    STEP_PASSWORD_BOOK:
      'Para acessar o passo a passo de solicitação de senha do livro, ',
    STEP_PASSWORD_BOOK_LINK: 'clique aqui.'
  },
  BOOK_PASSWORD: {
    ALT_IMAGE_STEP: 'Passo a passo obtenção da senha do livro'
  },

  DOWNLOAD_READER: {
    DOWNLOAD: 'A leitura do livro é feita pelo app Thorium Reader',
    DOWNLOAD_APP:
      'Faça o <i>download</i> do app clicando em uma das opções abaixo:',
    PLATFORM: '{{platform}}'
  },

  TOKEN: {
    VALIDATION: 'Aguarde enquanto validamos o token...'
  },

  LOGOUT: {
    WAIT: 'Aguarde enquanto encerramos a sua sessão...'
  },

  UPDATE_REGISTER: {
    TITLE: 'Confirme os dados abaixo',
    HELLO: 'Olá',
    DOCUMENT: 'CPF',
    SCHOOL: 'Qual a sua unidade de ensino?',
    LEARNING_LEVEL: 'Qual seu ano/série?',
    DISCLAIMER:
      'Atenção: após confirmação os dados temporariamente não poderão ser alterados',
    EDUCATION_UNIT: 'Unidade de Ensino',
    IMG_DESCRIPTION:
      'Seu cadastro está desatualizado. Para continuar a utilizar o Portal do Livro Digital atualize sua escola e ano/série',
    TITLE_PAGE: 'Seu cadastro está desatualizado!',
    SUBTITLE:
      'Para continuar a utilizar o Portal do Livro Digital atualize sua escola e ano/série.'
  },

  CREATE_PASSWORD: {
    NEW_PASSWORD: 'Nova senha',
    FORM_FILLING: 'Preencha os dados do usuário:',
    DOCUMENT: 'CPF',
    PASSWORD: 'Senha',
    PASSWORD_PLACEHOLDER: 'Insira sua senha',
    REPEAT_PASSWORD: 'Repetir Senha',
    REPEAT_PASSWORD_PLACEHOLDER: 'Repita sua senha',
    SAVE: 'Salvar',
    PASSWORD_REQUIRED: 'Senha deve ser preenchida',
    CONFIRMATION_INVALID: 'Confirmação não confere com a senha informada',
    TERMS_CONFIRMATION_REQUIRED:
      'Para continuar é preciso aceitar os termos de uso',
    RECAPTCHA_REQUIRED: 'ReCaptcha deve ser informado',
    TERMS_OF_USE_MODAL_TITLE: 'Termos de uso',
    TERMS_OF_USE: 'Li e aceito os <Link>termos de uso</Link>.'
  },

  PASSWORD_UPDATED: {
    PASSWORD_UPDATED: 'Senha atualizada com sucesso!',
    LOGIN: 'Fazer login'
  },

  PASSWORD_VALIDATION: {
    VALIDATION_MUST_HAVE_LOWERCASE: 'Deve conter ao menos uma letra minúscula',
    VALIDATION_MUST_HAVE_UPPERCASE: 'Deve conter ao menos uma letra maiúscula',
    VALIDATION_MUST_HAVE_NUMBER: 'Deve conter ao menos um número',
    VALIDATION_MUST_HAVE_SPECIAL_CHARACTER:
      'Deve conter ao menos um caractere especial',
    VALIDATION_MUST_HAVE_MIN_LENGTH: 'Deve conter ao menos 11 caracteres',
    VALIDATION_MUST_BE_UNPRECEDENTED:
      'Não pode ser igual às últimas 5 senhas utilizadas anteriormente'
  },

  NO_ACCESS: {
    ACCESS_NOT_ALLOWED: 'Você não tem permissão para acessar esta aplicação!',
    ACCESS_HOME_PAGE: 'Ir para a página inicial',
    USER_OPTIONS: 'O que você pode fazer agora:'
  },

  ADMIN_HEADER: {
    RNP_HEADER: 'Gestor de Negócio',
    ATTENDANCE_HEADER: 'Atendimento'
  },

  PRIVATE_FOOTER: {
    COPYRIGHT:
      'PNLD: há 85 anos democratizando conhecimento e cultura no Brasil'
  },

  PRIVATE_HEADER: {
    TITLE: 'Programa Nacional do Livro e do Material Didático',
    SUB_TITLE: 'Portal do Livro Digital',
    LEGISLATION: 'Legislação',
    ACCESS_OF_INFORMATION: 'Acesso à Informação',
    ACCESSIBILITY: 'Acessibilidade'
  },

  PRIVATE_MENU: {
    NOTICE: 'Editais'
  },

  PUBLIC_HEADER: {},

  PUBLIC_FOOTER: {
    TERMS_OF_USE: 'Termos de Uso',
    PRIVACITY_POLICY:
      'PNLD: há 80 anos democratizando conhecimento e cultura no Brasil.'
  },

  CREATE_ACCOUNT: {
    NEW_USER: 'Novo usuário',
    FORM_FILLING:
      'Preencha o formulário abaixo. Em seguida enviaremos um e-mail para validar seu cadastro.',
    FIRSTNAME: 'Nome',
    FIRSTNAME_PLACEHOLDER: 'Insira seu nome',
    LASTNAME: 'Sobrenome',
    LASTNAME_PLACEHOLDER: 'Insira seu sobrenome',
    DOCUMENT: 'CPF',
    DOCUMENT_PLACEHOLDER: 'Insira seu CPF',
    EMAIL: 'E-mail',
    EMAIL_PLACEHOLDER: 'Insira seu e-mail',
    CREATE: 'Cadastrar',
    FIRSTNAME_REQUIRED: 'Nome deve ser preenchido',
    LASTNAME_REQUIRED: 'Sobrenome deve ser preenchido',
    DOCUMENT_REQUIRED: 'CPF deve ser preenchido',
    INVALID_DOCUMENT: 'CPF inválido',
    EMAIL_REQUIRED: 'E-mail deve ser preenchido',
    TERMS_CONFIRMATION_REQUIRED:
      'Para continuar é preciso aceitar os termos de uso',
    INVALID_EMAIL: 'E-mail inválido',
    ACCOUNT_CREATED: 'Conta cadastrada com sucesso!',
    GOT_IT: 'Entendi',
    EMAIL_SENT:
      'Foi enviado um e-mail para sua conta pessoal. Siga as instruções para ativar sua conta.'
  },

  CREATE_USER: {
    USERS: 'Usuários',
    NEW_USER: 'Cadastro de Usuário',
    FNDE: 'FNDE',
    RESPONSIBLE: 'Responsável da editora',
    IPT: 'Validador',
    CONSULTANT: 'Consultor',
    COORDINATOR: 'Coordenador triagem',
    INSTITUTION: 'Instituição de Ensino',
    ADMIN: 'Gestor de Negócio',
    ATTENDANCE: 'Atendimento',
    PROVIDER: 'Operadora',
    FORM_FILLING: 'Preencha os dados do usuário:',
    NAME: 'Nome',
    NAME_PLACEHOLDER: 'Insira o nome',
    LASTNAME: 'Sobrenome',
    LASTNAME_PLACEHOLDER: 'Insira o sobrenome',
    DOCUMENT: 'CPF',
    DOCUMENT_PLACEHOLDER: 'Insira o CPF',
    EMAIL: 'E-mail',
    EMAIL_PLACEHOLDER: 'Insira o e-mail',
    INSTITUTION_PLACEHOLDER:
      'Insira a Instituição de Ensino a que o usuário pertence',
    MANAGER_NAME: 'Diretoria / Gerência',
    MANAGER_NAME_PLACEHOLDER: 'Insira a sigla da Diretoria/Gerência',
    TELEPHONE_PROVIDER: 'Operadora',
    TELEPHONE_PROVIDER_PLACEHOLDER: 'Insira o nome da operadora',
    ROLE_INSTITUTION_OWNER_REQUIRED:
      'Usuário responsável por Instituição de Ensino',
    ROLE_TELEPHONE_PROVIDER_REQUIRED: 'Usuário responsável por Operadora',
    ROLE_ADMIN_REQUIRED: 'Usuário RNP',
    ROLE_REQUIRED: 'Tipo de usuário deve ser selecionado',
    CREATE: 'Cadastrar',
    CANCEL: 'Cancelar',
    NAME_REQUIRED: 'Nome deve ser preenchido',
    LASTNAME_REQUIRED: 'Sobrenome deve ser preenchido',
    DOCUMENT_REQUIRED: 'CPF deve ser preenchido',
    EMAIL_REQUIRED: 'E-mail deve ser preenchido',
    INSTITUTION_REQUIRED: 'Instituição de Ensino deve ser selecionada',
    TELEPHONE_PROVIDER_REQUIRED: 'Provedora deve ser selecionada',
    MANAGER_NAME_REQUIRED: 'Diretoria ou gerência deve ser preenchida',
    PROFILE_REQUIRED: 'Perfil deve ser preenchido',
    INVALID_DOCUMENT: 'CPF inválido',
    INVALID_EMAIL: 'E-mail inválido',
    USER_CREATED: 'Usuário cadastrado com sucesso!',
    GOT_IT: 'Entendi',
    EMAIL_SENT:
      'Foi enviado um e-mail para a conta pessoal do usuário com as instruções para ativar sua conta.'
  },

  EDIT_USER: {
    TITLE: 'Alterar cadastro de usuário',
    INSTITUTION: 'Instituição de Ensino',
    ADMIN: 'Gestor de negócio',
    PROVIDER: 'Operadora',
    NAME: 'Nome',
    NAME_PLACEHOLDER: 'Insira o nome',
    LASTNAME: 'Sobrenome',
    LASTNAME_PLACEHOLDER: 'Insira o sobrenome',
    DOCUMENT: 'CPF',
    DOCUMENT_PLACEHOLDER: 'Insira o CPF',
    EMAIL: 'E-mail',
    EMAIL_PLACEHOLDER: 'Insira o e-mail',
    INSTITUTION_PLACEHOLDER:
      'Insira a Instituição de Ensino a que o usuário pertence',
    MANAGER_NAME: 'Diretoria / Gerência',
    MANAGER_NAME_PLACEHOLDER: 'Insira a sigla da Diretoria/Gerência',
    TELEPHONE_PROVIDER: 'Operadora',
    TELEPHONE_PROVIDER_PLACEHOLDER: 'Insira o nome da operadora',
    ROLE_INSTITUTION_OWNER_REQUIRED:
      'Usuário responsável por Instituição de Ensino',
    ROLE_TELEPHONE_PROVIDER_REQUIRED: 'Usuário responsável por Operadora',
    ROLE_ADMIN_REQUIRED: 'Usuário RNP',
    ROLE_REQUIRED: 'Tipo de usuário deve ser selecionado',
    CREATE: 'Cadastrar',
    NAME_REQUIRED: 'Nome deve ser preenchido',
    LASTNAME_REQUIRED: 'Sobrenome deve ser preenchido',
    DOCUMENT_REQUIRED: 'CPF deve ser preenchido',
    EMAIL_REQUIRED: 'E-mail deve ser preenchido',
    INSTITUTION_REQUIRED: 'Instituição de Ensino deve ser selecionada',
    TELEPHONE_PROVIDER_REQUIRED: 'Provedora deve ser selecionada',
    MANAGER_NAME_REQUIRED: 'Diretoria ou gerência deve ser preenchida',
    INVALID_DOCUMENT: 'CPF inválido',
    INVALID_EMAIL: 'E-mail inválido',
    USER_CREATED: 'Usuário cadastrado com sucesso!',
    GOT_IT: 'Entendi',
    PROFILE: 'Perfil',
    SAVE: 'Atualizar',
    ROLE_ADMIN: 'Gestor de negócio',
    ROLE_ATTENDANCE: 'Atendimento',
    EMAIL_SENT:
      'Foi enviado um e-mail para a conta pessoal do usuário com as instruções para ativar sua conta.',
    CANCEL: 'Cancelar'
  },

  USER_MANAGEMENT: {
    USER_MANAGEMENT: 'Gerenciar Usuários',
    USERNAME: 'Usuário',
    INSTITUTION: 'Instituição',
    STATUS: 'Status',
    MANAGED_BY: 'Diretoria / Gerência',
    DATE: 'Data',
    NEW_USER: 'Novo Usuário',
    ACTIONS: 'Ações',
    ACTIVE: 'Ativo',
    INACTIVE: 'Inativo',
    PROVIDER: 'Operadora',
    EMAIL: 'E-mail',
    SEARCH_PLACEHOLDER:
      'Busca por usuário, e-mail, instituição, operadora ou diretoria/gerência.'
  },

  USER_ACTIONS: {
    FIRST_ACCESS: 'Reenviar link de primeiro acesso',
    DEACTIVATE: 'Desativar usuário',
    RESTORE: 'Reativar usuário',
    EDIT: 'Editar usuário',
    EDIT_ONLY: 'Editar',
    DELETE: 'Deletar usuário',
    CONFIRM_DELETE:
      'Tem certeza que deseja excluir esse usuário? Essa ação é permanente.',
    TITLE_RESET_AUTH: 'Recadastramento de Autenticação',
    OPTION_REQUIRED: 'Selecione a ação para seguir',
    REQUIRED: 'Campo obrigatório',
    NUMBER_CALLED: 'Número do chamado',
    NUMBER_CALLED_PLACEHOLDER: 'Insira o número do chamado',
    RESET_USER: 'Recadastramento de autenticação',
    ERROR_DELETE: 'Não foi possivel excluir o usuário',
    SUCCESS_DELETE: 'Usuário excluído com sucesso',
    RESET_MFA: 'Resetar autenticação via QRCode',
    RESET_CHALLENGE: 'Resetar perguntas de segurança',
    RESET_ALL: 'Resetar autenticação via QRCode e perguntas de segurança',
    ONE_OF_ITEMS_REQUIRED: 'Selecione ao menos um item a ser resetado',
    CONFIRM: 'Confirmar',
    PROVIDER: 'Operadora',
    REGISTER: 'Cadastrar',
    ATTACH: 'Vincular',
    CANCEL: 'Cancelar',
    CPF: 'CPF',
    EMAIL: 'E-mail',
    INSTITUTION: 'Instituição',
    NO_ACTION: 'Sem ação'
  },

  REQUEST_PASSWORD_RESET: {
    TITLE: 'Recuperação de Senha',
    USER: 'Usuário',
    USER_PLACEHOLDER: 'Insira seu CPF ou e-mail',
    CONFIRM: 'Confirmar',
    EMAIL_OR_DOCUMENT_REQUIRED: 'Digite um CPF ou um e-mail válidos',
    EMAIL_SENT: 'E-mail enviado!',
    EMAIL_SENT_CONFIRMATION:
      'Foi enviado um e-mail para sua conta pessoal. Siga as instruções para recuperar sua senha.',
    GOT_IT: 'Entendi'
  },

  FILE_UPLOAD: {
    UPLOAD_STATUS: 'Status do upload',
    FILE_MAX_SIZE: 'Tamanho máximo do arquivo: {{size}}mb',
    DEFAULT_ERROR: 'Não foi possível concluir o upload.',
    INVALID_EXTENSION:
      'Não foi possível concluir o upload, deve ser anexado um arquivo de extensão {{extension}}'
  },

  READ_AND_ACCEPT_TERMS_OF_USE: {
    READ_AND_ACCEPT: 'Li e aceito a ',
    TERMS_OF_USE: 'Termos de Uso',
    PRIVACITY_POLICY: 'Política de privacidade'
  },

  READ_TERMS_OF_USE: {
    READ_TERMS_OF_USE: 'Clique aqui para visualizar os termos de uso',
    READ_PRIVACITY_POLICY: 'Política de privacidade'
  },

  SELECT_PERIOD: {
    PERIOD: 'Filtrar por período',
    ALL_PERIOD: 'Todos os períodos',
    CURRENT_MONTH: 'Mês atual',
    LAST_MONTH: 'Mês anterior'
  },

  FORM_LOADING: {
    LOADING: 'Carregando...'
  },

  MFA_SETUP: {
    CONFIGURE_MFA: 'Configurar Código de Verificação',
    CONFIRM_CODE: 'Enviar Código',
    DOWNLOAD_AUTHENTICATOR:
      'Baixe o aplicativo de autenticação em seu celular para escanear o código QR:',
    COPY_SECRET_1: 'Ou',
    COPY_SECRET_2: 'clique aqui',
    COPY_SECRET_3:
      'para copiar o código de autenticação e utilizar em um aplicativo em seu computador.',
    DOWNLOAD_ON_GOOGLE_PLAY: 'Baixar Google Authenticator para Android',
    DOWNLOAD_ON_APP_STORE: 'Baixar Google Authenticator para iOS',
    APP_STORE: 'App Store',
    GOOGLE_PLAY: 'Google Play',
    TOKEN_EXCEPTION:
      'Código inválido. Tente um novo código ou cadastre o QRCode novamente.',
    INTERNAL_ERROR_EXCEPTION: 'Falha na conexão, tente novamente.',
    DEFAULT_ERROR: 'Não foi possível concluir a requisição.'
  },

  MFA_TOKEN: {
    MFA: 'Código de Verificação',
    CONFIRM_CODE: 'Enviar Código',
    TOKEN_EXCEPTION: 'Código inválido. Tente um novo código',
    INTERNAL_ERROR_EXCEPTION: 'Falha na conexão, tente novamente.',
    DEFAULT_ERROR: 'Não foi possível concluir a requisição.'
  },

  SELECT_SECURITY_QUESTIONS: {
    SAVE: 'Salvar',
    ANSWER: 'Resposta',
    ANSWER_PLACEHOLDER: 'Resposta:',
    SELECT_QUESTIONS_TITLE:
      'Escolha e responda as duas perguntas abaixo para prosseguir:',
    SELECT_QUESTIONS: 'Selecione uma pergunta...',
    SECURITY_QUESTIONS: 'Perguntas e respostas',
    QUESTION_REQUIRED: 'Pergunta deve ser selecionada',
    ANSWER_REQUIRED: 'Resposta deve ser informada',
    FIRST_QUESTION: 'Pergunta 1',
    SECOND_QUESTION: 'Pergunta 2',
    HINT: 'Dica: ',
    HINT_TEXT:
      'Escolha perguntas que possa lembrar facilmente a resposta, pois esse processo será crucial para recuperação de senha no futuro.'
  },
  DATA_PROCESSING_AGREEMENT: {
    TITLE: 'Termo de Acordo de Processamento de Dados',
    CONTINUE: 'Continuar',
    READ_AND_ACCEPT:
      'Declaro que li e aceito o termo do Acordo de Processamento de Dados.',
    PROCESSING_AGREEMENT_REQUIRED:
      'Leia e aceite o termo do Acordo de Processamento de Dados para prosseguir.',
    SUCCESS: 'Aceite realizado com sucesso',
    PAGINATION: '{{pageNumber}} de {{numPages}}'
  },

  RESET_PASSWORD: {
    ANSWER: 'Resposta:',
    ANSWER_REQUIRED: 'Resposta da pergunta deve ser preenchida',
    QUESTION_REQUIRED: 'Houve um erro ao buscar a pergunta de segurança',
    BACK_TO_HOME: 'Voltar para página inicial',
    INVALID_TOKEN: 'Seu token é inválido ou expirou.',
    LINK_EMAIL: 'Informe seu e-mail ',
    TEXT_TOKEN: 'para gerar um novo token <br />ou volte para a ',
    LINK_INICIAL: 'página inicial'
  },

  COPIED_POPUP: {
    COPIED: 'Copiado com sucesso!'
  },
  LIST: {
    NONE_RESULTS: 'Nenhum registro encontrado'
  },
  LIST_NOTIFICATIONS: {
    TITLE: 'Notificações'
  },
  NOTICE: {
    TITLE: 'Editais',
    SUCCESS_MESSAGE: 'Test success msg from translate',
    ERROR_MESSAGE: 'Test error msg from translate',
    POPUP_CALL: 'Convocar',
    POPUP_QUALIFICATION: 'Habilitação',
    POPUP_ENABLER: 'Atribuir Habilitador',
    POPUP_NOTICE: 'Editar'
  },
  LIST_OF_QUALIFICATION_ANALYSIS: {
    TITLE: 'Habilitação',
    YEAR: 'Ano de Atendimento',
    APPROVED: 'Habilitada',
    REPROVED: 'Inabilitada',
    IN_ANALYSIS: 'Em análise',
    PARTIALLY_APPROVED: 'Habilitada Parcialmente',
    FILTER_YEAR: 'Ano de atendimento',
    FILTER_TYPE: 'Tipo de Habilitação',
    ROW_CNPJ: 'CNPJ',
    ROW_COMPANY: 'Empresa',
    ROW_COLLECTION_VOLUMES: 'Coleção (Volume)',
    ROW_YEAR: 'Ano Atendimento',
    ROW_RETURN: 'Retorno Diligência',
    ROW_SITUATION: 'Situação Análise',
    ROW_ENABLER: 'Habilitador Responsável',
    TAB_DILIGENCE_QUALIFICATION_TYPE: {
      YES: 'Sim',
      NO: 'Não'
    }
  },
  ATTACHMENTS_NOTICES: {
    CONFIRM_DELETE: 'Deseja excluir o registro?',
    TITLE: 'Anexos do Edital'
  },

  WARNING: {
    TITLE: 'Aviso geral',
    REGISTER: 'Cadastrar Aviso',
    ROW_SUBTITLE: 'Assunto',
    ROW_EDITAL_OBJECT: 'Edital/objeto',
    ROW_DATE: 'Data de vigência',
    ROW_ROLE: 'Perfil',
    ROW_LOCAL: 'Apresentação',
    ROW_STATUS: 'Situação',
    DLG_TITLE_COPY_BODY: 'Deseja copiar o registro?',
    DLG_TITLE_DELETE_BODY: 'Deseja excluir o registro?',
    EDITAL_OBJECT: 'Edital / Objeto',
    LOCATION: 'Apresentação',
    SITUATION: 'Situação',
    SEARCH_PLACEHOLDER: 'Pesquisar pelo Assunto e conteúdo'
  },
  WARNING_MODAL: {
    TITLE: 'Aviso Geral',
    SUBJECT: 'Assunto do aviso',
    EDITAL: 'Edital/objeto',
    PERMISSION: 'Perfil',
    LOCATION: 'Apresentação',
    OPENING_DATE_TIME: 'Data e Hora da Abertura',
    CLOSING_DATE_TIME: 'Data e Hora do Fechamento',
    ACTIVE: 'Ativo',
    SITUATION: 'Situação',
    STATE: 'Estado',
    MESSAGE: 'Mensagem'
  },
  ENTITIES: {
    MENU_TITLE: 'Entidades',
    TITLE: 'Lista de Entidades',
    SEARCH_PLACEHOLDER: 'Nome Entidade',
    SEARCH_BUTTON: 'Buscar',
    NONE_RESULTS: 'Nenhum registro encontrado.',
    REGISTER_ENTITY: 'Cadastrar Entidade',
    ACTIONS: {
      EDIT: 'Editar',
      ACCESSION: 'Adesão',
      MODEL_CHOICE: 'Modelo Escolha'
    },
    SPHERE: {
      TODOS: 'Todos',
      ESTADUAL: 'Estadual',
      FEDERAL: 'Federal',
      MUNICIPAL: 'Municipal'
    },
    MODAL_FILTER: {
      TITLE: 'Pesquisar Entidade',
      NAME: 'Nome Entidade',
      SPHERE: 'Esfera'
    },
    FORM: {
      IDENTIFICATION: 'Identificação',
      IBGE_CODE: 'Código IBGE',
      SPHERE: 'Esfera',
      CEP: 'CEP',
      COMPLEMENT: 'Complemento',
      NUMBER: 'Número',
      NEIGHBORHOOD: 'Bairro',
      PHONE_1: 'Telefone 1',
      PHONE_2: 'Telefone 2',
      CPF: 'CPF',
      NAME: 'Nome',
      DESCRIPTION: 'Descrição',
      CNPJ: 'CNPJ',
      ADDRESS: 'Endereço',
      RESPONSIBLE: 'Informações Dirigente',
      RG: 'Identidade',
      EXPEDITOR: 'Órgão Emissor',
      EXPEDITOR_DATE: 'Data Emissão Identidade',
      JOB: 'Cargo/Função'
    },
    LIST_HEADER: {
      UF: 'UF',
      SPHERES: 'Esferas',
      ENTITIES: 'Entidade',
      ACTIONS: 'Ações'
    }
  },

  IPT: {
    TAB_SUBSCRIPTION: 'Validação da Inscrição',
    TAB_ATTRIBUTE: 'Atributos Físicos',
    TAB_QUALIFICATION_DILIGENCE: 'Habilitação',

    TITLE_VIEW: 'Visualizar volumes da coleção',
    TITLE_VALIDATE: 'Validar volumes da coleção',
    TITLE_VALIDATE_ATTRIBUTE: 'Validar a inscrição do volume',
    TITLE_VIEW_ATTRIBUTE: 'Visualizar a inscrição do volume',
    SUBTITLE_ATTRIBUTE: 'Atributos Físicos',
    SUBTITLE_VALIDATE: 'Validação da Inscrição',
    LIST_COLLECTIONS_TITLE: 'Triagem',
    LIST_COLLECTIONS_SUBSCRIPTION_TITLE:
      'Editoras com Coleções em Validação da Inscrição',
    LIST_COLLECTIONS_ATTRIBUTE_TITLE:
      'Editoras com Coleções em Análise de Atributos Físicos',
    LIST_COLLECTION_CODE: 'Coleção Cód.',
    LIST_COLLECTION_NAME: 'Nome coleção',
    LIST_COLLECTION_DILIGENCE_REVIEW_STATUS: 'Situação',
    LIST_COLLECTION_AREA: 'Área',
    LIST_COLLECTION_COMPONENT: 'Componente',
    LIST_COLLECTION_DILIGENCE_NUMBER: 'Nº Diligência',
    LIST_COLLECTION_DUE_DATE: 'Prazo correção',
    LIST_COLLECTION_DILIGENCE_ITEMS: 'Obras diligência',
    LIST_COLLECTION_DILIGENCE_VOLUMES: 'Volumes',
    LIST_DILIGENCIES_TITLE: 'Diligências',
    LIST_VOLUMES_TITLE_VALIDATED: 'Validar volumes da coleção',
    LIST_VOLUMES_TITLE_READONLY: 'Visualizar volumes da coleção',
    COLLECTION_VALIDATION_SUBTITLE: 'Validação da coleção',
    COLLECTION_VALIDATION_VALIDATED: 'Obra validada?',
    SUCCESS_SEND_TO_COORDINATOR:
      'Dados da análise gravados com sucesso. A análise será encaminhada para o(s) Coordenadores.',
    COLLECTION_VALIDATION_ADJUSTMENTS: 'Observações para ajuste',
    COLLECTION_VALIDATION_COMPLEMENTARY:
      'Documento complementar - (Caso necessite complementar as informações de validação)',
    COLLECTION_VALIDATION_ALLOW_COMPLEMENTARY:
      'Permitir o Documento complementar visível ao responsável da editora?',
    COLLECTION_VALIDATION_FINAL: 'Documento de parecer final',
    CHANGE_DILIGENCE_DATE: 'Alterar o prazo da diligência',

    ANALYSIS_SHEET_NAME: 'Ficha de Análise',
    ANALYSIS_SHEET: `
      <strong>$t(VOLUME_FILE_TYPES:CHARACTERIZED)</strong>:  {{totalVolumesVersionCharacterize}} ({{totalSheetsCompletedCharacterize}})
      <br />
      <strong>$t(VOLUME_FILE_TYPES:UNCHARACTERIZED)</strong>:  {{totalVolumesVersionUncharacterized}} ({{totalSheetsCompletedUncharacterized}})
    `,
    VOLUME_CODE: 'Código do volume',
    VOLUME: 'Volume',
    GRADE: 'Série',
    FORMAT: 'Formato',
    COPY: 'Exemplar',
    ORIGIN: 'Origem',
    SITUATION: 'Situação',

    COMPANY: 'Empresa',
    EDITAL: 'Edital',
    COLLECTION_VOLUME: 'Coleção (Volume)',
    NOT_RATED: 'Não validadas',
    VALIDATED: 'Validadas',
    DILIGENCE: 'Diligência(s)',
    ACTIONS: 'Ações',

    UPDATED_SUCCESS: 'Dados da análise gravados com sucesso',
    UPDATED_SUCCESS_DILIGENCE:
      'Dados da análise gravados com sucesso. A diligência da obra foi encaminhada para o responsável da editora fazer as correções.',
    UPDATED_SUCCESS_DILIGENCE_NUMBER:
      'Dados da análise gravados com sucesso. A diligência {{diligence}} da obra foi encaminhada para o responsável da editora fazer as correções.',
    NO_DILIGENCE_DATA: 'Nenhuma diligência encontrada.',
    NO_PUBLISHER_DATA: 'Não existe editora para validar.',

    ADJUSTED: 'Corrigido',

    RETURN: 'Devolver',
    RETURN_TITLE_MODAL: 'Devolver coleção',
    ANALYZED_DATE: 'Data da análise',
    SEND_TO_COORDINATOR: 'Enviar para coordenador',
    COLLECTION_DRAFT_NOTES: 'Observações sobre a validação (rascunho)',
    DRAFT_DATE: 'Data de atualização (rascunho)',
    ANALYZED_BY: 'Analisado por',
    COLLECTION_ANALYSIS_TITLE: 'Análise de coleção',
    VALIDATOR_NAME: 'Validador',
    RETURN_ADJUSTMENTS_NOTES: 'Observações da devolução',
    RETURN_MSG_CONFIRM:
      'Ao devolver a validação para o validador, a validação da coleção estará aberta para ajustes do(s) validadores. Deseja continuar?',
    SEND_TO_COORDINATOR_MSG_CONFIRM:
      'Ao salvar a validação, as informações serão encaminhadas para verificação do Coordenador e não permitirá desfazer a ação. Deseja continuar?',

    DILIGENCE_DEADLINE_MODAL_TITLE: 'Prazo da diligência',
    DILIGENCE_DEADLINE_TITLE:
      'Definir prazo de correção da diligência para editora',
    DEADLINE_TYPE_AUTOMATIC: 'Prazo automático',
    DEADLINE_TYPE_AUTOMATIC_UTIL_DAYS: 'dias úteis',
    DEADLINE_TYPE_MANUAL: 'Prazo manual',
    DEADLINE_DATE_START: 'Data inicial do prazo',
    DEADLINE_DATE_END: 'Data final do prazo'
  },
  REPORTS: {
    TITLE: 'Relatórios',
    TITLE_ATTRIBUTE: 'Relatório de Atributos Físicos',
    TITLE_SUBSCRIPTION: 'Relatório Geral das Inscrições',
    GENERATE_XLS: 'Gerar XLS',
    GENERAL_REGISTRATION: 'Relatório Geral das Inscrições',
    SEARCH_RESULT: 'Resultado da busca',
    RESULT_LIST: 'Lista do resultado',
    EDITAL: 'Edital *',
    OBJECT: 'Objeto *',
    PUBLISHER: 'Editora',
    AUTHORSHIP: 'Autoria',
    COLLECTION: 'Coleção',
    VOLUME: 'Volume',
    AREA: 'Área',
    COMPONENT: 'Componente',
    CATEGORY: 'Categoria',
    DETAIL: 'Detalhamento',
    FORMAT: 'Formato',
    SERIE: 'Série',
    EXEMPLE: 'Exemplar',
    APPEAL: 'Recurso',
    REGISTRATION_VALIDATION: 'Validação da Inscrição',
    GENRE: 'Gênero',
    PEDAGOGICAL_ANALYSIS: 'Avaliação pedagógica',
    THEME: 'Tema',
    PHYSICAL_ATTRIBUTES: 'Atributos físicos',
    QUALIFICATION: 'Habilitação',
    GENERATE_CSV: 'Gerar CSV',
    COLLECTION_CODE: 'Código Coleção',
    VOLUME_CODE: 'Código Volume',
    DONWLOAD_REPORT: 'Download do relatório',
    SEARCH_RESULT_XLS: 'Resultado da busca - Relatório em XLS',
    DATE_TIME_LAST_SEARCH: 'Data e hora da última busca:'
  },

  FNDE: {
    SETTINGS_TITLE: 'Configuração',
    ADHESION_TITLE: 'Adesão',
    FORM: 'Formulário',
    STATIC_FIELDS_LABEL: 'Campos fixos',
    AUXILIARY_FIELDS_LABEL: 'Campos auxiliares',
    ADHESION_TAB_LABEL: 'Adesão',
    INITIAL_PAGE_TAB_LABEL: 'Tela inicial',
    CHOOSE_MODEL_LABEL: 'Modelo de escolha',
    SEARCHBAR_PLACEHOLDER: 'Pesquisar pelo nome do item',
    ADD_ITEM_TRIGGER_LABEL: 'Cadastrar Item',
    DELETING_CONFIRMATION_TITLE: 'Excluir Item',
    DELETING_CONFIRMATION_MESSAGE: 'Deseja excluir o registro?',
    STATIC_FIELD_MODAL_TITLE: 'Editar Campo Fixo',
    AUXILIARY_FIELD_MODAL_TITLE: 'Editar Campo Auxiliar',
    ITEM_EDITING_MODAL_TITLE: 'Editar Item',
    ITEM_ADDING_MODAL_TITLE: 'Cadastrar Item',
    DISABLE_CPF: 'Desabilitar o CPF no cadastro de autoria'
  },

  ADHESION: {
    TITLE: 'Habilitar Adesão',
    ENABLE_FASE: 'Habilitar fase de Adesão',
    ADHESION_SUBTITLE: 'Adesão',
    ADHESION_PARAMETERS: 'Parametros da Adesão',
    DIDATICAL: 'Obras Didáticas',
    PEDAGOGICAL: 'Obras Pedagógicas',
    LITERARY: 'Obras Literárias',
    EJA: 'Obras EJA',
    EDUCATION: {
      CHILD_EDUCATION: 'Educação Infantil',
      ELEMENTARY_EDUCATION_PRIMARY_YEARS: 'Ensino Fundamental - Anos Iniciais',
      ELEMENTARY_EDUCATION_LAST_YEARS: 'Ensino Fundamental - Anos Finais',
      HIGHSCHOOL: 'Ensino Médio'
    }
  },

  MANAGE_FORM: {
    PLACEHOLDER_SEARCH: 'Pesquise pelo Nome do formulário',
    STATUS: 'Fase',
    EDITAL: 'Edital',
    REGISTER_FORM: 'Cadastrar Formulário',
    QUESTION_TITLE: 'Pergunta/Título',
    CODE: 'Código',
    IS_TITLE: 'É um título?',
    ALL: 'Todos',

    MODAL_COPY_FORM: {
      TITLE: 'Copiar o formulário',
      CONFIRM_TITLE: 'Confirmar a cópia do formulário?',
      FILE_NAME: 'Nome da ficha',
      VERSION: 'Versão',
      TOTAL_ITENS: 'Total itens',
      CODE: 'Código',
      IS_TITLE: 'É título?'
    }
  },

  MANAGE_FORM_LABELS: {
    TITLE: 'Cadastrar formulário',
    FORM_NAME: 'Nome do formulário',
    QUESTION_TITLE_QUANTITY: 'Pergunta / Título',
    PHASE: 'Fase',
    EDITAL: 'Edital',
    OBJECT: 'Edital - Objeto',
    VERSION: 'Versão',
    ACTION: 'Ação',
    CHARACTERIZED: 'Caracterizado',
    UNCHARACTERIZED: 'Descaracterizado',
    PHASE_SUBTYPE: 'Fase - subtipo',
    UPDATE_WARNING:
      'O formulário possui vínculos e será limitado a edição das informações.'
  },

  STATIC_FIELD_LABELS: {
    NAME: 'Nome do campo',
    LABEL: 'Label',
    ACTIONS: 'Ações'
  },
  STATIC_ITEM_LABELS: {
    CODE: 'Código',
    NAME: 'Nome do item',
    NOTE: 'Observações',
    TYPE: 'Tipo',
    ACTIONS: 'Ações'
  },
  AUXILIARY_FIELD_LABELS: {
    NAME: 'Nome do campo',
    ACTIONS: 'Ações'
  },
  AUXILIARY_ITEM_LABELS: {
    NAME: 'Nome do item',
    ACTIONS: 'Ações'
  },

  INITIAL_PAGE: {
    TITLE: 'Titulo',
    APRESENTATION: 'Texto de Apresentação'
  },

  PAGES_LOCATION: {
    INITIAL_PAGE: 'Tela inicial',
    SUBSCRIPTION_CONTROL_PAGE: 'Tela de Controle da inscrição',
    SUBSCRIPTION_COLLECTION_VOLUME_LIST_PAGE: 'Lista de volumes da coleção',
    SUBSCRIPTION_VOLUME_CONTROL_PAGE: 'Tela de controle do volume'
  },

  WARNINGS_TYPE: {
    INFO: 'Informativo',
    WARNING: 'Atenção',
    SUCCESS: 'Sucesso',
    ERROR: 'Erro'
  },

  CHOOSE_MODEL_FIELDS_LABEL: {
    TITLE: 'Seção Apresentação'
  },

  ADHESION_FIELD_LABELS: {
    SECTION_ORIENTATION: 'Seção Orientações',
    SECTION_ADHESION: 'Seção Aderir ao PNLD',
    SECTION_MODAL: 'Modal Justificar Exclusão de Participação no PNLD'
  },

  USERSLIST: {
    TITLE: 'Usuários',
    USER_NAME: 'Nome do usuário',
    PROFILE: 'Perfil',
    ADD_USER_TITLE: 'Cadastrar Usuário',
    USER_LIST_TITLE: 'Últimos usuários cadastrados',
    SEARCH_PLACEHOLDER: 'Pesquise pelo Nome do Usuário ou CPF',

    CONFIRM_DIALOG: {
      TOGGLE: {
        TITLE: 'Alterar status',
        CONTENT:
          'Tem certeza que deseja alterar o status do usuário <strong>{{ name }}</strong>?'
      },
      RESET_PASSWORD: {
        TITLE: 'Resetar senha',
        CONTENT:
          'Deseja reiniciar a senha do usuário <strong>{{ name }}</strong>?'
      },
      RESET_ANSWERS: {
        TITLE: 'Resetar senha e perguntas de segurança',
        CONTENT:
          'Deseja reiniciar a senha e as perguntas de segurança do usuário <strong>{{ name }}</strong>?'
      }
    }
  },

  OBJECT: {
    TITLE: 'Cadastro do Objeto',
    EXPIRED_OBJECT_MESSAGE: 'Data de vigência deste objeto expirou.',
    PUBLISHER_NOT_FOUND:
      'Não foram encontrados resultados referentes a busca {{ currTerm }}. Pesquise novamente.',
    PUBLISHER_BIND: 'Vincular Editora',
    PUBLISHER_CREATE: 'Cadastrar Editora',
    PUBLISHER_BIND_SUCCESS: 'Editora vinculada com sucesso!',
    PUBLISHER_SEARCH: 'Insira o nome da Editora',
    PUBLISHER_LAST_BOUND: 'Últimas Editoras Vinculadas',
    TABS: {
      DETAILS: 'Objeto',
      PUBLISHER: 'Editoras',
      CATEGORY: 'Categoria',
      ORGANIZATION: 'Organização',
      POST_REGISTRATION: 'Pós-inscrição',
      QUALIFICATION: 'Habilitação',
      PARAMETERIZATION: 'Atributos Físicos',
      CHOICE: 'Escolha'
    }
  },

  OBJECT_DETAILS: {
    OBJECT_DATA: {
      TITLE: 'Dados do objeto',
      NAME: 'Nome',
      CODE: 'Código',
      ITEMS_LIMIT: 'Limite de Obras'
    },

    ENABLE_STAGES: {
      TITLE: 'Habilitar fases'
    },

    TABS: {
      PUBLISHER_REGISTRATION: 'Inscrição das Editoras',
      REGISTRATION_VALIDATION: 'Validação da Inscrição',
      VALIDATION_RESOURCES: 'Recursos',
      QUALIFICATION: 'Habilitação',
      PEDAGOGICAL_EVALUATION: 'Avaliação pedagógica'
    },

    PUBLISHER_REGISTRATION: {
      TITLE: 'Inscrição das Editoras',
      ENABLE_STAGES: 'Habilitar fase de Inscrição das obras',
      ENABLE_REGISTRATION: 'Habilitar o Cadastro de obras'
    },

    REGISTRATION_VALIDATION: {
      TITLE: 'Validação da inscrição',
      ENABLE: 'Habilitar fase de Validação da inscrição'
    },

    POST_SUBSCRIPTION: {
      TITLE: 'Pós-inscrição',
      ENABLE: 'Habilitar Pós-inscrição',
      PRESENTATION_TEXT:
        'Texto de apresentação da fase de inscrição das Obras/Editoras'
    },

    APPEAL_VALIDATION: {
      TITLE: 'Recurso da fase de Validação da inscrição',
      ENABLE: 'Habilitar recurso da fase de Validação da inscrição'
    },

    QUALIFICATION: {
      TITLE: 'Habilitação',
      ENABLE: 'Habilitar fase de Habilitação',
      ENABLED_YEARS: 'Ano(s) de Atendimento',
      TITLE_PHASE: 'Recurso da fase de Habilitação',
      ENABLE_PHASE: 'Habilitar recurso da fase de Habilitação'
    },

    PEDAGOGICAL_EVALUATION: {
      TITLE: 'Avaliação pedagogica',
      ENABLE: 'Habilitar fase de avaliação pedagogica'
    },
    PHYSICAL_ATTRIBUTE: {
      TITLE: 'Recurso da fase de Atributos Físicos',
      ENABLE_PAHSE: 'Habilitar fase de Recurso do Atributos Físicos TESTE'
    },
    OPENING_DATE_TIME: 'Data e hora de abertura',
    CLOSING_DATE_TIME: 'Data e hora de fechamento',

    CHOOSE_MODEL: {
      TITLE: 'Modelo de Escolha',
      ENABLE: 'Habilitar Modelo de Escolha'
    }
  },

  CHOICE: {
    SUBTITLE: 'Texto de Apresentação - ',
    FILES_SUBTITLE: 'Arquivos',
    PARAMETRIZATION_SUBTITLE: 'Parametrização da Escolha',
    PARAMETRIZATION_DELETE_MGS:
      'Tem certeza que deseja excluir a Parametrização da Escolha',
    TABS: {
      GUIDELINES: 'Orientações',
      TERMS_CONDITIONS: 'Termos e Condições',
      SPECIFICATIONS: 'Especificações',
      COMPOSITION_ACERVE: 'Composição de Acervos',
      REGISTER_COLLECTION: 'Cadastrar Acervo',
      REGISTER_COLLECTIONS: 'Cadastrar Acervos',
      REGISTRATION_OF_CHOICE: 'Registro de Escolha',
      FINISHED_OF_CHOICE: 'Finalização da Escolha'
    },
    PARAMETERS: {
      CREATE: 'Criar Parâmetro'
    }
  },

  PEDAGOGICAL_ASSESSMENT: {
    TITLE: 'Avaliação pedagógica',
    ENABLE_PEDAGOGICAL_APPEAL: 'Habilitar Recurso da Avaliação pedagógica',
    OPENING_DATE_AND_TIME: 'Data e Hora de Abertura',
    CLOSING_DATE_AND_TIME: 'Data e Hora de Fechamento',
    ENABLE_PEDAGOGICAL_ADJUSTMENT: 'Habilitar Correção da Avaliação pedagógica',
    DATE_GREATER_THAN_APPEAL:
      'A Data selecionada tem que ser maior que a inicial',
    DATE_GREATER_THAN_ADJUSTMENT:
      'A Data selecionada tem que ser maior que a inicial'
  },

  OBJECT_ORGANIZATION: {
    TITLE: '01 - Categoria - Obra didática por área',
    ADD_NEW_COLLECTION: 'Cadastrar Coleção',
    COLLECTION: 'Coleção',
    AREA: 'Área',
    COMPONENT: 'Componente',
    VOLUME: 'Volume',
    ACTIONS: 'Ações',
    CONFIRM_DELETE: 'Deseja excluir o registro ?',
    CONFIRM_DUPLICATE: 'Deseja duplicar o registro ?'
  },

  AUXILIARY_FIELD: {
    THEME: 'Tema',
    GENRE: 'Gênero literário',
    GENRE_SECONDARY: 'Gênero majoritário',
    LANGUAGE: 'Idioma',
    RELATIONSHIP: 'Relacionamento',

    COVER_PAPER_TYPE: 'Tipo do papel(capa)',
    COVER_FINISHING_TYPE: 'Tipo do acabamento(capa)',

    PAGE_PAPER_TYPE: 'Tipo do papel(miolo)',
    PAGE_FINISHING_TYPE: 'Tipo do acabamento(miolo)',
    OTHER_DEFINITION: 'Definir',
    OTHER_NAME: 'Nome',
    OTHER_JUSTIFICATION: 'Justificar'
  },

  OBJECT_COLLECTION: {
    CREATE_TITLE: 'Cadastrar Coleção',
    EDIT_TITLE: 'Editar Coleção',

    COLLECTION_CODE: 'Código da coleção',
    CATEGORY_AND_DETAILS: 'Categorias e níveis',
    CATEGORY: 'Categoria',
    CATEGORY_DETAIL: 'Detalhamento (nível)',
    GENERAL_INFO: 'Informações Gerais',
    AREA: 'Área',
    AREA_QUANTITY: 'Quantidade por área',
    AREA_LABEL: 'Label da área para editora',
    COMPONENT: 'Componente',
    COMPONENT_LABEL: 'Label do componente para editora',

    BOOKS: 'Número de páginas',
    BOOKS_LABEL: 'Exemplar',
    PAGES: 'Páginas (Mínimo - Máximo)',
    MIN_PLACEHOLDER: 'Min.',
    MAX_PLACEHOLDER: 'Max.',

    DESCRIPTION: 'Descrição',
    THEME: 'Temas',
    GENRE: 'Gêneros literários',
    ENABLE_MAJOR_GENRE: 'Habilitar gênero majoritário',
    OPTIONAL_FIELD: 'Apresentar campos',
    LANGUAGE: 'Idiomas',
    RELATIONSHIP: 'Relacionamento',

    BOOKS_HELPER_TEXT:
      'Caso o número máximo de páginas estiver definido por volume, deixe essa informação vazia.'
  },

  OBJECT_COLLECTION_VOLUME: {
    CREATE_TITLE: 'Adicionar Volume',
    EDIT_TITLE: 'Editar Volume',
    VOLUME_CODE: 'Código do volume',
    GENERAL_INFO: 'Informações Gerais',
    FORMAT: 'Formato do volume',
    EXEMPLE: 'Exemplar',
    RESOURCETYPE: 'Tipo de recurso',
    SERIES: 'Série',
    DESCRIPTION: 'Descrição',
    OPTIONAL_VOLUME: 'Volume facultativo',
    DIGITAL: 'Obra digital',
    EXTENSION: 'Extensão do arquivo para upload',

    FILES_TO_UPLOAD: 'Arquivo(s) para upload',
    NAME: 'Nome',
    SIZE: 'Tamanho (MB)',
    DURATION: 'Duração (minutos)',
    PAGES: 'Páginas',
    VERSION: 'Versão',
    ACTIONS: 'Ação',
    MIN: 'Min.',
    MAX: 'Max.',
    SHOW_KEYWORD: 'Exibir palavra chave',
    TOTAL_FILES: 'Total de arquivos no volume:',
    ADD: 'Adicionar',

    FIELDS: 'Apresentar campos',
    AUTHOR: 'Autoria',

    DIMENSIONS: 'Dimensões',
    DIMENSIONS_LABEL: 'Tipo de dimensões',
    FREE_HINT:
      'Preenchimento livre pela editora os campos de largura e altura na inscrição da obra.',
    FREE: 'Livre',
    FIXED: 'Fixa',
    BOOK_COVER: 'Capa',
    BOOK_COVER_DESC: 'Informações sobre a capa',
    BOOK_PAGES: 'Miolo',
    BOOK_PAGES_DESC: 'Informações sobre o miolo',
    MAX_PAGES: 'Número máximo de páginas',
    FIXED_DIMENSIONS: 'Dimensões fixas(mm)',
    BOOK_TYPE: 'Tipo do livro',

    COVER_PAPER_TYPE: 'Tipo do papel(capa)',
    COVER_FINISHING_TYPE: 'Tipo do acabamento(capa)',

    PAGES_PAPER_TYPE: 'Tipo do papel(miolo)',
    PAGES_FINISHING_TYPE: 'Tipo do acabamento(miolo)',

    PRINTED_FILES: 'Obra'
  },

  OBJECT_CATEGORY_MODAL: {
    REGISTER_TITLE: 'Cadastrar Categoria',
    UPDATE_TITLE: 'Atualizar Categoria',
    SUBTITLE: 'Informe os dados da Categoria que será vinculada ao Edital:',
    NAME: 'Nome',
    CODE: 'Código'
  },

  CATEGORY_DETAIL_MODAL: {
    REGISTER_TITLE: 'Inserir Detalhamento',
    UPDATE_TITLE: 'Editar Detalhamento',
    SUBTITLE:
      'Informe os dados do Detalhamento que será vinculada a Categoria:',
    NAME: 'Nome',
    COLLECTION_LIMIT: 'Limite da Coleção'
  },

  PUBLISHER: {
    TITLE: 'Lista de Participação em Editais',
    NONE_RESULTS: 'Nenhum edital para ser mostrado',
    MISSING_PUBLISHER_ID: "Parâmetro 'publisherId' não identificado",

    DILIGENCE_TITLE: 'Diligências',
    ACTIONS: 'Ações',
    CODE: 'Cód. coleção',
    COLLECTION: 'Coleção',
    EDITAL: 'Edital',
    DILIGENCE_NUMBER: 'Diligência',
    DILIGENCE_VOLUME_NUMBER: 'Volumes (diligência)',
    DUE_DATE: 'Prazo',
    FINISHED_COLLECTIONS: 'Coleções finalizadas',
    PUBLISHER: 'Empresa',
    REGISTERED_COLLECTIONS: 'Coleções inscritas',
    SITUATION: 'Situação',
    SITUATION_STATUS: 'Fases',
    STATUS: 'Status',
    VALIDITY: 'Vigência',

    NO_DILIGENCES: 'Nenhuma diligência encontrada',
    NOT_RATED: 'Não avaliado',
    NOT_VALIDATED: 'Não Validado',
    VALIDATED: 'Validado',
    DILIGENCE: 'Diligência',
    ADJUSTED: 'Corrigido',

    DILIGENCE_STATUS: 'Aguardando ajustes',
    ADJUSTED_STATUS: 'Enviado correções',
    NOT_VALIDATED_STATUS: 'Não Validado',
    NOT_RATED_STATUS: 'Não avaliado',
    VALIDATED_STATUS: 'Validado',
    EXPIRED_STATUS: 'Prazo vencido',

    NOT_FIXED_TITLE: 'Campos não corrigidos',
    NOT_FIXED_MESSAGE: 'Os campos abaixo ainda não foram verificados',
    NOT_FIXED_FIELD: 'Campo',
    NOT_FIXED_SITUATION: 'Situação',
    NOT_FIXED_SUCCESS: 'Registro incluído com sucesso',
    NOT_FIXED_DESCRIPTION: 'Não corrigido',
    VOLUME: 'Volume',

    GO_TO_POST_SUBSCRIPTION: 'Ir para pós inscrição',

    FILTER_NOTICES: 'Todos os editais',
    FILTER_PUBLISHERS: 'Todas as editoras',
    FILTER_PHASES: 'Todas as fases',

    APPEAL_REQUESTS: {
      TITLE: 'Resultado',
      EDITAL_OBJECT: 'Edital/Objeto',
      DUE_DATE: 'Vigência',
      PUBLISHER: 'Editora',
      COLLECTIONS: 'Coleções',
      REQUESTS: 'Solicitações',
      STEPS: 'Fases',
      YEAR: 'Ano de atendimento',
      REQUEST_ADJUSTEMENT: 'Solicitar correção',
      VIEW_ADJUSTEMENT: 'Vizualizar correção',
      EDIT_ADJUSTEMENT: 'Editar correção',
      VIWE_ADJUSTEMENT: 'Vizualizar correção',

      COLLECTION_TITLE: 'Título da coleção',
      COLLECTION_CODE: 'Código da coleção',
      COLLECTION_RESULT: 'Resultado',

      COLLECTION_STATUS: {
        TITLE: 'Situação',
        VALIDATED: 'Validado',
        NOT_VALIDATED: 'Não validado',
        NOT_RATED: 'Não iniciado'
      },

      APPEAL_STATUS: {
        TITLE: 'Recurso',
        NOT_ANALYZED: 'Não avaliado',
        NOT_SUBMITTED: 'Não enviado',
        SUBMITTED: 'Em análise',
        DEFERRED: 'Deferido',
        REJECTED: 'Indeferido',
        NO_APPEAL: 'Sem recurso'
      },

      ADJUSTMENT: {
        SITUATION: 'Situação',
        APPEAL: 'Recurso'
      },

      EVALUATION_REPORT: 'Parecer',
      EVALUATION_REPORT_DOWNLOAD: 'Download do parecer',
      REQUEST_ADJUSTMENT: 'Solicitar correção',
      DOWNLOAD: 'Download do recurso',
      VIEW_REQUEST: 'Visualizar a solicitação',

      ADD_APPEAL: 'Solicitar recurso',
      EDIT_APPEAL: 'Modificar recurso',
      VIEW_APPEAL: 'Visualizar o recurso',

      BTN_ADJUSMENT_SEND_VALIDATION: {
        SEND: 'Enviar documentos validados',
        VIEW: 'Vizualizar os documentos validados'
      },

      FILE_DOWNLOAD_APPEAL: 'Download do recurso',
      FILE_PREVIEW_APPEAL: 'Visualizar o PDF recurso'
    },

    PARTICIPATION_NOTICE_TABLE: {
      TITLE: 'Solicitar participação em objeto / edital',
      CONFIRM_PARTICIPATE: 'Confirmar a participação em objeto / edital?',
      EDITAL_OBJECT: 'Edital',
      VALIDITY: 'Vigência',
      PUBLISHER: 'Editora',
      SITUATION: 'Situação',
      OBJECT: 'Objeto',
      COMPANY_LINKED: 'Empresa a ser vinculada',
      RESPONSIBLE_REQUEST: 'Responsável pela solicitação',
      NON_PARTICIPANT: 'Não Participante',
      UNDER_REVIEW: 'Em análise'
    }
  },

  PUBLISHER_NOTICE: {
    TITLE: 'Inscrição do Edital',
    NONE_RESULTS: 'Nenhum registro encontrado',
    TITLE_COMPANY_PUBLISHER_INFO: 'Empresa',
    PUBLISHER_INFO_STATUS_TEXT: 'Informações da Empresa',
    PUBLISHER_INFO_ADDRESS_TEXT: 'Endereço da Empresa',
    PUBLISHER_COMMUNICATION_CHANNEL_INFO_STATUS_TEXT:
      'Canal de Comunicação para Acessibilidade',
    PUBLISHER_INFO_BANK_TEXT: 'Informações Bancárias',
    BUTTON_INSERT_COLLECTION: 'Inserir Coleção',
    BUTTON_DELETE_COLLECTION: 'Excluir',
    COLLECTION_INFO_LABEL: 'Coleção',
    COLLECTION_INFO_CODE: 'Código',
    AREA: 'Área',
    COMPONENT: 'Componente',
    COLLECTION_INFO_AREA: '{{area}}/{{component}}',
    COLLECTION_STATUS_INFO: 'Informações da coleção',
    COLLECTION_STATUS_DOC: 'Documentos da Coleção',
    COLLECTION_STATUS_VOLUME: 'Volumes',
    PUBLISHER_RULING_TITLE: 'Dirigentes',
    PUBLISHER_RULINGS_NUMBER: 'Dirigentes: ',
    PUBLISHER_RULING_REPRESENTATIVES_NUMBER: 'Representantes: '
  },

  PUBLISHER_REGISTER_FORM: {
    FORM_GROUP_TITLE_1: 'Informações da Empresa',
    FORM_LABEL_CNPJ: 'CNPJ',
    FORM_LABEL_COMPANYNAME: 'Nome da empresa',
    FORM_LABEL_TRADINGNAME: 'Nome fantasia',
    FORM_LABEL_COMPANYPHONE: 'Telefone',
    FORM_LABEL_COMPANYMOBILE: 'Celular',
    FORM_LABEL_COMPANYEMAIL: 'E-mail',

    FORM_GROUP_TITLE_2: 'Responsável',
    FORM_LABEL_RESPONSIBLE: 'Nome do Responsável',
    FORM_LABEL_RESPONSIBLEPHONE: 'Telefone',
    FORM_LABEL_RESPONSIBLEMOBILE: 'Celular',
    FORM_LABEL_RESPONSIBLEEMAIL: 'E-mail',
    FORM_LABEL_DECLARATION: 'Declaração da empresa',

    UPDATED_SUCCESS: 'Registro alterado com sucesso.'
  },

  PUBLISHER_BANK_FORM: {
    FORM_GROUP_TITLE_1: 'Informações Bancárias',
    FORM_LABEL_UF: 'UF',
    FORM_LABEL_COUNTY: 'Município',
    FORM_LABEL_BANK: 'Banco',
    FORM_LABEL_BRANCH: 'Agência',
    FORM_LABEL_ACCOUNT_TYPE: 'Tipo da Conta',
    FORM_LABEL_ACCOUNT: 'Conta',
    CREATED_SUCCESS: 'Registro inserido com sucesso.',
    UPDATED_SUCCESS: 'Registro alterado com sucesso.'
  },

  PUBLISHER_ADDRESS_FORM: {
    FORM_GROUP_TITLE_1: 'Endereço da Empresa',
    FORM_LABEL_CNPJ: 'CNPJ',
    FORM_LABEL_COMPANYNAME: 'Nome da empresa',
    FORM_LABEL_CEP: 'CEP',
    FORM_LABEL_ADDRESS: 'Endereço',
    FORM_LABEL_NEIGHBORHOOD: 'Bairro',
    FORM_LABEL_UF: 'UF',
    FORM_LABEL_COUNTY: 'Município',
    UPDATED_SUCCESS: 'Registro alterado com sucesso.',
    CEP_MUST_BE_AT_LEAST_8_CHARACTERS: 'O cep deve ter pelo menos 8 caracteres'
  },

  PUBLISHER_COMMUNICATION_CHANNEL_FORM: {
    FORM_GROUP_TITLE_1: 'Canal de Comunicação para Acessibilidade',
    FORM_LABEL_PHONE: 'Telefone',
    FORM_LABEL_EXTENSION_LINE: 'Ramal',
    FORM_LABEL_CELL_PHONE: 'Celular',
    FORM_LABEL_EMAIL: 'E-mail',
    FORM_LABEL_SITE: 'Site',
    FORM_LABEL_DDD: 'DDD',

    UPDATED_SUCCESS: 'Registro alterado com sucesso.'
  },

  PUBLISHER_SELECT_COLLECTION: {
    PAGE_TITLE: 'Seleção de Coleções do Edital',
    TITLE_COMPANY_PUBLISHER_INFO: 'Empresa',
    COLLECTION_PREFIX_SELECTED:
      'Total de coleções selecionadas pela editora {{totalSelected}}',
    COLLECTION_PREFIX_SELECTED_WITH_LIMIT:
      'Total de coleções selecionadas pela editora {{totalSelected}}/{{count}}',

    CATEGORY_PREFIX_SELECTED:
      'Total de coleções selecionadas pela editora {{categoryTotal}}',
    CATEGORY_PREFIX_SELECTED_WITH_LIMIT:
      'Total de coleções selecionadas pela editora {{categoryTotal}}/{{count}}',

    COLLECTION_NONE_RESULTS: 'Nenhuma categoria para ser mostrada',
    SELECTED_SUCCESS: 'Coleções inseridas com sucesso',
    NONE_RESULTS: 'Nenhuma coleção para ser mostrada',
    TABLE: {
      LIMIT: 'Limite',
      AREA: 'Área',
      COMPONENT: 'Componente',
      TOTAL: 'Total de Volume',
      ACTION: 'Ação',
      LABEL_ACTION: 'Visualizar'
    },
    MODAL: {
      TITLEAREA: 'Área',
      TITLECOMPONENT: 'Componente',
      TITLECODE: 'Código da Coleção',
      COLLECTIONVOLUME: 'Volumes da coleção',
      LISTVOLUME: 'Lista de volume',
      CODE: 'Código do volume',
      SERIES: 'Série',
      FORMAT: 'Formato',
      EXEMPLARY: 'Exemplar',
      RESOURCE: 'Recurso',
      VOLUME: 'Volume Obrigatorio'
    }
  },

  COLLECTION_VOLUME_DOCUMENTS: {
    FILE_HISTORY_TITLE: 'Histórico do arquivo',
    NAME: 'Nome',
    VERSION: 'Versão',
    FORMAT: 'Formato',

    FILE_NAME: 'Nome do arquivo',
    FILE_DATA: 'Dados do arquivo',
    REGISTRATION_DATE: 'Data de registro',
    RESPONSIBLE: 'Responsável',
    CHARACTERIZED: 'Caracterizado',
    UNCHARACTERIZED: 'Descaracterizado',

    FILE_SIZE: 'Tamanho (MB)',
    DURATION: 'Duração',
    PAGES: 'Páginas',
    DIGITAL: 'Digital',
    PRINTED: 'Impresso'
  },

  DILIGENCE_VOLUME_LIST: {
    PAGE_TITLE: 'Volumes em diligência',
    TITLE: 'Volumes em diligência',
    DILIGENCE_COLLECTION_DATA: 'Diligência na coleção',
    DILIGENCE_VOLUME_LIST: 'Lista de volumes para correção',
    ADJUSTMENT_OBSERVATIONS: 'Observações para ajuste',
    DILIGENCE_NUMBER: 'Diligência',
    DILIGENCE_DESCRIPTION: 'Descrição da diligência das obras',
    DILIGENCE_DOCUMENT: 'Documento complementar da diligência',
    IS_DOCUMENT_VISIBLE: 'Documento visível para a editora',
    ANALISYS_DATE: 'Data de análise',
    ANALYZED_BY: 'Analisado por',
    DUE_DATE: 'Prazo para correção',
    FIXED_DATE: 'Data de correção',
    FIXED_BY: 'Corrigido por',
    COMPLEMENTARY_DOCUMENT: 'Documento complementar da diligência',
    CODE: 'Código',
    VOLUME: 'Volume',
    GRADE: 'Série',
    FORMAT: 'Formato',
    COPY: 'Exemplar',
    RESOURCE: 'Recurso',
    SITUATION: 'Situação',
    ACTIONS: 'Ações',

    NO_VOLUMES: 'Nenhum volume encontrado',
    ADJUSTED: 'Enviado correções',
    NOT_RATED: 'Não iniciado',
    NOT_VALIDATED: 'Não validado',
    VALIDATED: 'Validado',
    DILIGENCE: 'Aguardando ajustes',
    QUIT: 'Desistência',
    SEND_ANALISYS: 'Enviar para análise'
  },

  DILIGENCE_VOLUME_LIST_ATTRIBUTE: {
    PAGE_TITLE: 'Volumes em diligência',
    TITLE: 'Volumes em diligência',

    DILIGENCE_NUMBER: 'Diligência',
    DILIGENCE_VOLUME_LIST: 'Lista de volumes',
    CODE: 'Código',
    VOLUME: 'Volume',
    GRADE: 'Série',
    FORMAT: 'Formato',
    COPY: 'Exemplar',
    RESOURCE: 'Recurso',
    SITUATION: 'Situação',
    ACTIONS: 'Ações',

    DOCUMENT_COMPLEMENTARY: 'Documento complementar',
    NOTE_ADJUSTMENT_DESCRIPTION: 'Observações para ajustes',
    DUE_DATE_DESCRIPTION: 'Prazo',
    REVIEWED_BY_DESCRIPTION: 'Analisado por',
    ADJUSTED_DATE_DESCRIPTION: 'Correção enviada em',
    ADJUSTED_BY_DESCRIPTION: 'Por',
    ADJUSTED_NOTE_DESCRIPTION: 'Observações enviadas pela editora',
    HAS_NOTES: 'Existem observações',

    NOTE_ANALYSTS_DESCRIPTION: 'Observações para os analistas',

    NO_VOLUMES: 'Nenhum volume encontrado',
    ADJUSTED: 'Enviado correções',
    NOT_RATED: 'Não iniciado',
    NOT_VALIDATED: 'Não validado',
    VALIDATED: 'Validado',
    DILIGENCE: 'Aguardando ajustes',
    QUIT: 'Desistência',
    SEND_ANALISYS: 'Enviar para análise',

    SUCCESS_SEND_ANALYST: 'A obra foi encaminhada para análise.',
    CONFIRM_SEND_ANALYST: 'Confirmar envio',
    ASK_CONFIRM_SEND_ANALYST:
      'Deseja confirmar o envio da coleção para análise?'
  },

  DILIGENCE_VOLUME_EDIT: {
    FORM_CORRECTION: 'Corrigir o formulário',
    TITLE_DIGITAL: 'Obra digital em diligência',
    TITLE_PRINTED: 'Obra impressa em diligência',
    ADJUSTED: 'Corrigidos',
    REVIEW_NOT_OK: 'Não corrigidos',
    DILIGENCE_COLLECTION_DATA: 'Diligência na coleção',
    DILIGENCE_NUMBER: 'Diligência',
    ANALISYS_DATE: 'Data de análise',
    DUE_DATE: 'Prazo para correção',
    INFO: {
      EDITAL: '{{collectionName}} - ({{objectName}})',
      PUBLISHER: '<strong>Empresa: {{name}} ({{cnpj}})</strong>',
      CATEGORY_DETAIL:
        '{{category.code}} - {{category.name}} ::{{detail.name}}',
      AREA_LABEL: 'Área',
      AREA: '{{label}}: {{code}} - {{name}}',

      COMPONENT_LABEL: 'Componente',
      COMPONENT: '{{label}}: {{code}} - {{name}}',

      COLLECTION_CODE: 'Código da Coleção: {{code}}',
      COLLECTION_NAME: 'Nome da Coleção: {{name}}',

      LAST_UPDATE: 'Última atualização: {{date}}',
      UPDATED_BY: 'Por: {{name}}'
    },
    VOLUME_DATA: {
      TITLE: 'Dados do Volume',
      DESCRIPTION: 'Descrição',
      NAME: 'Nome',
      VERSION: 'Versão',
      FILE_DATA: 'Dados',
      FILENAME: 'Nome arquivo',
      FIXED_PAGES: 'Páginas para corrigir',
      ACTIONS: 'Ações',
      PAGE_NUMBER: 'Nº da página',
      ADJUSTMENTS: 'Observações',
      SUCCESS: 'Registro incluído com sucesso.',
      NO_PAGES_TO_UPLOAD: 'Nenhuma página carregada!',
      SAVE_VOLUME: 'Gravar páginas corrigidas'
    },

    FIELD_LIST_FILES: {
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      SIZE: 'Tamanho',
      DIMENSION: 'Dimensão',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },

    COLLECTION_DATA: {
      TITLE: 'Dados da Coleção',
      DESCRIPTION: 'Descrição'
    },
    COLLECTION_DOCUMENTS: {
      TITLE: 'Documentos da coleção',
      DESCRIPTION: 'Descrição',
      TYPE: 'Nome',
      NAME: 'Nome Arquivo',
      SIZE: 'Tamanho',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },
    VOLUME_INFO: {
      TITLE: 'Informações do volume',
      VOLUME_CODE: '<strong>Código do Volume:</strong> {{value}}',
      GRADE: '<strong>Série:</strong> {{value}}',
      FORMAT: '<strong>Formato:</strong> {{value}}',
      COPY: '<strong>Exemplar:</strong> {{value}}',
      RESOURCE_CODE: '<strong>Código do Recurso:</strong> {{value}}',
      RESOURCE_NAME: '<strong>Nome do Recurso:</strong> {{value}}',
      NAME: '<strong>Nome do Volume:</strong> {{value}}',
      OPTIONAL: '<strong>Facultativo:</strong> {{isOptional}}',
      IS_OPTIONAL: {
        true: 'Sim',
        false: 'Não'
      }
    },
    VOLUME_BOOK_COVER: {
      TITLE: 'Especificações da capa',
      DESCRIPTION: 'Descrição',
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      SIZE: 'Tamanho',
      DIMENSION: 'Dimensão',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },
    VOLUME_AUTHORSHIP: {
      TITLE: 'Autorias vinculadas',
      DESCRIPTION: 'Descrição',
      NAME: 'Nome',
      CPF: 'CPF',
      PSEUDONYM: 'Pseudônimo',
      NATIONALITY: 'Nacionalidade'
    },
    VOLUME_FILES: {
      TITLE: 'Arquivos do volume',
      TYPE: 'Arquivo',
      NAME: 'Nome',
      VERSION: 'Versão',
      SIZE: 'Limite (MB)',
      PAGES: 'Páginas',
      DURATION: 'Duração',
      KEYWORD: 'Palavra-Chave',
      DATA: 'Dados',
      ANALYSIS: 'Análise',
      ACTIONS: 'Ações',
      FOUND_EXTENSIONS: 'Extensão dos arquivos da lista',
      OPEN_ANALYSIS: 'Abrir ficha de análise',
      FILE_SIZE: 'Tamanho',
      CREATED_DATE: 'Data de criação',
      MIN: 'Mínimo',
      MAX: 'Máximo',
      PAGE_LIMIT: 'Limite de páginas da coleção',
      TOTAL_REMAINING_PAGES: 'Total de páginas restantes',
      FILES_TITLE: 'Arquivos do Volume Solicitados na Fase de Inscrição'
    },
    ATTRIBUTE: {
      ANALYSIS_JUSTIFICATION: 'Justificativa da análise',
      VOLUME_OPTIONAL: 'Volume facultativo',
      ASK_PROCEED_VOLUME: 'Deseja seguir com este volume?',
      DESERTION_JUSTIFICATION: 'Justificativa da deserção',
      ASK_CONFIRM_ACTION: 'Deseja confirmar a ação?',
      CONFIRM_ACTION: 'Confirmar ação'
    }
  },

  COLLECTION_VOLUME_AUTHORSHIP: {
    TITLE: 'Autorias vinculadas',
    DESCRIPTION: 'Descrição',
    NAME: 'Nome',
    CPF: 'CPF',
    PSEUDONYM: 'Pseudônimo',
    NATIONALITY: 'Nacionalidade',
    TYPE: 'Tipo',
    CONFIRM_DELETE: 'Deseja excluir o registro ?',
    IMPORT_MODAL_TITLE: 'Importar autorias de outro volume',
    CODIGO: 'Código',
    COLECAO: 'Coleção',
    SELECT_VOLUME_DA_COLECAO: 'Volumes da coleção'
  },

  COLLECTION_VOLUME_LIST: {
    PAGE_TITLE: 'Controle dos Volumes da Coleção',
    CONFIRM_FINISH_VOLUME:
      'Deseja finalizar o volume <strong>{{volumeName}}</strong>?',
    SERIES: 'Série / Ano',
    FORMAT: 'Formato',
    EXEMPLARY: 'Exemplar',
    STATUS: 'Situação',
    ALL: 'Todos'
  },
  COLLECTION_VOLUME_DETAIL: {
    CONFIRM_FINISH_VOLUME: '$t(COLLECTION_VOLUME_LIST:CONFIRM_FINISH_VOLUME)',
    VOLUME_INFO_TITLE: 'Informações do Volume',
    UPLOAD_INFO_TITLE: 'Upload de Arquivos do Volume',
    AUTHORSHIP_INFO_TITLE: 'Autorias vinculadas',
    MATERIAL_SPECIFICATION_TITLE: 'Especificações do material',
    BOOK_CORE_SPECIFICATION_TITLE: 'Especificações do miolo do livro',
    BOOK_COVER_SPECIFICATION_TITLE: 'Especificações da capa do livro',
    VOLUME_CODE: 'Código do volume',
    VOLUME_TITLE: 'Título do volume',
    UPLOAD_RECOMMENDATIONS:
      'Recomendamos o envio de imagens menores que 5MB e uma conexão de 10MB. Formato JPG',
    CONFIRM_DELETE: 'Deseja excluir o registro ?',
    CONFIRM_CLEAR: 'Deseja limpar o registro ?',
    OPTIONAL_VOLUME_TITLE:
      'Atenção, o volume é facultativo. Deseja cadastrar a(s) obra(s) para este volume?'
  },
  COLLECTION_VOLUME_DATA: {
    PAGE_TITLE: 'Informações do Volume',
    NONE_RESULTS: 'Nenhum campo disponível para inscrição.',
    UPLOAD_SUBTITLE: 'Upload de Arquivo do Volume',
    FILE_EXTENSIONS: 'Extensão do arquivo para upload: {{extensions}}',
    PAGE_LIMIT: 'Limite de páginas da coleção',
    MIN: 'Mínimo',
    MAX: 'Máximo',
    TOTAL_REMAINING_PAGES: 'Total de páginas restante',
    TOTAL_EXCEEDING_PAGES: 'Total de páginas excedido em',
    PAGES: 'páginas'
  },
  COLLECTION_FORM: {
    PAGE_TITLE: 'Cadastro de Dados da Coleção',
    NONE_RESULTS: 'Nenhum campo disponível para inscrição.'
  },
  COLLECTION_DOCUMENTS: {
    PAGE_TITLE: 'Documentos da Coleção',
    NONE_RESULTS: 'Nenhum campo disponível para inscrição.'
  },
  PUBLISHER_REGISTRATION_REQUEST: {
    PAGE_TITLE: 'Solicitação de Cadastramento de Editoras',
    PAGE_SUBTITLE:
      'Informe os dados da Editora que deseja cadastrar ao edital:',
    LABELS: {
      NAME: 'Nome editora',
      CNPJ: 'CNPJ',
      CPF: 'CPF',
      TRADENAME: 'Razão Social',
      CELLPHONENUMBER: 'Celular',
      PHONEENUMBER: 'Telefone',
      EMAIL: 'E-mail',
      RESPONSIBLE: 'Responsável',
      RESPPHONENUMBER: 'Telefone',
      RESPCELLPHONE: 'Celular',
      RESPEMAIL: 'E-mail',
      OBJECTID: 'Vincular ao Edital/Objeto'
    },
    ACTIONS: {
      REQUEST: 'Solicitar',
      GOBACK: 'Voltar'
    }
  },
  PUBLISHER_REQUESTS: {
    TITLE: 'Solicitações',
    PAGE_TITLE: 'Solicitações de Editoras',
    MODAL: {
      TITLE: 'Solicitação de cadastro',
      SUBTITLES: {
        REQUESTER: 'Editora solicitante',
        RESPONSIBLE: 'Responsável da editora'
      },
      LABELS: {
        CNPJ: 'CNPJ',
        CPF: 'CPF',
        EMAIL: 'E-mail',
        EDITORA: 'Editora',
        TRADENAME: 'Nome fantasia',
        PHONENUMBER: 'Telefone',
        CELLPHONENUMBER: 'Celular',
        RESPONSIBLE: 'Responsável'
      }
    }
  },

  VOLUME_VALIDATION_STATUS: {
    YES: 'Sim',
    NO: 'Não',
    WITH_DILIGENCE: 'Com diligência'
  },
  VOLUME_SUBSCRIPTION: {
    TITLE: 'Visualizar a inscrição do volume',
    NEEDS_REVIEW: 'Indicar ajuste',

    INFO: {
      EDITAL: '{{collectionName}} - ({{objectName}})',
      PUBLISHER: '<strong>Empresa: {{name}} ({{cnpj}})</strong>',
      CATEGORY_DETAIL:
        '{{category.code}} - {{category.name}} ::{{detail.name}}',
      AREA_LABEL: 'Área',
      AREA: '{{label}}: {{code}} - {{name}}',

      COMPONENT_LABEL: 'Componente',
      COMPONENT: '{{label}}: {{code}} - {{name}}',

      COLLECTION_CODE: 'Código da Coleção: {{code}}',
      COLLECTION_NAME: 'Nome da Coleção: {{name}}',

      LAST_UPDATE: 'Última atualização: {{date}}',
      UPDATED_BY: 'Por: {{name}}'
    },

    COLLECTION_DATA: {
      TITLE: 'Dados da coleção',
      DESCRIPTION: 'Selecione os dados da coleção para indicar ajuste'
    },

    COLLECTION_DOCUMENTS: {
      TITLE: 'Documentos da coleção',
      DESCRIPTION: 'Selecione os documentos da coleção para indicar ajuste',
      TYPE: 'Nome',
      NAME: 'Nome Arquivo',
      SIZE: 'Tamanho',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },

    VOLUME_INFO: {
      TITLE: 'Informações do volume',
      VOLUME_CODE: '<strong>Código do Volume:</strong> {{value}}',
      GRADE: '<strong>Série:</strong> {{value}}',
      FORMAT: '<strong>Formato:</strong> {{value}}',
      COPY: '<strong>Exemplar:</strong> {{value}}',
      RESOURCE_CODE: '<strong>Código do Recurso:</strong> {{value}}',
      RESOURCE_NAME: '<strong>Nome do Recurso:</strong> {{value}}',
      NAME: '<strong>Nome do Volume:</strong> {{value}}',
      OPTIONAL: '<strong>Facultativo:</strong> {{isOptional}}',
      IS_OPTIONAL: {
        true: 'Sim',
        false: 'Não'
      }
    },

    VOLUME_FILE_INFO: {
      NAME: '<strong>Nome:</strong> {{value}}',
      FILE_NAME: '<strong>Nome do Arquivo:</strong> {{value}}',
      VERSION: '<strong>Versão:</strong> {{value}}',
      CHARACTERIZED: 'Caracterizado',
      UNCHARACTERIZED: 'Descaracterizado'
    },

    VOLUME_DATA: {
      TITLE: 'Dados do volume',
      DESCRIPTION: 'Selecione os dados do volume para indicar ajuste'
    },

    FIELD_LIST_FILES: {
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      SIZE: 'Tamanho',
      DIMENSION: 'Dimensão',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },

    VOLUME_BOOK_COVER: {
      TITLE: 'Especificações da capa',
      DESCRIPTION: 'Selecione as especificações da capa para indicar ajuste',
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      DIMENSION: 'Dimensão',
      SIZE: 'Tamanho',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },

    VOLUME_AUTHORSHIP: {
      TITLE: 'Autorias vinculadas',
      DESCRIPTION: 'Selecione as autorias para indicar ajuste',
      NAME: 'Nome',
      CPF: 'CPF',
      PSEUDONYM: 'Pseudônimo',
      NATIONALITY: 'Nacionalidade'
    },

    VOLUME_FILES: {
      TITLE: 'Arquivos do volume',
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      VERSION: 'Versão',
      SIZE: 'Limite (MB)',
      PAGES: 'Páginas',
      DURATION: 'Duração',
      KEYWORD: 'Palavra-Chave',
      DATA: 'Dados',
      ANALYSIS: 'Análise',
      ACTIONS: 'Ações',
      PAGE_NUMBER: 'Nº da página',
      ADJUSTMENTS: 'Observações',
      FOUND_EXTENSIONS: 'Extensão dos arquivos da lista',
      OPEN_ANALYSIS: 'Abrir ficha de análise',
      FILE_SIZE: 'Tamanho',
      CREATED_DATE: 'Data de criação',
      MIN: 'Mínimo',
      MAX: 'Máximo',
      PAGE_LIMIT: 'Limite de páginas da coleção',
      TOTAL_REMAINING_PAGES: 'Total de páginas restantes'
    },

    VOLUME_VALIDATION: {
      TITLE: 'Validação da obra',
      IS_VALID: 'A obra está validada?',
      FINISH: 'Concluir validação',
      ANALYSIS_JUSTIFICATION: 'Justificativa da análise'
    },
    SAVE: 'Gravar'
  },

  VOLUME_ATTRIBUTE: {
    TITLE: 'Visualizar a inscrição do volume',
    NEEDS_REVIEW: 'Indicar ajuste',

    INFO: {
      EDITAL: '{{collectionName}} - ({{objectName}})',
      PUBLISHER: '<strong>Empresa: {{name}} ({{cnpj}})</strong>',
      CATEGORY_DETAIL:
        '{{category.code}} - {{category.name}} ::{{detail.name}}',
      AREA_LABEL: 'Área',
      AREA: '{{label}}: {{code}} - {{name}}',

      COMPONENT_LABEL: 'Componente',
      COMPONENT: '{{label}}: {{code}} - {{name}}',

      COLLECTION_CODE: 'Código da Coleção: {{code}}',
      COLLECTION_NAME: 'Nome da Coleção: {{name}}',

      LAST_UPDATE: 'Última atualização: {{date}}',
      UPDATED_BY: 'Por: {{name}}'
    },

    COLLECTION_DATA: {
      TITLE: 'Dados da coleção',
      DESCRIPTION: 'Selecione os dados da coleção para indicar ajuste'
    },

    COLLECTION_DOCUMENTS: {
      TITLE: 'Documentos da coleção',
      DESCRIPTION: 'Selecione os documentos da coleção para indicar ajuste',
      TYPE: 'Nome',
      NAME: 'Nome Arquivo',
      SIZE: 'Tamanho',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },

    VOLUME_INFO: {
      TITLE: 'Informações do volume',
      VOLUME_CODE: '<strong>Código do Volume:</strong> {{value}}',
      GRADE: '<strong>Série:</strong> {{value}}',
      FORMAT: '<strong>Formato:</strong> {{value}}',
      COPY: '<strong>Exemplar:</strong> {{value}}',
      RESOURCE_CODE: '<strong>Código do Recurso:</strong> {{value}}',
      RESOURCE_NAME: '<strong>Nome do Recurso:</strong> {{value}}',
      NAME: '<strong>Nome do Volume:</strong> {{value}}',
      OPTIONAL: '<strong>Facultativo:</strong> {{isOptional}}',
      IS_OPTIONAL: {
        true: 'Sim',
        false: 'Não'
      }
    },

    VOLUME_FILE_INFO: {
      NAME: '<strong>Nome:</strong> {{value}}',
      FILE_NAME: '<strong>Nome do Arquivo:</strong> {{value}}',
      VERSION: '<strong>Versão:</strong> {{value}}',
      CHARACTERIZED: 'Caracterizado',
      UNCHARACTERIZED: 'Descaracterizado'
    },

    VOLUME_DATA: {
      TITLE: 'Dados do volume',
      DESCRIPTION: 'Selecione os dados do volume para indicar ajuste'
    },

    FIELD_LIST_FILES: {
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      SIZE: 'Tamanho',
      DIMENSION: 'Dimensão',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },

    VOLUME_BOOK_COVER: {
      TITLE: 'Especificações da capa',
      DESCRIPTION: 'Selecione as especificações da capa para indicar ajuste',
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      DIMENSION: 'Dimensão',
      SIZE: 'Tamanho',
      DATE: 'Data/Hora',
      ACTIONS: 'Ações'
    },

    VOLUME_AUTHORSHIP: {
      TITLE: 'Autorias vinculadas',
      DESCRIPTION: 'Selecione as autorias para indicar ajuste',
      NAME: 'Nome',
      CPF: 'CPF',
      PSEUDONYM: 'Pseudônimo',
      NATIONALITY: 'Nacionalidade'
    },

    VOLUME_FILES: {
      TITLE: 'Arquivos do volume',
      TYPE: 'Nome',
      NAME: 'Nome arquivo',
      VERSION: 'Versão',
      SIZE: 'Limite (MB)',
      PAGES: 'Páginas',
      DURATION: 'Duração',
      KEYWORD: 'Palavra-Chave',
      DATA: 'Dados',
      ANALYSIS: 'Análise',
      ACTIONS: 'Ações',
      PAGE_NUMBER: 'Nº da página',
      ADJUSTMENTS: 'Observações',
      FOUND_EXTENSIONS: 'Extensão dos arquivos da lista',
      OPEN_ANALYSIS: 'Abrir ficha de análise',
      FILE_SIZE: 'Tamanho',
      CREATED_DATE: 'Data de criação',
      MIN: 'Mínimo',
      MAX: 'Máximo',
      PAGE_LIMIT: 'Limite de páginas da coleção',
      TOTAL_REMAINING_PAGES: 'Total de páginas restantes'
    },

    VOLUME_VALIDATION: {
      TITLE: 'Validação da obra',
      IS_VALID: 'A obra está validada?',
      FINISH: 'Concluir validação',
      ANALYSIS_JUSTIFICATION: 'Justificativa da análise'
    },
    SAVE: 'Gravar'
  },

  CONSULTANT: {
    TITLE_EDITAL: 'Informações do edital',
    SEARCH_EDITAL: 'Pesquise pelo edital ou objeto',
    OBJECT: 'Objeto',
    NONE_RESULTS_EDITAL: 'Nenhuma edtial encontrada',
    EDITAL: 'Edital',
    VALIDITY: 'Vigência',
    ACTIONS: 'Ações',
    TITLE_PUBLISHER: 'Informações das editoras',
    SEARCH_PUBLISHER: 'Pesquise pela editora ou CNPJ',
    NONE_RESULTS_PUBLISHER: 'Nenhuma editora encontrada',
    PUBLISHER: 'Editora',
    CNPJ: 'CNPJ',
    STATUS: 'Fases'
  },

  OBJECT_SUBTITLE: {
    SUBTITLE_DISCRIPTION: 'Informações da legenda'
  },

  PASS_REGISTRATION: {
    PASS_REGISTRATION_DISCRIPTION:
      'Apresentar campos na Pós-inscrição - Coleção',
    ALERT_DIALOG:
      'Atenção, as coleções que foram parametrizadas serão afetadas com a retirada destes campos. Deseja continuar a alteração?'
  },
  POST_SUBSCRIPTION_LIST: {
    TITLE: 'Pós inscrição',
    PAGE_TITLE: 'Lista de pós-inscrição',
    COLLECTION_TITLE: 'Coleção',
    COLLECTION_CODE: 'Código da coleção',
    COMPANY: 'Empresa',
    IS_FINISHED: 'Situação',
    EDIT_POST_SUBSCRIPTION_TITLE: 'Editar Pós-inscrição',
    POST_SUBSCRIPTION_TOOLTIP: 'Dados da pós-inscrição',
    NONE_RESULTS: 'Nenhum registro encontrado',
    FILLED_BY: 'Preenchido por ',
    NOT_FILLED: 'Não preenchido',
    COLLECTION_TOTAL: 'Total de coleções',
    POST_SUBSCRIPTION_TITLE: 'Dados da pós-inscrição'
  },

  PAGINATION: {
    LABEL_ROWS_PER_PAGE: 'Exibir',
    NEXT_ICON_BUTTON_TEXT: 'Próxima página',
    BACK_ICON_BUTTON_TEXT: 'Página anterior',
    LABEL_PAGES: 'páginas',
    LABEL_FROM: 'de',
    LABEL_SELECTED: 'Selecionado(s) {{value}}'
  },

  MODAL_UPLOAD_HISTORY: {
    FILE_HISTORY_TITLE: 'Histórico do arquivo',
    NAME: 'Nome',
    VERSION: 'Versão',
    FORMAT: 'Formato',

    FILE_NAME: 'Nome do arquivo',
    FILE_DATA: 'Dados do arquivo',
    REGISTRATION_DATE: 'Data de registro',
    RESPONSIBLE: 'Responsável',
    CHARACTERIZED: 'Caracterizado',
    UNCHARACTERIZED: 'Descaracterizado',

    FILE_SIZE: 'Tamanho (MB)',
    DURATION: 'Duração',
    PAGES: 'Páginas',
    DIGITAL: 'Digital',
    PRINTED: 'Impresso'
  },

  CHANGE_DATE_DILIGENCE: {
    TITLE: 'Prazo da diligência',
    LABEL_DATE_START: 'Data final do prazo',
    LABEL_DATE_END: 'Data inicial do prazo',
    COLLECTION: 'Coleção: ',
    CODE: 'Coleção:',
    ALERT_DATE: 'Prazo de'
  },

  COLLECTION_REVIEW_STATUS: {
    NOT_RATED: 'Não Avaliada',
    NOT_VALIDATED: 'Não validado',
    SENT_FOR_ANALISYS: 'Enviado para Análise',
    APPROVED: 'Aprovado',
    REJECTED: 'Reprovado',
    FINISHED: 'Finalizado',
    ADJUSTED: 'Corrigida',
    VALIDATED: 'Validado'
  },

  DILIGENCE_REVIEW_STATUS_TYPE: {
    NOT_STARTED: 'Não iniciado',
    IN_ANALYSIS: 'Em análise',
    ANALYZED: 'Analisado',
    IN_VALIDATION: 'Em validação',
    VALIDATED: 'Validado',
    NOT_VALIDATED: 'Não validada',
    DILIGENCE: 'Em diligência',
    ADJUSTED: 'Corrigida',
    OUT_OF_TIME: 'Fora do prazo',
    NOT_RATED: 'Não Avaliada'
  },

  ROLES: {
    ROLE_ADMIN: 'FNDE',
    ROLE_PUBLISHER: 'Responsável da editora',
    ROLE_IPT: 'Validador',
    ROLE_CONSULTANT: 'Consultor',
    ROLE_COORDINATOR: 'Coordenador triagem',
    ALL_ROLES: 'Todos os perfis',
    ROLE_DIRECTOR: 'Diretor',
    ROLE_TEACHER: 'Professor',
    ROLE_STUDENT: 'Aluno',
    ROLE_GUARDIAN: 'Mãe, Pai ou Responsável Legal'
  },

  STATUS: {},

  APPEAL_GENERAL_INFO: {
    EDITAL_OBJECT: 'Edital/Objeto ',
    TITLE: 'Nome da coleção ',
    CODE: 'Código da coleção',
    RESOURCE_PHASE: 'Fase do recurso: ',
    FINAL_OPINION: ' Parecer final',
    COLLECTION_APPEAL: 'Situação da obra: ',
    QUALIFICATION_APPEAL: 'Situação da habilitação: ',
    YEARS_OF_SERVICE: 'Ano de atendimento',
    OBJECT_CALL_TITLE: 'Título da Convocação',
    INFO_MESSAGE:
      '<strong>Informação.</strong> Prazo da solicitação de {{startDate}} - {{endDate}}',

    PUBLISHER_NAME: 'Editora'
  },

  APPEAL_PHASE_ORIGIN_TYPE: {
    ALL: 'Todos',
    SUBSCRIPTION_VALIDATION: 'Validação da inscrição',
    APPEAL_VALIDATION: 'Recurso da validação',
    QUALIFICATION: 'Habilitação',
    PEDAGOGICAL_ANALYSIS: 'Avaliação pedagógica',
    PHYSICAL_ATTRIBUTES: 'Atributos físicos',
    QUALIFICATION_COMPANY: 'Habilitação Empresa',
    QUALIFICATION_LITERARY_WORK: 'Habilitação Obra'
  },

  SUBSCRIPTION_APPROVAL_TYPE: {
    NOT_RATED: 'Não avaliada',
    NOT_VALIDATED: 'Não validada',
    VALIDATED: 'Validada',
    DILIGENCE: 'Com diligência',
    ADJUSTED: 'Ajustada'
  },

  REQUEST_APPEAL_FORM: {
    TITLE_REQUEST: {
      COLLECTION: 'Solicitar Recurso da Obra',
      QUALIFICATION: 'Solicitar Recurso da Habilitação'
    },
    TITLE_VIEW: 'Visualizar Solicitação de Recurso',
    NOTE: 'Justificativa do recurso',
    FILES_TO_UPLOAD: 'Arquivo(s) da solicitação do recurso',
    DESCRIPTION: 'Descrição do arquivo',
    FILE: 'Arquivo',
    DLG_SEND_APPEAL_TITLE: 'Enviar a solicitação de recurso',
    DLG_SEND_APPEAL_MSG: 'Deseja enviar a solicitação de recurso para análise?',
    INFORM_AT_LEAST_ONE_ATTACHMENT: 'informe pelo menos um anexo',
    RESOURCE_STATUS: 'Situação do recurso: ',
    RESOURCE_DATE: 'Data do recurso: ',
    RESOURCE_BY: 'Por: ',
    RESOURCE_PHASE: 'Fase do recurso: '
  },

  ADDRESS: {
    UF: 'UF',
    COUNTY: 'Município',
    IBGE_CODE: 'Código IBGE'
  },

  APPEAL_HISTORIES: {
    TITLE: 'Histórico das solicitações',
    DATA: 'Data',
    STAGE: 'Etapa',
    RESULT: 'Resultado',
    DOCUMENT: 'Anexos'
  },

  APPEAL_ANALYSIS: {
    FILES_TO_UPLOAD: {
      TITLE: 'Arquivos da solicitação',

      DESCRIPTION: 'Descrição do arquivo',
      FILE: 'Arquivo'
    },

    ANALYSIS_FORM: {
      TITLE: 'Análise de recurso',
      NOTE: 'Observações da análise do recurso',
      DEFERRED: 'Deferido',
      REJECTED: 'Indeferido',
      VALIDATE_APPEAL_QUESTION: 'Validar o recurso?',
      ATTACHED_FILE: 'Anexo',
      ATTACHED_FILE_VIEW: 'Anexo da validação do recurso',
      APPEAL_DATE: 'Data da análise do recurso'
    },

    APPEAL_INFO: {
      TITLE: 'Recurso solicitado pela editora',
      APPEAL_PHASE: 'Fase do recurso',
      APPEAL_STATUS: 'Situação do recurso',
      APPEAL_DATE: 'Data do recurso',
      APPEAL_NOTES: 'Justificativa do recurso',
      BY: 'Por'
    }
  },

  FORM_RULING_SUBSCRIPTION: {
    NUMBER_SUBSCRIBERS: 'Quantidades que assinam',
    SUBSCRIPTION_CONDITION: 'Assinam o contrato'
  },

  PUBLISHER_RULING: {
    TITLE_PAGE: 'Dirigentes',
    NAME: 'Nome',
    ROLE: 'Cargo',
    PRIORITY: 'Prioridade dos dirigentes',
    DLG_TITLE: 'Remover o Dirigente',
    DLG_MSG: 'Deseja excluir o registro?'
  },

  MODAL_RULING: {
    TITLE: 'Inserir/Edital Dirigentes',
    INFO_GRRAL: 'Informações gerais',
    NAME: 'Nome completo',
    UF: 'Orgão emissor',
    CELLPHONE: 'Celular',
    PRIORITY: 'Prioridade',
    ISSUINGBODY: 'Orgão emissor',
    REPRESENTED_BY_ATTORNEY: 'Representado por procuração',
    ROLE: 'Cargo',
    POWER_ATTORNEY: 'Procuração por representante'
  },

  SUBSCRIPTION_CONDITION_TYPE: {
    TOGETHER: 'Conjuntamente',
    SINGLY: 'Isoladamente'
  },

  QUALIFICATION_YEARS: {
    YEARS: 'Anos de atendimento',
    LICENSE_TYPE: 'Tipo de Habilitação',
    FORM: 'Formulário',
    BTN_ADD_FORM: 'Adicionar Formulário',
    DLG_TITEL_DELL_QUALIFICATION_YEARS: 'Excluir o ano de atendimento',
    DLG_MSG_DELL_QUALIFICATION_YEARS:
      'Ano de Atendimento possui parametrização gravada, confirma exclusão?',
    SUBROW_TITLE: 'Nome do formulário',
    DLG_TITLE_BINDED_FORM: 'Confirmar exclusão do formulário',
    FILTER_YEAR: 'Ano de atendimento',
    FILTER_TYPE: 'Tipo de Habilitação'
  },

  MODAL_QUALIFICATION_SETTINGS: {
    TITLE: 'Parametrização da habilitação',
    YEAR: 'Ano de atendimento',
    TYPE_QUALIFICATION: 'Tipo de Habilitação',
    ENABLEMENT_MODE: ' Modo de Habilitação',
    OPENING_DATE: 'Data de Abertura',
    CLOSE_DATE: 'Data de Fechamento',
    DOCUMENTS_RECEIPT_OPENING_DATE:
      'Data de Abertura para Recebimento de Documentos',
    DOCUMENTS_RECEIPT_CLOSING_DATE:
      'Data de Fechamento para Recebimento de Documentos',
    SITUATION_LIST: 'Exibir na Habilitação Obras na Situação',
    DATA_MUST_BE_GREATER_THAN:
      'A Data selecionada tem que ser maior que a inicial'
  },
  MODAL_BIND_FORM: {
    PAGE_TITLE: 'Pesquisar Formulário',
    FILTER_PLACEHOLDER: 'Nome do formulário',
    NAME: 'Nome do formulário',
    PHASE: 'Fase'
  },
  PARAMETERIZATION: {
    REQUIRED: 'O campo é obrigatório',
    INVALID_NAME: 'Já existe um arquivo com o nome informado.',
    FORMAT: 'O formato do arquivo selecionado é inválido.',
    FILE_LARGE: 'O aquivo é muito grande',
    STEPS: 'Diligências',
    EDITORIAL_STRUCTURE: 'Estrutura Editorial',
    COLLECTIONS: 'Coleções',
    CONFIRM_DELETE:
      'Tem certeza que deseja excluir esse volume? Essa ação é permanente.',
    PARAMETRIZATION_VOLUME_FILE: 'Parametrizar arquivos do Volume',
    DELETE_VOLUME: 'Excluir o Volume',
    EDITE_VOLUME: 'Editar o Volume',
    COLLECTION_CODE: 'Código da coleção',
    CATEGORY_AND_DETAILS: 'Categorias e níveis',
    CATEGORY: 'Categoria',
    CATEGORY_DETAIL: 'Detalhamento (nível)',
    AREA: 'Área',
    STATUS: 'Status',
    AREA_QUANTITY: 'Quantidade por área',
    AREA_LABEL: 'Label da área para editora',
    COMPONENT: 'Componente',
    COMPONENT_LABEL: 'Label do componente para editora',

    BOOKS: 'Número de páginas',
    BOOKS_LABEL: 'Exemplar',
    PAGES: 'Páginas (Mínimo - Máximo)',
    MIN_PLACEHOLDER: 'Min.',
    MAX_PLACEHOLDER: 'Max.',

    DESCRIPTION: 'Descrição',
    THEME: 'Temas',
    GENRE: 'Gêneros literários',
    ENABLE_MAJOR_GENRE: 'Habilitar gênero majoritário',
    OPTIONAL_FIELD: 'Apresentar campos',
    LANGUAGE: 'Idiomas',
    RELATIONSHIP: 'Relacionamento',
    ACTIONS: 'Ações',
    COLLECTION: 'Coleção',
    VOLUME: 'Volumes',
    BOOKS_HELPER_TEXT:
      'Caso o número máximo de páginas estiver definido por volume, deixe essa informação vazia.',
    CREATE_TITLE: 'Adicionar Volume',
    EDIT_TITLE: 'Editar Volume',
    VOLUME_CODE: 'Código do volume',
    GENERAL_INFO: 'Informações Gerais',
    FORMAT_VOLUME: 'Formato do volume',
    EXEMPLE: 'Exemplar',
    RESOURCETYPE: 'Tipo de recurso',
    SERIES: 'Série',
    OPTIONAL_VOLUME: 'Volume facultativo',
    DIGITAL: 'Obra digital',
    EXTENSION: 'Extensão do arquivo para upload',

    FILES_TO_UPLOAD: 'Arquivo(s) para upload',
    NAME: 'Nome',
    SIZE: 'Tamanho (MB)',
    DURATION: 'Duração (minutos)',
    VERSION: 'Versão',
    MIN: 'Min.',
    MAX: 'Max.',
    SHOW_KEYWORD: 'Exibir palavra chave',
    TOTAL_FILES: 'Total de arquivos no volume:',
    ADD: 'Adicionar',

    FIELDS: 'Apresentar campos',
    AUTHOR: 'Autoria',

    DIMENSIONS: 'Dimensões',
    DIMENSIONS_LABEL: 'Tipo de dimensões',
    FREE_HINT:
      'Preenchimento livre pela editora os campos de largura e altura na inscrição da obra.',
    FREE: 'Livre',
    FIXED: 'Fixa',
    BOOK_COVER: 'Capa',
    BOOK_COVER_DESC: 'Informações sobre a capa',
    BOOK_PAGES: 'Miolo',
    BOOK_PAGES_DESC: 'Informações sobre o miolo',
    MAX_PAGES: 'Número máximo de páginas',
    FIXED_DIMENSIONS: 'Dimensões fixas(mm)',
    BOOK_TYPE: 'Tipo do livro',

    COVER_PAPER_TYPE: 'Tipo do papel(capa)',
    COVER_FINISHING_TYPE: 'Tipo do acabamento(capa)',

    PAGES_PAPER_TYPE: 'Tipo do papel(miolo)',
    PAGES_FINISHING_TYPE: 'Tipo do acabamento(miolo)',

    PRINTED_FILES: 'Obra'
  },

  LIST_APPEALS: {
    LINK_TITLE: 'Recursos',
    TITLE: 'Lista de Recursos',
    PUBLISHER: 'Editora',
    COLLECTION: 'Coleção',
    EDITAL_OBJECT: 'Edital/Objeto',
    DATE: 'Data da solicitação',
    STATUS: 'Situação',
    ANALYZE: 'Analisar recurso',
    VIEW: 'Visualizar recurso',
    PLACEHOLDER_SEARCH: 'Pesquise pela Editora e Coleção',
    YEAR_TYPE: 'Ano de atendimento'
  },

  TYPE_QUALIFICATION_OPTIONS: {
    ALL: 'Todos',
    FULL_DISTRIBUTION: 'Distribuição Integral',
    FIRST_REPOSITION: '1ª Reposição',
    SECOND_REPOSITION: '2ª Reposição',
    THIRD_REPOSITION: '3ª Reposição',
    FORTH_REPOSITION: '4ª Reposição',
    FIFTH_REPOSITION: '5ª Reposição',
    PURCHASE_WITH_ADDENDUM: 'Compra com termo aditivo',
    RESIDUAL_PURCHASE: 'Compra Residual'
  },

  TYPE_QUALIFICATION_MODE: {
    REGULAR: 'Regular',
    SIMPLIFIED: 'Simplificado'
  },

  TYPE_QUALIFICATION_SITUATION: {
    APPROVED: 'Aprovadas',
    APPROVED_WITH_FAILURES:
      'Aprovadas condicionadas à correção de falhas pontuais',
    REJECTED: 'Reprovadas',
    VALIDATED_IN_SUBSCRIPTION: 'Validadas na Inscrição',
    QUALIFIED: 'Habilitadas '
  },

  CALL_SAVE_STEPPER: {
    STEP_DATA_NASIC: 'Dados básicos',
    STEP_PUBLISHER: 'Editora',
    STEP_CALL: 'Convocação',
    CALL_SEND: 'Enviar'
  },

  CALL_BASIC_DATA: {
    TYPE_CALL: 'Selecione o tipo de convocação',
    YEAR_CALL: 'Ano de atendimento',
    TITLE_CALL: 'Titulo da convocação'
  },

  CALL_PUBLISHER: {
    ROW_CNPJ: 'CNPJ',
    ROW_FANTASY_NAME: 'Nome fantasia',
    ROW_NAME: 'Razão social',
    SELECT_AT_LEAST_ONE_PUBLISHER:
      'Para continuar é necessário incluir pelo menos uma editora.'
  },

  CALL_DETAILS: {
    TITLE: 'Visualização Convocação',
    VIEW_CALL: 'Convocação',
    ANSWER_CALL: 'Responder convocação',
    INFO_ANSWER: 'Informações da Resposta de Convocação',
    INFO_ANSWER_DOC_COMPANY: 'Informações e Documentos da Empresa',
    INFO_ANSWER_DOC_COLLECTIONS: 'Informações e Documentos das Coleções',
    TYPE_CALL: 'Tipo da convocação',
    QUALIFICATION_YEAR: 'Ano de atendimento',
    DATE: 'Data da convocação',
    DATE_LIMIT: 'Data limite de resposta',
    TITLE_CALL: 'Título da convocação',
    DESCRIPTION: 'Convocação',
    STATUS: 'Situação',
    DATE_ANSWER: 'Data de resposta',
    ANSWER: 'Resposta da convocação',
    COLLECTION: 'Coleção',
    COLLECTION_CODE: 'Código da Coleção',
    CNPJ: 'CNPJ',
    COMPANY_NAME: 'Nome da empresa',
    DOCUMENTS: 'Links anexos'
  },

  MODAL_LOOK_FOR_PUBLISHERS: {
    PAGE_TITLE: 'Buscar empresas',
    LOOK_FOR_PUBLISHERS: 'Buscar editoras',
    ROW_CNPJ: 'CNPJ',
    ROW_FANTASY_NAME: 'Nome fantasia',
    ROW_NAME: 'Razão social',
    FILTER_TITLE: 'Pesquisa a empresa para vincular ao objeto',
    FILTER_PLACEHOLDER: 'Empresas'
  },

  CALL_BODY: {
    TITLE: 'Texto da Convocação',
    ATTACHMENTS_NOTICES:
      'Selecionar arquivos do Edital/Objeto para enviar o link de acesso ao arquivo na convocação:'
  },

  CALL_MODAL_IMPORT: {
    TITLE: 'Importar convocação',
    ROW_EDITAL: 'Edital',
    ROW_OBJETO: 'Objeto',
    ROW_CONVOCACAO: 'Convocação',
    ROW_DATA: 'Data',
    FILTER_EDTIAL: 'Edital',
    FILTER_OBJECT: 'Objeto',
    FILTER_TYPE_CALL: 'Tipo de convocação'
  },

  CALL_LIST: {
    FILTER_LBL_SITUATION: 'Situação',
    BTN_NOVO: 'Cadastrar convocação',
    ROW_CALL_TITLE: 'Titulo da convocação',
    ROW_CALL_TYPE: 'Tipo',
    ROW_CALL_CREATE: 'Data de criação',
    ROW_CALL_YEAR: 'Ano de atendimento',
    ROW_CALL_SEND: 'Data de envio',
    ROW_CALL_SITUATION: 'Situação',
    DLG_TITLE_SEND: 'Enviar a convocação',
    DLG_TITLE_SEND_BODY: 'Deseja enviar a convocação',
    DLG_TITLE_COPY: 'Copiar a convocação',
    DLG_TITLE_COPY_BODY: 'Deseja copiar a convocação'
  },

  EDITAL_OBJECT_CALL_TYPE: {
    QUALIFICATION: 'Habilitação',
    PHISICAL_ATTRIBUTES_ANALYSIS: 'Análise de Atributos Físicos'
  },

  EDITAL_OBJECT_CALL_STATUS_TYPE: {
    REGISTERED: 'Cadastrado',
    SENT: 'Enviado',
    ALL: 'Todos'
  },

  CALL_TYPE_OPTIONS: {
    QUALIFICATION: 'Habilitação',
    PHISICAL_ATTRIBUTES_ANALYSIS: 'Análise de atributos físicos',
    NEGOTIATION: 'Negociação',
    OTHER: 'Outros'
  },

  CALL_STATUS_OPTIONS: {
    RECEIVED: 'Recebido',
    DRAFT: 'Em elaboração',
    SENT: 'Enviado'
  },

  CALL_PUBLISHER_DOCUMENTS: {
    TITLE: 'Documentos',
    DESCRIPTION: 'Descrição',
    HAS_SIGNATURE:
      'Possui apenas uma assinatura digital provida pela ICP-BRASIL?',
    COMPANY_NAME: 'Nome da empresa',
    COMPANY: 'Empresa',
    ANALYSIS_RESULT:
      'Resultado da verificação de conformidade de assinatura digital ICP-Brasil',
    APPROVED: 'Aprovado',
    DISAPPROVED: 'Desaprovado',
    COLLECTION_NAME: 'Nome da coleção',
    COLLECTION_CODE: 'Código da coleção'
  },

  ASSIGN_CALL_ENABLER: {
    TITLE: 'Atribuir Habilitador',
    TITLE_CHOOSE_ENABLER: 'Escolher habilitador',

    COMPANY: 'Empresa',
    CALL_TITLE: 'Titulo da convocação',
    COLLECTION_VOLUMES: 'Coleção (volumes)',
    YEARS: 'Ano atendimento',
    ASSIGNED_ENABLER: 'Habilitador responsável',
    ANSWERED_CALL: 'Respondeu convocação',

    PLACEHOLDER_SEARCH: 'Insira CNPJ, Razão social ou Nome fantasia',
    EDITAL_OBJECT: 'Objeto / Edital',
    CALL_ENABLER: 'Habilitador',
    YEAR: 'Ano de atendimento',

    CURRENT_ENABLER: 'Habilitador atual',
    NEW_ENABLER: 'Novo habilitador',

    TITLE_HISTORY: 'Histórico de Habilitador',
    START_DATE: 'Data de início',
    END_DATE: 'Data final',
    SENT: 'Enviado',
    ALL: 'Todos',
    ASSIGN: 'Atribuir'
  },

  CALL_VIEW: {
    TITLE: 'Convocação',
    BASIC_DATA: 'Dados básicos',
    CALLING_TYPE: 'Tipo de convocação',
    YEAR: 'Ano da convocação',
    CALLING_TITLE: 'Título da convocação',
    PUBLISHERS: 'Editoras',
    FANTASY: 'Nome fantasia',
    COMPANY: 'Razão Social',
    CALLING: 'Convocação'
  },

  PEDAGOGICAL_FILE_UPLOAD: {
    FILE_UNCHARACTERIZED: 'Documento do recurso Descaracterizado',
    FILE_CHARACTERIZED: 'Documento do recurso Caracterizado'
  },

  PEDAGOGICAL_ADJUSMENT_REQUEST: {
    TITLE_TABLE: 'Upload dos volumes aprovados (descaracterizados)',
    TITLE_DOCUMENTS: 'Upload de documentos',
    ROW_TITLE: 'Título',
    ROW_CODE: 'Código do volume',
    ROW_EXEMPLE: 'Exemplar',
    ROW_FORMAT: 'Formato',
    MODAL: {
      TITLE: 'Arquivos de volumes descaracterizados',
      TITLE_VOLUMS: 'Volume:',
      TITLE_CODE: 'Código do volume:',
      ROW_NAME: 'Name',
      ROW_FILETYPE: 'Versão',
      ROW_BYUSER: 'Responsavél',
      ROW_REGISTER_DATE: 'Data do registro',
      ROW_OLD_FILE: 'Arquivo atual',
      ROW_NEW_FILE: 'Correção'
    },
    DOCUMENTS: {
      PUNCTUAL_FAILURE: 'Ficha de falhas pontuais preenchida',
      CORRECTION_STATEMENT_NO_SIGNATURES:
        'Declaração de correção Sem assinaturas (Descaracterizado)',
      CORRECTION_STATEMENT_WITH_SIGNATURES:
        'Declaração de correção Com assinaturas (Caracterizado)'
    },

    DLG_SEND: {
      TITLE: 'Enviar correção',
      BODY: 'Deseja enviar a correção?'
    }
  },

  SUBSCRIPTION_COLLECTION_PEDAGOGICAL_ANALYSIS_STATUS_TYPE: {
    NOT_RATED: 'Não iniciado',
    SENT_TO_ANALYSIS: 'Aguardando análise da avaliação pedagógica',
    APPROVED_PRELIMINARY: 'Aprovação preliminar',
    APPROVED: 'Aprovada',
    APPROVED_WITH_FAILURES: 'Aprovado FP',
    APPROVED_AFTER_CORRECTIONS: 'Aprovado após correções',
    REJECTED_AFTER_CORRECTIONS: 'Rejeitado após correções',
    REJECTED: 'Reprovada',
    FINISHED_BY_PUBLISHER: 'Finalizado pela editora',
    EXPIRED_APPEAL_PERIOD: 'Recurso fora do prazo',
    EXPIRED_ADJUSTMENT_PERIOD: 'Correção fora do prazo',
    EXPIRED_CHARACTERIZED_VOLUMES_DELIVERY_PERIOD:
      'Volumes aprovada fora do prazo'
  },

  PEDAGOGICAL_ADJUSMENT_SEND_VALIDATION: {
    TITLE_TABLE: 'Upload dos volumes aprovados (caracterizados)',
    ROW_TITLE: 'Título',
    ROW_CODE: 'Código do volume',
    ROW_EXEMPLE: 'Exemplar',
    ROW_FORMAT: 'Formato',
    BTN_FINISH: 'Finalizar',
    MODAL: {
      TITLE: 'Arquivos de volumes caracterizados',
      TITLE_VOLUMS: 'Volume:',
      TITLE_CODE: 'Código do volume:',
      TITLE_DOCUMENTS: 'Upload dos arquivos aprovados',
      ROW_NAME: 'Name',
      ROW_FILETYPE: 'Versão',
      ROW_BYUSER: 'Responsavél',
      ROW_REGISTER_DATE: 'Data do registro',
      ROW_OLD_FILE: 'Arquivo atual',
      ROW_NEW_FILE: 'Correção'
    },
    DOCUMENTS: {
      PUNCTUAL_FAILURE: 'Ficha de falhas pontuais preenchida',
      CORRECTION_STATEMENT_NO_SIGNATURES:
        'Declaração de correção Sem assinaturas (Caracterizado)',
      CORRECTION_STATEMENT_WITH_SIGNATURES:
        'Declaração de correção Com assinaturas (Caracterizado)'
    },

    DLG_SEND: {
      TITLE: 'Finalizar',
      BODY: 'Ao finalizar o processo, não será permitido desfazer a ação. Deseja continuar?'
    }
  },

  PEDAGOGICAL_ANALYSIS_HISTORY_MODAL: {
    TITLE: 'Histórico das solicitações',

    STEP: 'Etapa',
    STATUS: 'Resultado',
    DATE: 'Data',
    ATTACHED: 'Anexos',

    STATUS_OPTIONS: {
      NOT_RATED: 'Não iniciado',
      APPROVED_PRELIMINARY: 'Aprovação preliminar',
      SENT_TO_ANALYSIS: 'Aguardando análise da avaliação pedagógica',
      APPROVED: 'Aprovado',
      APPROVED_WITH_FAILURES:
        'Aprovado condicionado a correção de falhas pontuais após recurso',
      APPROVED_AFTER_CORRECTIONS: 'Aprovado após correções',
      REJECTED: 'Reprovada',
      FINISHED_BY_PUBLISHER: 'Finalizado pela editora',
      REJECTED_AFTER_CORRECTIONS: 'Indeferido após correção',
      EXPIRED_APPEAL_PERIOD: 'Recurso fora do prazo',
      EXPIRED_ADJUSTMENT_PERIOD: 'Correção fora do prazo',
      EXPIRED_CHARACTERIZED_VOLUMES_DELIVERY_PERIOD:
        'Volumes parovados fora do prazo'
    },

    STEP_OPTIONS: {
      APPROVED: 'Volumes aprovados',
      CORRECTION: 'Correção',
      APPEAL: 'Recurso'
    }
  },

  GENERAL_INFO_PHASE: {
    COLLECTION: 'Coleção',
    CODE: 'Código da coleção',
    PHASE: 'Fase'
  },

  ADJUSTMENT_GENERAL_INFO: {
    EDITAL_OBJECT: 'Edital/Objeto ',
    TITLE: 'Nome da coleção ',
    CODE: 'Código da coleção',
    RESOURCE_PHASE: 'Fase: ',
    INFO_MESSAGE:
      '<strong>Informação.</strong> Prazo da solicitação de {{startDate}} - {{endDate}}'
  },

  FORM_QUESTION: {
    TITLE_FORM: 'Formulários',
    QUESTION_OPTIONS: {
      YES: 'Sim',
      NO: 'Não',
      NOT_APPLICABLE: 'Não se aplica',
      NOT_RATED: 'Selecione'
    },

    ANSWER_ACTION_YES: 'Sim',
    ANSWER_ACTION_NO: 'Não',
    ANSWER_ACTION_NOT_APPLICABLE: 'Não se aplica',
    ANSWER_ACTION_NOT_RATED: 'Limpar',

    DLG_TITLE_CLEAR: 'Limpar?',
    DLG_MSG_CLEAR:
      'Tem certeza que deseja limpar a análise? Todos as respostas serão apagadas.',
    BTN_CHANGE_SITUATION: 'Alterar Situação',
    MSG_NOT_ALLOW_CHANGE:
      'Não é permitido alterar a situação. Existe(m) item(ns) do(s) formulário(s) de análise sem resposta.'
  },

  SUBSCRIPTION_COLLECTION_PEDAGOGICAL_ANALYSIS_STEPS_TYPE: {
    PRELIMINARY_ANALYSIS: 'Análise preliminar',
    APPROVED: 'Volumes aprovados',
    CORRECTION: 'Correção',
    APPEAL: 'Recurso'
  },

  QUALIFICATION_ANALYSIS: {
    PUBLISHER: 'Empresa',
    COLLECTION: 'Obras',
    DETAILING_THE_SITUATION_TAB: 'Detalhamento da Diligência',
    EMITIR_OPINION_TECHNICIAN_TAB: 'Parecer Técnico'
  },

  QUALIFICATION_ANALYSIS_GENERAL_INFO: {
    YEAR_SERVICE: 'Ano de Atendimento',
    TYPE_ENABLE: 'Tipo de Habilitação',
    MODE_ENABLE: 'Modo Habilitação',
    ENABLER: 'Habilitador'
  },

  PUBLISH_INFORMATION: {
    PUBLISHER_TITLE: 'Informações da Empresa',
    CNPJ: 'CNPJ',
    PUBLISHER_PHONE: 'Telefone',
    RESPONSIBLE_NAME: 'Nome do Responsável',
    PUBLISHER_NAME_COMPANY: 'Nome da Empresa',
    PUBLISHER_CELLPHONE: 'Celular',
    PUBLISHER_NAME_FANTASY: 'Nome Fantasia',
    PUBLISHER_EMAIL: 'E-mail'
  },

  PUBLISHER_INFO_COLLECTION: {
    PUBLISHER_TITLE: 'Informações da Empresa',
    CNPJ: 'CNPJ',
    PUBLISHER_PHONE: 'Telefone',
    RESPONSIBLE_NAME: 'Nome do Responsável',
    PUBLISHER_NAME_COMPANY: 'Nome da Empresa',
    PUBLISHER_CELLPHONE: 'Celular',
    PUBLISHER_NAME_FANTASY: 'Nome Fantasia',
    PUBLISHER_EMAIL: 'E-mail'
  },

  SITUATION_SUMMARY_OF_COLLECTIONS: {
    TITLE: 'Resumo de Situação das Coleções',
    FILTER_LABEL: 'Situação da Coleção',
    ROW_COLLECTION_CODE: 'Código da Coleção',
    ROW_TITLE: 'Título',
    ROW_COMPONENT: 'Componente',
    ROW_CATEGORY: 'Categoria',
    ROW_COLLECTION_STATUS: 'Situação da Coleção'
  },

  PEDAGOGICAL_EVALUATION_ANALYSIS_PUBLISHER_BANK_INFORMATION: {
    PUBLISH_BANK_TITLE: 'Informações Bancárias',
    STATE: 'UF',
    ACCOUNT_TYPE: 'Tipo de Conta',
    CITY: 'Município',
    AGENCY: 'Agência',
    BANK: 'Banco',
    ACCOUNT: 'Conta'
  },

  PUBLISHER_ADDRESS: {
    COMPANY_ADDRESS_TITLE: 'Endereço da Empresa',
    CEP: 'CEP',
    STATE: 'UF',
    COMPLETE_ADDRESS: 'Endereço Completo',
    CITY: 'Município',
    DISTRICT: 'Bairro'
  },

  PUBLISHER_RULLING_INFORMATION: {
    PUBLISHER_RULLING_TITLE: 'Informações dos Dirigentes',
    NAME: 'Nome',
    CPF: 'CPF',
    RG: 'RG',
    ORGAO_EMISSOR: 'Orgão Emissor',
    POSISTION: 'Cargo',
    PRIORITY: 'Prioridade',
    REPRESENTED_BY_PROXY: 'Representado por Procuração',
    ACTION: 'Ação',
    REPRESENTED_BY_PROXY_TRUE: 'Sim',
    REPRESENTED_BY_PROXY_FALSE: 'Não'
  },

  PUBLISHER_RECEIVED_DOCS: {
    PUBLISHER_RECEIVED_DOCS_TITLE: 'Documentos Recebidos da convocação',
    DESCRIPTION: 'Descrição',
    DOCUMENT_NAME: 'Nome Arquivo',
    DIGITAL_SIGNATURE: 'Assinatura Digital ?',
    VERIFICATION_RESULT: 'Resultado Verificação',
    HAS_SIGNATURE: {
      TRUE: 'Sim',
      FALSE: 'Não'
    }
  },

  DETAILING_THE_SITUATION_TAB: {
    SEND: 'Enviar Diligência'
  },

  MODAL_SEND_DILIGENCE: {
    TITLE: 'Enviar Diligência',
    COMPANY: 'Empresa',
    COLLECTIONS: 'Coleções',
    CODE: 'Código',
    TITLE_COLLECTION: 'Título',
    CURRENT_STATUS: 'Situação atual da Habilitação',
    NEW_STATUS: 'Nova Situação da Habilitação',
    DATE: 'Prazo diligência'
  },

  QUALIFICATION_STATUS: {
    DILIGENCE_SITUATION: 'Situação da Habilitação',
    PUBLISHER_SITUATION: 'Situação da empresa',
    PUBLISHER_TITLE: 'Informações da Empresa',
    CNPJ: 'CNPJ',
    PUBLISHER_PHONE: 'Telefone',
    RESPONSIBLE_NAME: 'Nome do Responsável',
    PUBLISHER_NAME_COMPANY: 'Nome da Empresa',
    PUBLISHER_CELLPHONE: 'Celular',
    PUBLISHER_NAME_FANTASY: 'Nome Fantasia',
    PUBLISHER_EMAIL: 'E-mail'
  },

  COLLECTIONS_INFO: {
    TITLE: 'Informações da Coleção',
    FILTER_LABEL: 'Situação da Coleção',
    ROW_COLLECTION_CODE: 'Código da Coleção',
    ROW_TITLE: 'Título',
    ROW_COMPONENT: 'Componente',
    ROW_CATEGORY: 'Categoria',
    ROW_COLLECTION_STATUS: 'Situação da Coleção',
    ANALYSIS_ITEM: 'Item da análise',
    NOTE: 'Observação',
    RECORD: 'FICHA CATALOGRÁFICA'
  },

  COMPANY_DOCUMENTS: {
    TITLE: 'Pendências da Análise',
    COMPANY_DOCUMENTS: 'DOCUMENTAÇÃO DA EMPRESA',
    ANALYSIS_ITEM: 'Item da análise',
    NOTE: 'Observação'
  },

  GENERAL_NOTE: {
    TITLE: 'Observações Gerais'
  },

  HISTORY_DILIGENCE: {
    ROW_STATUS: 'Situação da habilitação',
    ROW_DATE: 'Data Hora',
    ROW_CREATOR: 'Autor'
  },

  HISTORY_DILIGENCE_STATUS: {
    SENT: 'Enviado',
    NOT_SENT: 'Não enviado',
    ADJUSTED: 'Ajustado'
  },

  PEDAGOGICAL_REQUEST_ADJUSTMENT_STATUS: {
    NO_ADJUSMENT: 'Sem correção',
    NOT_RATED: 'Não enviado',
    SENT_FOR_ANALISYS: 'Enviado para análise',
    APPROVED: 'Aprovado',
    REJECTED: 'Indeferido',
    FINISHED: 'Finalizado'
  },

  BANK_ACCOUNT_TYPES: {
    CHECKING_ACCOUNT: 'Conta corrente',
    SAVINGS_ACCOUNT: 'Conta poupança'
  },

  SITUATION_SUMMARY_OF_COLLECTIONS_MODAL_VOLUMES: {
    TITLE: 'Informações dos Volumes ',
    BTN_VIEW_VOLUMES: 'Vizualizar volumes',
    ROW_CODE_VOLUME: 'Código volume',
    ROW_TITLE: 'Título',
    ROW_AREA: 'Área',
    ROW_COMPONENT: 'Componente',
    ROW_SERIES: 'Series',
    ROW_FORMAT: 'Formato',
    ROW_EXEMPLAR: 'Exemplar',
    ROW_CATEGORY: 'Categoria'
  },

  QUALIFICATION_COLLECTION: {
    TITLE: 'Informações da Coleção',
    COLLECTION_TITLE: 'Título da Coleção: ',
    COLLECTION_CODE: 'Código da Coleção: ',
    TITLE_VOLUMES: 'Volumes da Coleção'
  },

  VOLUMES_OF_COLLECTION: {
    COLLECTION_TITLE: 'Volumes da Coleção: ',
    CODE_VOLUME: 'Código: ',
    TITLE_VOLUME: 'Título do Volume: ',
    COMPONENT: 'Componente: ',
    VOLUME_TITLE: 'Título do Volume: ',
    FORMAT: 'Formato: ',
    SERIES: 'Série: ',
    EDITION_NUMBER: 'Número da Edição: ',
    EXEMPLAR: 'Exemplar: ',
    YEAR_OF_EDITION: 'Ano da Edição: '
  },

  ICP_REPORT_STATUS: {
    APPROVED: 'Aprovado',
    DISAPPROVED: 'Reprovado',
    INDETERMINATE: 'Indeterminado'
  },

  ANSWER_CALL: {
    TITLE: 'Observações  - Resposta de Convocação',
    EXISTE_NOTE: 'Existem Observações'
  },

  PEDAGOGICAL_EVALUATION_ANALYSIS_UPDATE_HISTORY: {
    TITLE: 'Histórico de Atualizações',
    ROW_CPF: 'CPF',
    ROW_USER: 'Usuário',
    ROW_DATE: 'Data e hora',
    ROW_SITUATION: 'Situação'
  },

  PUBLISHER_SITUATION: {
    SITUATION: 'Situação da Empresa: '
  },

  COLLECTION_SITUATION: {
    SITUATION: 'Situação da obra: '
  },

  QUALIFICATION_ANALYSIS_STATUS_TYPE: {
    ALL: 'Todos',
    NOT_RATED: 'Não iniciada',
    NOT_STARTED: 'Não iniciada',
    IN_ANALYSIS: 'Em análise',
    FIRST_DILIGENCE: '1ª Diligência',
    SECOND_DILIGENCE: '2ª Diligência',
    ENABLED: 'Habilitada',
    DISABLED: 'Inabilitada',
    FULL_QUALIFICATION: 'Habilitação Total',
    PARTIAL_QUALIFICATION: 'Habilitação Parcial',
    QUALIFICATION_DISABLE: 'Inabilitação'
  },

  TABLE_AUTOR_SHIP: {
    TITLE: 'Autorias Vinculadas',
    ROW_CPF: 'CPF',
    ROW_NAME: 'Nome',
    ROW_PSEUDONYM: 'Pseudônimo',
    ROW_TYPE: 'Tipo',
    ROW_NATIONALITY: 'Nacionalidade',
    ROW_LOCALITY: 'Localidade'
  },

  PUBLISHER_INFORMATION_COLLECTION: {
    PUBLISHER_TITLE: 'Informações da Empresa',
    CNPJ: 'CNPJ',
    PUBLISHER_NAME_FANTASY: 'Nome Fantasia',
    PUBLISHER_NAME: 'Razão social'
  },

  CHANGE_SITUATION: {
    BTN_CHANGE_SITUATION: 'Alterar situação',
    TITLE_MODAL: 'Alterar situação da analise',
    SITUATION: 'Situação: ',
    LBL_NEW_SITUATION: 'Nova situação'
  },

  ENABLERS_HISTORY_MODAL: {
    TITLE_HISTORY: 'Histórico de Habilitador',
    START_DATE: 'Data de início',
    YEAR: 'Ano',
    CALL_ENABLER: 'Habilitador'
  },

  ANSWER_DILIGENCE_GENERAL_INFO: {
    YEAR_SERVICE: 'Ano de Atendimento',
    TYPE_ENABLE: 'Tipo de Habilitação',
    MODE_ENABLE: 'Modo Habilitação',
    ENABLER: 'Habilitador: '
  },

  DILIGENCE_DETAILS_DILIGENCE_INFORMATION: {
    DILIGENCE_INFORMATION_TITLE: 'Informações da Diligência',
    SEND_DATE: 'Data de envio',
    RESPONSE_DEADLINE: 'Prazo de resposta',
    DILIGENCE_SITUATION: 'Situação Diligência'
  },

  DILIGENCE_DETAILS_PUBLISHER_INFORMATION: {
    PUBLISHER_INFORMATION_TITLE: 'Informações da Empresa',
    CNPJ: 'CNPJ',
    PUBLISHER_NAME: 'Razão social',
    PUBLISHER: 'Empresa'
  },

  TAB_DILIGENCE_QUALIFICATION: {
    ROW_CNPJ: 'CNPJ',
    ROW_COMPANY: 'Empresa',
    ROW_DEADLINE: 'Prazo',
    ROW_SITUATION: 'Situação',
    ROW_DILIGENCE: 'Diligência',
    ROW_EDITAL: 'Edital',
    ROW_OBJECT: 'Objeto',
    ROW_CALL_TITLE: 'Título da Convocação',
    ROW_CALL_YEAR_TYPE: 'Ano de Atendimento',
    STATUS_TYPE: {
      SENT: 'Aguardando',
      ADJUSTED: 'Respondido'
    }
  },

  LIST_OF_QUALIFICATION_DILIGENCE: {
    TITLE_PAGE: 'Diligências',
    ROW_CNPJ: 'CNPJ',
    ROW_COMPANY: 'Empresa',
    ROW_YEAR: 'Ano',
    ROW_DILIGENCE: 'Diligências',
    ROW_DEADLINE: 'Prazo Diligência',
    ROW_SITUATION: 'Situação Diligência'
  },

  QUALIFICATION_DEADLINE_DILIGENCE_TYPE: {
    WITHIN_DEADLINE: 'Dentro do Prazo',
    DEADLINE: 'Alerta - Limite de Prazo',
    DEADLINE_EXPIRED: 'Prazo Vencido',
    RELEASED_FOR_ANALYSIS: 'Liberado para Análise'
  },

  ANSWER_DILIGENCE_DETAILS: {
    TITLE: 'Responder Diligência',
    VIEW_DILIGENCE: 'Diligência',
    ANSWER_DILIGENCE: 'Responder diligência',
    PUBLISHER_TITLE: 'Informações da Empresa',
    CNPJ: 'CNPJ',
    PUBLISHER_NAME: 'Razão social',
    PUBLISHER_NAME_FANTASY: 'Empresa',
    DILIGENCE_TITLE: 'Informações da Diligência',
    SEND_DATE: 'Data Envio',
    DEAD_LINE: 'Data resposta',
    SITUATION: 'Situação Diligência',
    INFO_ANSWER: 'Informações da Diligência',
    INFO_ANSWER_DOC_COMPANY: 'Informações e Documentos da Empresa',
    INFO_ANSWER_DOC_COLLECTIONS: 'Informações e Documentos das Coleções',
    COMPANY_NAME: 'Nome da empresa',
    COLLECTION: 'Coleção',
    COLLECTION_CODE: 'Código da Coleção',
    QUALIFICATION_SITUTATION: 'Situação da Habilitação',
    STATUS: {
      SENT: 'Aguardando',
      ADJUSTED: 'Respondido',
      FIRST_DILIGENCE: '1ª Diligência',
      SECOND_DILIGENCE: '2ª Diligência'
    }
  },

  DILIGENCE_DOCUMENTS: {
    TITLE: 'Documentos',
    DESCRIPTION: 'Descrição',
    HAS_SIGNATURE:
      'Possui apenas uma assinatura digital provida pela ICP-BRASIL?',
    COMPANY_NAME: 'Nome da empresa',
    COMPANY: 'Informações da Empresa',
    ANALYSIS_RESULT:
      'Resultado da verificação de conformidade de assinatura digital ICP-Brasil',
    APPROVED: 'Aprovado',
    DISAPPROVED: 'Desaprovado',
    CNPJ: 'CNPJ',
    PUBLISHER_NAME_FANTASY: 'Nome Fantasia',
    PUBLISHER_NAME: 'Razão social',
    COLLECTION_NAME: 'Título',
    COLLECTION_CODE: 'Código',
    COLLECTION_INFO: 'Informações da Coleção'
  },

  EMITIR_OPINION_TECHNICIAN: {
    DOWNLOAD_TECHNICAL_ADVICE: 'Download parecer técnico'
  },

  EMITIR_OPINION_TECHNICIAN_PUBLISHER_BANK_INFORMATION: {
    PUBLISH_BANK_TITLE: 'Informações Bancárias',
    STATE: 'UF',
    ACCOUNT_TYPE: 'Tipo de Conta',
    CITY: 'Município',
    AGENCY: 'Agência',
    BANK: 'Banco',
    ACCOUNT: 'Conta'
  },

  EMITIR_OPINION_TECHNICIAN_PUBLISHER_RULLING_INFORMATION: {
    TITLE: 'Informações dos Dirigentes',
    NAME: 'Nome',
    CPF: 'CPF',
    RG: 'RG',
    ORGAO_EMISSOR: 'Orgão Emissor',
    POSISTION: 'Cargo',
    PRIORITY: 'Prioridade',
    REPRESENTED_BY_PROXY: 'Representado por Procuração',
    ACTION: 'Ação',
    REPRESENTED_BY_PROXY_TRUE: 'Sim',
    REPRESENTED_BY_PROXY_FALSE: 'Não'
  },

  EMITIR_OPINION_TECHNICIAN_PUBLISHER_ADDRESS: {
    COMPANY_ADDRESS_TITLE: 'Endereço da Empresa',
    CEP: 'CEP',
    STATE: 'UF',
    COMPLETE_ADDRESS: 'Endereço Completo',
    CITY: 'Município',
    DISTRICT: 'Bairro'
  },

  EMITIR_OPINION_TECHNICIAN_COMPANY_QUALIFICATION: {
    TITLE: 'Habilitação da Empresa',
    NUMBER: 'N° Processo SEI',
    TECHNICIAN: 'Parecer Técnico'
  },

  EMITIR_OPINION_TECHNICIAN_SITUATION: {
    SITUATION: 'Situação da Empresa: '
  },

  EMITIR_OPINION_TECHNICIAN_NOTE_GENERAL: {
    TITLE: 'Observações Gerais'
  },

  EMITIR_OPINION_TECHNICIAN_TECHNICAL_ADVICE_HISTORY: {
    TITLE: 'Histórico de Atualizações',
    ROW_CPF_USER: 'Autor',
    ROW_DATE: 'Data e hora',
    ROW_CODE: 'Código',
    ROW_SITUATION: 'Situação'
  },

  EMITIR_OPINION_TECHNICIAN_FORM: {
    OPTION_TITLE: 'Conclusão',
    BTN_SAVE: 'Gravar Parecer',
    PROCESS_NUMBER: 'N° Processo SEI',
    FULL_QUALIFICATION: {
      LABEL: 'Habilitação Total',
      NOTE: 'Após análise da documentação apresentada pela empresa, constatamos o atendimento de todas as exigências legais e editalícias, o que confere  à editora e às obras inscritas condições para serem habilitadas. Caso se concretize a negociação, a situação da empresa no SICAF e no TST deverá ser verificada.'
    },
    PARTIAL_QUALIFICATION: {
      LABEL: 'Habilitação Parcial',
      NOTE: 'Após análise da documentação apresentada pela empresa, constatamos o atendimento de todas as exigências legais e editalícias, exceto para as obras excluídas, conforme detalhamento acima. As demais obras encontram-se aptas a serem habilitadas. Caso se concretize a negociação, a situação da empresa no SICAF e no TST deverá ser verificada.'
    },
    QUALIFICATION_DISABLE: {
      LABEL: 'Inabilitação',
      NOTE: 'Após análise da documentação apresentada pela empresa, constatamos o não atendimento das exigéncias legais e editalícias, o que torna a editora e as obras inaptas a prosseguir no processo de aquisição.'
    },
    MSG_PROCESS_NUMBER_MIN_17: 'Deve ter pelo menos 17 caracteres'
  },

  DILIGENCE_TIME_LINE: {
    ROW_QUESTION: 'Pergunta',
    ROW_ANSWER: 'Resposta',
    ROW_NOTE: 'Observação',
    ENABLER: 'Habilitador: '
  },

  DILIGENCE_TIME_LINE_MODAL: {
    TITLE: 'Detalhes das respostas',
    BTN_ALL_ANSWER: 'Todas respostas',
    ROW_QUESTION: 'Pergunta',
    ROW_ANSWER: 'Resposta',
    ROW_NOTE: 'Observação'
  },

  ANSWER_LINE: {
    QUESTION_OPTIONS: {
      YES: 'Sim',
      NO: 'Não',
      NOT_APPLICABLE: 'Não se aplica',
      NOT_RATED: 'Selecione'
    }
  },

  SCHOOLS: {
    TITLE: 'Escolas',
    TITLE_LIST: 'Lista de Escolas',
    GENERATE_XLS: 'Gerar XLS',
    ADDRESS: 'Endereço',
    DIRECTOR: 'Diretor',
    RESPONSIBLE_BOOK: 'Responsável pelo Recebimento de Livros',
    INEP: 'Código INEP',
    SCHOOL: 'Escola',
    DEPENDENCY: 'Esfera',
    UF: 'UF',
    CITY: 'Município',
    LOCATION: 'Localização',
    SITUATION: 'Situação',
    TAB_REGISTER: 'Cadastro',
    TAB_CHOICE_SCHOOL_MODEL: 'Escolha e  Modelo de Escolha',
    TAB_ORIETATION: 'Orientações',
    TAB_TERMS: 'Termos e Condições',
    TAB_ESPECIFICATIONS: 'Especificações',
    TAB_COMPOSITIONS: 'Composição dos Acervos',
    TAB_REGISTER_CHOICE: 'Registro de Escolha',
    TAB_FINALIZATION: 'Finalização da Escolha'
  },
  PUBLISHER_APPEAL_TAB: {
    TITLE: 'Resultados',
    TAB: {
      SUBSCRIPTION_VALIDATION: 'Inscrição e validação',
      PEDAGOGICAL_ANALYSIS: 'Analise pedagogica',
      QUALIFICATION: 'Habilitação'
    }
  },

  SUBSCRIPTION_VALIDATION_APPEAL_TABLE: {
    TITLE: 'Resultado',
    EDITAL_OBJECT: 'Edital/Objeto',
    DUE_DATE: 'Vigência',
    PUBLISHER: 'Editora',
    COLLECTIONS: 'Coleções',
    REQUESTS: 'Solicitações',
    STEPS: 'Fases',
    YEAR: 'Ano de atendimento',
    REQUEST_ADJUSTEMENT: 'Solicitar correção',
    VIEW_ADJUSTEMENT: 'Vizualizar correção',
    EDIT_ADJUSTEMENT: 'Editar correção',
    VIWE_ADJUSTEMENT: 'Vizualizar correção',

    COLLECTION_TITLE: 'Título da coleção',
    COLLECTION_CODE: 'Código da coleção',
    COLLECTION_RESULT: 'Resultado',

    COLLECTION_STATUS: {
      TITLE: 'Situação',
      VALIDATED: 'Validado',
      NOT_VALIDATED: 'Não validado',
      NOT_RATED: 'Não iniciado'
    },

    APPEAL_STATUS: {
      TITLE: 'Recurso',
      NOT_ANALYZED: 'Não avaliado',
      NOT_SUBMITTED: 'Não enviado',
      SUBMITTED: 'Em análise',
      DEFERRED: 'Deferido',
      REJECTED: 'Indeferido',
      NO_APPEAL: 'Sem recurso'
    },

    ADJUSTMENT: {
      SITUATION: 'Situação',
      APPEAL: 'Recurso'
    },

    EVALUATION_REPORT: 'Parecer',
    EVALUATION_REPORT_DOWNLOAD: 'Download do parecer',
    REQUEST_ADJUSTMENT: 'Solicitar correção',
    DOWNLOAD: 'Download do recurso',
    VIEW_REQUEST: 'Visualizar a solicitação',

    ADD_APPEAL: 'Solicitar recurso',
    EDIT_APPEAL: 'Modificar recurso',
    VIEW_APPEAL: 'Visualizar o recurso',

    BTN_ADJUSMENT_SEND_VALIDATION: {
      SEND: 'Enviar documentos validados',
      VIEW: 'Vizualizar os documentos validados'
    },

    FILE_DOWNLOAD_APPEAL: 'Download do recurso',
    FILE_PREVIEW_APPEAL: 'Visualizar o PDF recurso'
  },

  PEDAGOGICAL_ANALYSIS_APPEAL_TABLE: {
    TITLE: 'Resultado',
    EDITAL_OBJECT: 'Edital/Objeto',
    DUE_DATE: 'Vigência',
    PUBLISHER: 'Editora',
    COLLECTIONS: 'Coleções',
    REQUESTS: 'Solicitações',
    STEPS: 'Fases',
    YEAR: 'Ano de atendimento',
    REQUEST_ADJUSTEMENT: 'Solicitar correção',
    VIEW_ADJUSTEMENT: 'Vizualizar correção',
    EDIT_ADJUSTEMENT: 'Editar correção',
    VIWE_ADJUSTEMENT: 'Vizualizar correção',

    COLLECTION_TITLE: 'Título da coleção',
    COLLECTION_CODE: 'Código da coleção',
    COLLECTION_RESULT: 'Resultado',

    COLLECTION_STATUS: {
      TITLE: 'Situação',
      VALIDATED: 'Validado',
      NOT_VALIDATED: 'Não validado',
      NOT_RATED: 'Não iniciado'
    },

    APPEAL_STATUS: {
      TITLE: 'Recurso',
      NOT_ANALYZED: 'Não avaliado',
      NOT_SUBMITTED: 'Não enviado',
      SUBMITTED: 'Em análise',
      DEFERRED: 'Deferido',
      REJECTED: 'Indeferido',
      NO_APPEAL: 'Sem recurso'
    },

    ADJUSTMENT: {
      SITUATION: 'Situação',
      APPEAL: 'Recurso'
    },

    EVALUATION_REPORT: 'Parecer',
    EVALUATION_REPORT_DOWNLOAD: 'Download do parecer',
    REQUEST_ADJUSTMENT: 'Solicitar correção',
    DOWNLOAD: 'Download do recurso',
    VIEW_REQUEST: 'Visualizar a solicitação',

    ADD_APPEAL: 'Solicitar recurso',
    EDIT_APPEAL: 'Modificar recurso',
    VIEW_APPEAL: 'Visualizar o recurso',

    BTN_ADJUSMENT_SEND_VALIDATION: {
      SEND: 'Enviar documentos validados',
      VIEW: 'Vizualizar os documentos validados'
    },

    FILE_DOWNLOAD_APPEAL: 'Download do recurso',
    FILE_PREVIEW_APPEAL: 'Visualizar o PDF recurso'
  },

  SUBSCRIPTION_VALIDATION_CIRCULAR: {
    SUBSCRIBED_COLLECTIONS: 'Coleções inscritas',
    VALIDATED_COLLECTIONS: 'Coleções validadas',
    NOT_VALIDATED_COLLECTIONS: 'Coleções reprovadas',
    APPROVED_FP_REQUESTS: 'Coleções aprovadas FP'
  },

  SUBSCRIPTION_VALIDATION_REQUEST_CIRCULAR: {
    SUBSCRIBED_REQUESTS: 'Recursos cadastrados',
    ANALYZED_REQUESTS: 'Recursos analisados'
  },

  PEDAGOGICAL_ANALYSIS_CIRCULAR: {
    SUBSCRIBED_COLLECTIONS: 'Coleções inscritas',
    VALIDATED_COLLECTIONS: 'Coleções aprovadas',
    NOT_VALIDATED_COLLECTIONS: 'Coleções reprovadas',
    APPROVED_FP_REQUESTS: 'Coleções aprovadas FP'
  },

  SUBSCRIPTION_REQUEST_CIRCULAR: {
    SUBSCRIBED_REQUESTS: 'Recursos cadastrados',
    ANALYZED_REQUESTS: 'Recursos analisados'
  },

  APPEAL_STATUS_TYPE: {
    NOT_ANALYZED: 'Não avaliado',
    NOT_SUBMITTED: 'Não enviado',
    SUBMITTED: 'Em análise',
    DEFERRED: 'Deferido',
    REJECTED: 'Indeferido',
    NOT_CREATED: 'Sem recurso'
  },

  QUALIFICATION_ANALYSIS_APPEAL_TABLE: {
    EDITAL_OBJECT: 'Edital/Objeto',
    PUBLISHER: 'Editora',
    DUE_DATE: 'Vigência',
    YEAR: 'Ano de atendimento',
    SITUATION: 'Situação',
    APPEAL: 'Recurso',

    FILE_VISUALIZE_OPINION: 'Visualizar parecer Técnico',
    FILE_DOWNLOAD_OPINION: 'Download do parecer Técnico',

    ADD_APPEAL: 'Solicitar recurso',
    EDIT_APPEAL: 'Modificar recurso',
    VIEW_APPEAL: 'Visualizar o recurso',

    ANALYSIS_STATUS_TYPE: {
      NOT_STARTED: 'Não iniciada',
      IN_ANALYSIS: 'Em análise',
      FIRST_DILIGENCE: '1ª Diligência',
      SECOND_DILIGENCE: '2ª Diligência',
      ENABLED: 'Habilitada',
      DISABLED: 'Inabilitada',
      FULL_QUALIFICATION: 'Habilitação Total',
      PARTIAL_QUALIFICATION: 'Habilitação Parcial',
      QUALIFICATION_DISABLE: 'Inabilitado'
    }
  },

  QUALIFICATION_ANALYSIS_DILIGENCE_RESPONSE_STATUS_TYPE: {
    RECEIVED: 'Recebida',
    IN_PREPARATION: 'Em Elaboração',
    ANSWERED: 'Respondida'
  },

  EMITIR_OPINION_TECHNICIAN_COLLCETION_DILIGENCE_TIME_LINE: {
    ROW_TITLE: 'Habilitação das obras',
    ROW_SITUATION: 'Situação da obra'
  },

  EMITIR_OPINION_TECHNICIAN_TAB: {
    MSG_TECHNICAL_ADVICE_IS_ENABLED:
      'O parecer técnico só poderá ser realizado quando as análises de empresa e obra estiverem concluídas.'
  },

  FILLED_SITUATIONS: {
    NOT_FILLED: 'Não preenchido',
    IN_FILLING: 'Em preenchimento',
    FILLED: 'Preenchido',
    FINISHED: 'Finalizado',
    ALL: 'Todos'
  },
  TERMS_OF_CONSENTIMENT: {
    TITILE_PAGE: 'aviso de privacidade – Portal do Livro Digital',
    TERMS_OF_USE_TITLE: 'Termo de uso e política de privacidade pessoa física'
  },

  REQUEST_ACCESS: {
    TITLE: 'Solicitar Acesso como Gestor de Unidade de Ensino',
    TITLE_MANAGER: 'Solicitar Acesso como Representante de Unidade de Ensino',
    FULL_NAME: 'Nome Completo',
    CPF: 'CPF',
    EMAIL: 'E-mail',
    OCCUPATION: 'Função',
    PRINCIPAL_OCCUPATION: 'Gestor de unidade de ensino',
    REQUEST_ACCESS: 'Solicitar Acesso',
    SUCCESS_MESSAGE_TITLE: 'Verifique se os dados informados estão corretos',
    SUCCESS_MESSAGE:
      '<b>Sucesso</b> Você receberá um e-mail de notificação quando a sua solicitação de acesso for aprovada.',
    SUPPLEMENTARY_DATA_TITLE: 'Dados complementares',
    SUPPLEMENTARY_DATA:
      'Bem-vindo Professor! Com o objetivo de fornecer a melhor experiência em nossa plataforma, solicitamos que preencha os dados complementares abaixo:',
    STEP_UF: 'Selecione a UF',
    UF: 'UF',
    UF_PLACEHOLDER: 'Selecione a unidade da federação',
    STEP_CITY: 'Selecione o Município',
    CITY: 'Município',
    CITY_PLACEHOLDER: 'Selecione o município',
    STEP_SCHOOL: 'Selecione a sua Unidade de Ensino',
    SCHOOL: 'Unidade de Ensino',
    SCHOOL_PLACEHOLDER: 'Selecione a Unidade de Ensino',
    STEP_STAGE_EDUCATION: 'Selecione a Etapa da Educação de atuação',
    STAGE_EDUCATION: 'Etapa da Educação de Atuação ',
    STAGE_EDUCATION_PLACEHOLDER: 'Selecione a Etapa da Educação de atuação',
    USER_ALREADY_HAS_REGISTRATION:
      'Alerta. Não foi possível efetuar sua solicitação, usuário já possui cadastro no portal.',
    USER_WITH_ANOTHER_PERMISSION:
      'Alerta. Não foi possível efetuar sua solicitação, usuário já possui cadastro no portal com outro perfil.',
    CPF_ALREADY_REQUESTED_FOR_TEACHER:
      'Alerta. Não foi possível efetuar sua solicitação, pois usuário possui uma solicitação pendente.',
    TERMS_OF_USE: 'Termos de Uso',
    ACCEPT_TERMS_OF_USE:
      'Eu concordo com os termos de política de privacidade de dados.',
    REFUSE_TERMS_MODAL: {
      TITLE: 'Atenção',
      CONTENT:
        'Você recusou o Termo de Uso e Política de Privacidade e não poderá acessar o Portal do Livro Digital. Confirma a recusa? '
    },
    INFO_IS_CORRECT: 'Os dados acima estão corretos',
    SUMMARY: 'Resumo das informações',
    PERSONAL_INFO: 'Informações Pessoais',
    LOCALITY: 'Localidade',
    SCHOOL_STEP: 'Etapa de Ensino',
    YEAR_CLASS: 'Ano/Série'
  },

  CONSENT_AGREMMENT: {
    TERM_TITLE: 'Termo de Consentimento',
    TERM_DESCRIPTION:
      'Este termo de consentimento objetiva coletar autorização para utilização de informações de crianças (menores de doze anos) para fins de participação no Portal do Livro Digital, de responsabilidade do Fundo Nacional de Desenvolvimento da Educação – FNDE.',
    ACCEPT_TERMS_OF_USE:
      'Autorizo a unidade de ensino e o Fundo Nacional de Desenvolvimento da Educação - FNDE a tratarem dados pessoais do menor, com o objetivo exclusivo de permitir cadastro e acesso ao Portal do Livro Digital do Programa Nacional do Livro e do Material Didático (PNLD).',
    PEOPLE_CPF: 'Número do cadastro de pessoa física (CPF):',
    DEGREE_OF_KINSHIP: 'Grau de parentesco com a criança:',
    GUARDIAN_SECTION: 'Identificação do pai ou responsável legal',
    CHILD_SECTION: 'Identificação da criança',
    TERM_ATTENTION:
      'Informamos que os dados da criança serão mantidos, de forma segura e confidencial, apenas pelo período necessário para atendimento das finalidades específicas nesse termo.',
    TERM_ATTENTION_DESCRIPTION: 'Em caso de dúvidas adicionais, consulte os',
    TERM_ATTENTION_DESCRIPTION_LINK: ' Termos e Política de Privacidade ',
    TERM_ATTENTION_DESCRIPTION_LINK_AFTER:
      'ou entre em contato por meio do canal: [...].',
    TERM_ATTENTION_DESCRIPTION_AFTER:
      'Pelo presente termo, eu, pai e/ou responsável legal pela criança',
    MOTHER_OR_FATHER: 'Mãe ou Pai',
    SISTER_OR_BROTHER: 'Irmã ou Irmão',
    GRANDMOTHER_OR_GRANDFATHER: 'Avó ou Avô',
    AUNT_OR_UNCLE: 'Tio ou Tia',
    STEPMOTHER_OR_STEPFATHER: 'Madrasta ou Padrasto',
    GUARDIAN: 'Responsável pela guarda',
    OTHER: 'Outros Tipos'
  },

  DOWNLOAD_WORK: {
    SELECT_VOLUME: 'Selecione o volume',
    DESCRIPTION:
      'Ao selecionar um volume o download irá iniciar imeditamente em uma nova aba e esse processo pode demorar até 2 minutos para ser concluído a depender do tamanho da obra.'
  },
  LITERARY_DIDACTIC_WORKS: {
    TITLE_INTERACTIVE_READER: 'Leitor Interativo do PNLD',
    TITLE_RESOURCES_WORKS: 'Recursos digitais abertos',
    INFO_MESSAGE:
      'Professor para melhor navegação utilize os filtros de seleção disponíveis na tela.',
    FILTER_BY: 'Filtrar por:',
    SCHOOL: 'Escola',
    SEGMENT: 'Segmento',
    TYPE_LITERARY: 'Tipo de obra',
    DIDACTIC: 'Didática',
    TABS: {
      WORKS: 'Livros didáticos, literários e pedagógicos',
      DIGITAL_RESOURCES: 'Recursos Digitais abertos'
    },
    TYPE_LITERARY_OPTIONS: {
      DIDACTIC: 'Obras Didáticas',
      LITERARY: 'Obras Literárias',
      PEDAGOGICAL: 'Obras Pedagógicas',
      DIGITAL_RESOURCES: 'Recursos Digitais'
    },
    YEAR: 'Ano/Série',
    PUBLISHER: 'Editora',
    COMPONENT: 'Componente',
    CLEAN_FILTER: 'Limpar filtro',
    SEARCH: 'Buscar',
    RELATED_WORKS: 'Obras Relacionadas',
    LICENSES_PENDING_MESSAGE:
      'Estamos preparando as suas obras, em breve elas estarão disponíveis. Por favor, retorne posteriormente.',
    REFRESH_WORKS: 'Atualizar Obras',
    SUBTITLE_WORKS: 'Para acessar os ',
    SUBTITLE_WORKS_BOLD: 'livros didáticos, literários e pedagógicos ',
    SUBTITLE_WORKS_END: 'selecione os campos abaixo:',
    SUBTITLE_RESOURCES:
      'Acesse os <strong>recursos digitais abertos (red, pdf, áudio e vídeo)</strong> através dos links abaixo:',
    PNLD_2021: 'PNLD 2021',
    PNLD_2022: 'PNLD 2022',
    PNLD_2023: 'PNLD 2023',
    PNLD_2024: 'PNLD 2024',
    EDUCATION_COLLAGE: 'Ensino Médio',
    EDUCATION_ELEMENTARY: 'Anos Iniciais Do Ensino Fundamental',
    EDUCATION_FINAL_YEARS: 'Anos Finais do Ensino Fundamental',
    EDUCATION_INFANT: 'Educação Infantil',
    OBJECT_1: 'Objeto 1 – materiais de áudios de Língua Inglesa e Arte',
    OBJECT_2: 'Objeto 2 – Materiais Digitais',
    OBJECT_4: 'Objeto 4 – REDS',
    OBJECT_4_DIGITAL_RESOURCES: `Objeto 4 – Recursos Educacionais Digitais - RED's`,
    MATERIALS: 'Materiais Complementares',
    OBJECT_4_REDS: `Objeto 4 – Categoria 02: Recursos Educacionais Digitais - RED's`,
    AUTHORS: 'Autores',

    TEXT_DOWNLOAD: 'Faça download do nosso leitor para acessar sua biblioteca',
    RESOURCES_WORKS_ACCESS:
      '<strong>Acessar os Recursos Digitais Abertos.</strong> RED, PDF, áudio e vídeo.'
  },

  DOWNLOAD_CARDS: {
    PHONE: {
      CARD_TITLE: 'Versão para celulares'
    },
    DESKTOP: {
      CARD_TITLE: 'Versão para computadores'
    }
  },

  LITERARY_DIDACTIC_WORKS_DETAIL: {
    TITLE: 'Título do volume:',
    YEAR: 'Ano/Série:',
    AUTHORS: 'Autores:',
    NOTICE: 'Editora:',
    DOWNLOAD_APP: 'Faça o download do app clicando em uma das opções abaixo:',
    CHOOSE_SYSTEM:
      'Escolha o sistema operacional usado no computador onde o livro será lido',
    READ_ONLINE: 'Ler Online',
    DOWNLOAD_BOOK: 'Baixar livro',
    SHARE: 'Compartilhar',
    RECEIVE_PASSWORD:
      'Para ler este livro, você precisará de uma senha. Para recebê-la em seu e-mail, clique <Link>aqui</Link>',
    HERE: 'aqui',
    EDITION: 'edição',
    DOWNLOAD_BOOK_LABEL:
      'Faça o <strong>download do livro</strong> e acesse seu conteúdo.',
    RECEIVE_PASSWORD_LABEL:
      'Solicite a <strong>senha de validação</strong> para abrir o livro pelo Thorium.',
    RECEIVE_PASSWORD_LABEL_COMPLETE:
      'Ela será enviada em seu e-mail de cadastro.',
    REQUEST_PASSWORD: 'Solicitar senha',
    DIVIDER_TEXT: 'Passo a passo para baixar e ler seu livro',
    DOWNLOAD_DESK:
      'Caso não tenha o leitor <strong>Thorium Reader</strong> faça o download clicando no icone do seu sistema operacional abaixo:',
    ALERT_LABEL:
      '<strong>Atenção.</strong> Nesse momento o livro não pode ser acessado pelo celular. ',
    ALERT_LABEL_COMPLETE:
      'Para ler essa obra <strong>acesse o portal através do seu computador</strong> e realize o download do livro e do leitor Thorium Reader.'
  },
  SELECT_PROFILE: {
    SELECT_PROFILE_TITLE: 'Selecione o perfil que deseja cadastrar',
    DEFAULT_PLACEHOLDER: 'Selecione',
    SELECT_PERFIL: 'Selecione o seu perfil',
    REGISTER_STUDENT_PROFILE: 'Cadastrar estudante maior de 12 anos',
    REGISTER_GUARDIAN_PROFILE: 'Cadastrar estudante menor de 12 anos',
    REGISTER_TEACHER_PROFILE: 'Cadastrar professor',
    STUDENT: 'Estudante',
    TEACHER: 'Professor(a)',
    GUARDIAN: 'Mãe, Pai ou Responsável legal',
    STUDENT_DESCRIPTION: 'Cadastro de alunos maiores de 12 anos',
    TEACHER_DESCRIPTION: '',
    GUARDIAN_DESCRIPTION: 'Cadastro de alunos menores de 12 anos',
    STUDENT_NOT_FOUND:
      'Prezado(a) Aluno(a), seu vinculo com a escola não foi localizado. Por favor procurar a direção de sua escola para normalização do acesso.',
    STUDENT_LICENSE_ERROR:
      'Prezado(a) Aluno(a), houve um erro ao gerar a licença de seus materiais. Por favor tente novamente mais tarde ou procure a direção de sua escola para normalização do acesso',
    REQUEST_SUCCESS:
      '<b>Informação.</b> Solicitação de cadastro realizada com sucesso! Realize o login para ter acesso ao Portal do Livro Digital!',
    ALT_TXT: {
      STUDENT:
        'Ilustração representando um garoto de pele morena, com cabelo crespo, jaqueta de frio laranja e calças brancas e uma garota de pele morena, com cabelo curto na altura dos ombros camiseta azul escura e calça branca.',
      TEACHER:
        'Ilustração representando uma professora com pele de cor morena, cabelos castanhos, uma blusa preta e uma saia verde',
      GUARDIAN:
        'Ilustração representando um pai, moreno, com cabelos pretos e um cavanhaque e uma mãe com cabelos presos, de cor preta e uma bolsa azul clara.'
    },
    NAME_TXT: {
      STUDENT_SELECT: 'Selecione o perfil Aluno.',
      TEACHER_SELECT: 'Selecione o perfil Professor',
      GUARDIAN_SELECT: 'Selecione o perfil Mãe, Pai ou Responsável Legal.',
      STUDENT_REGISTER: 'Cadastrar perfil Aluno.',
      TEACHER_REGISTER: 'Cadastrar perfil Professor.',
      GUARDIAN_REGISTER: 'Cadastrar perfil Mãe, Pai ou Responsável Legal.'
    }
  },
  IDENTIFY_USER: {
    FORM_TITLE: 'Insira suas informações pessoais',
    HELLO: 'Olá',
    FILL_DATA:
      'Preencha seus dados pessoais para completar o auto cadastro como {{role}}'
  },

  CONSENT_AGREEMENT: {
    FORM_TITLE: 'Termo de consentimento'
  },
  REQUEST_LIST: {
    EDUCATION_UNIT: 'Unidade de Ensino',
    UF: 'UF',
    CITY: 'Município',
    STAGE_EDUCATION: 'Etapa da Educação',
    STATUS: 'Status da Solicitação',
    APPROVED: 'Aprovado',
    REQUESTED: 'Aguardando Aprovação',
    RELATIONSHIP_MANAGEMENT: 'Gestão de Vínculo',
    MODAL_DIALOG: {
      TITLE: 'Alerta',
      DESCRIPTION_1:
        'Ao prosseguir com a exclusão, será removido o seu vínculo.',
      DESCRIPTION_2: 'Deseja continuar?'
    }
  },
  NEW_REQUEST: {
    TITLE: 'Adicionar Unidade de Ensino',
    DESCRIPTION:
      'Preencha os campos abaixo com informações sobre a unidade de ensino que deseja adicionar:',
    UF: 'UF',
    CITY: 'Município',
    SCHOOL: 'Unidade de Ensino',
    STAGE_EDUCATION: 'Etapa da Educação',
    SEND_REQUEST: 'Enviar Solicitação'
  },
  CONFIRM_REGISTER: {
    CHECK_THE_DATA: 'Verifique se os dados informados estão corretos',
    CONFIRM_THE_DATA: 'Confirme os dados abaixo',
    PERSONAL_INFORMATION: 'Informações pessoais',
    SCHOOL: 'Unidade de Ensino',
    YEAR: 'Qual o ano/série do(a) estudante?'
  },

  REQUEST_ROLES: {
    ROLE_TEACHER: 'Professor',
    ROLE_GUARIDAN: 'Mãe, pai ou responśavel'
  },

  SELECT_SYSTEM_VERSION: {
    TITLE: 'Qual versão do sistema você deseja acessar?',
    DESCRIPTION:
      'Por favor, selecione a versão do sistema que você gostaria de acessar.',
    DEFAULT_VERSION: 'Versão antiga',
    NEW_VERSION: 'Nova versão'
  },

  REQUEST_ACCESS_COMMONS: {
    SELECT_CITY: 'Selecione a localidade da sua escola',
    SELECT_EDUCATION_UNIT: 'Selecione sua unidade de ensino',
    SELECT_LEARNING_PHASE: 'Etapa de ensino de educação',
    SELECT_LEARNING_PHASE_PLACEHOLDER: 'Selecione a Etapa de Ensino',
    SEARCH_SCHOOL: 'Buscar unidade de ensino',
    SELECT_SCHOOL: 'Selecione a escola',
    SCHOOL_STEP: 'Etapa de Ensino',
    EMAIL: 'E-mail',
    YEAR_CLASS: 'Ano/Série',
    LEARNING_LEVEL: 'Qual seu ano/série?',
    SCHOOL: 'Unidade de Ensino',
    CITY: 'Município',
    IS_UPPER_TWELVE: 'O estudante tem mais de 12 anos?',
    IS_LOWER_TWELVE:
      'Este cadastro é para estudantes menores de 12 anos. Retorne ao menu principal.',
    STUDENT_REQUEST_ALREADY_STARTED:
      'Já existe cadastro para o CPF utilizado, favor verificar.',
    STEPS: {
      LOCALITY: 'Localidade',
      EDUCATION_UNIT: 'Unidade de Ensino',
      LEARNING_PHASE: 'Etapa de Ensino de Atuação',
      FINISH: 'Resumo das Informações'
    },
    DISABILITIES: {
      STUDENT_DISABILITY: 'O estudante é pessoa com deficiência?',
      DISABILITY_DESCRIPTION: 'Sim, qual o tipo de deficiência?',
      BLINDNESS: 'Cegueira',
      LOW_VISION: 'Baixa Visão',
      MONOCULAR_VISION: 'Visão Monocular',
      DEAFNESS: 'Surdez',
      HEARING_IMPAIRMENT: 'Deficiência auditiva',
      DEAFBLINDNESS: 'Surdocegueira',
      PHYSICAL_DISABILITY: 'Deficiência física',
      INTELLECTUAL_DISABILITY: 'Deficiência intelectual',
      ADHD: 'Transtorno de Déficit de Atenção e Hiperatividade (TDAH)',
      DYSLEXIA: 'Dislexia',
      DYSCALCULIA: 'Discalculia',
      DYSGRAPHIA: 'Disortografia',
      AUTISM_SPECTRUM_DISORDER: 'Transtorno do espectro do autismo (ASD)',
      OTHER: 'Outros Tipos'
    },
    DEGREE_OF_KINSHIP: {
      PARANT_DEGREE: 'Grau de Relacionamento do responsável com o Estudante',
      MOTHER_OR_FATHER: 'Mãe ou Pai',
      SISTER_OR_BROTHER: 'Irmã ou Irmão',
      GRANDMOTHER_OR_GRANDFATHER: 'Avó ou Avô',
      AUNT_OR_UNCLE: 'Tio ou Tia',
      GUARDIAN: 'Responsável pela guarda',
      OTHER: 'Outros Tipos',
      STEPMOTHER_OR_STEPFATHER: 'Madrasta ou Padrasto'
    },
    SUMMARY: {
      TITLE: 'Resumo das informações',
      STUDENT_TITLE: 'Resumo das informações do(a) estudante',
      INFO_IS_CORRECT: 'Os dados acima estão corretos',
      PERSONAL_INFO: 'Informações pessoais',
      FULL_NAME: 'Nome completo',
      IS_UPPER_TWELVE: 'O estudante tem mais de 12 anos?'
    },
    ACCESSIBILITY_PHYSICAL_DEBILITATION: 'Selecione o tipo de deficiência',
    EMAIL_PLACEHOLDER: 'nome@email.com.br'
  },

  REQUEST_ACCESS_GUARDIAN: {
    PERSONAL_INFO: 'Insira as informações do(a) estudante',
    FULLNAME: 'Nome completo',
    FULLNAME_PLACEHOLDER: 'Digite o nome completo',
    DISABILITY_DESCRIPTION: 'Sim, qual o tipo de deficiência?',
    PARANT_DEGREE: 'Grau de Relacionamento do responsável com o Estudante',
    PLACEHOLDER: 'Selecione',
    FORM_TITLE:
      'Agora você irá iniciar o cadastro do estudante do qual é responsável.',
    FORM_DESCRIPTION:
      'No formulário abaixo preencha os dados para solicitar o acesso do estudante ao PNLD LIP, combinado?',
    FORM_CONFIRM: 'Entendi! Começar o cadastro',
    STUDENT_DISABILITY: 'O estudante é pessoa com deficiência?',
    STEPS: {
      PERSONAL_INFO: 'Informações pessoais',
      LOCALITY: 'Localidade',
      EDUCATION_UNIT: 'Unidade de Ensino',
      LEARNING_PHASE: 'Ano / Série',
      FINISH: 'Resumo das Informações'
    },
    MOTHER_OR_FATHER: 'Mãe ou Pai',
    SISTER_OR_BROTHER: 'Irmã ou Irmão',
    GRANDMOTHER_OR_GRANDFATHER: 'Avó ou Avô',
    AUNT_OR_UNCLE: 'Tio ou Tia',
    STEPMOTHER_OR_STEPFATHER: 'Madrasta ou Padrasto',
    GUARDIAN: 'Responsável pela guarda',
    OTHER: 'Outros Tipos',
    BLINDNESS: 'Cegueira',
    LOW_VISION: 'Baixa Visão',
    MONOCULAR_VISION: 'Visão Monocular',
    DEAFNESS: 'Surdez',
    HEARING_IMPAIRMENT: 'Deficiência auditiva',
    DEAFBLINDNESS: 'Surdocegueira',
    PHYSICAL_DISABILITY: 'Deficiência física',
    INTELLECTUAL_DISABILITY: 'Deficiência intelectual',
    ADHD: 'Transtorno de Déficit de Atenção e Hiperatividade (TDAH)',
    DYSLEXIA: 'Dislexia',
    DYSCALCULIA: 'Discalculia',
    DYSGRAPHIA: 'Disortografia',
    AUTISM_SPECTRUM_DISORDER: 'Transtorno do espectro do autismo (ASD)'
  },
  REQUEST_COMPLETED: {
    BUTTON: 'Visualizar lista de escolas cadastradas',
    BUTTON_ADD_SCHOOL: 'Cadastrar nova escola',
    CARD_TITLE: 'Faça download do nosso leitor para acessar sua biblioteca',
    ROLE_GUARDIAN: {
      TITLE: 'Cadastro enviado com sucesso!',
      SUBTITLE:
        'Agora a escola do(a) estudante irá analisar a solicitação de acesso ao PNLD LIP. Caso os dados estejam corretos o acesso será liberado. <br> <br> Você receberá uma notificação por e-mail. Fique ligado!',
      MANAGEMENT_PAGE: 'Visualizar lista de estudantes cadastrados',
      REQUEST: 'Cadastrar novo estudante'
    },
    ROLE_STUDENT: {
      TITLE: 'Cadastro concluído. Pendente de aprovação.',
      SUBTITLE:
        'Agora sua escola irá analisar seu cadastro. Se os dados estiverem corretos seu acesso ao PNLD LIP será liberado. <br> <br>Você receberá uma notificação em seu e-mail quando isso acontecer. Fique ligado!'
    },
    ROLE_TEACHER: {
      TITLE:
        'Cadastro concluído. Baixe o app e aguarde a aprovação da sua escola.',
      SUBTITLE:
        'Agora sua escola irá analisar seu cadastro. Se os dados estiverem corretos seu acesso ao PNLD LIP será liberado. <br><br>Você receberá uma notificação em seu e-mail quando isso acontecer. Fique ligado!',
      MANAGEMENT_PAGE: 'Visualizar lista de escolas cadastradas',
      REQUEST: 'Cadastrar nova escola'
    }
  },

  REQUEST_APPROVED: {
    TITLE: 'Aprovação realizada com sucesso.',
    DESCRIPTION:
      'A sua aprovação no sistema foi realizada com sucesso. Por favor, faça o login novamente para acessar a página inicial.',
    LOGOUT: 'Refazer login'
  },

  REQUEST_NOT_ALLOWED: {
    TITLE: 'Opss! Você possui menos de 12 anos.',
    SUBTITLE:
      'Por esse motivo será necessário que o seu Pai, Mãe ou Responsável Legal faça o cadastro por você. <br><br>Você poderá acessar os livros digitais quando seu cadastro for finalizado.'
  },

  SELECT_SCHOOL_MODAL: {
    MODAL_TITLE: 'Selecione a sua escola',
    SELECT_THIS_SCHOOL: 'Selecionar essa escola',
    UF: 'UF',
    CITY: 'Município',
    EDUCATION_UNIT: 'Unidade de Ensino',
    INEP: 'INEP',
    SEARCH_PLACEHOLDER: 'Pesquise pelo nome da escola ou pelo INEP',
    SELECT_SCHOOL: 'Selecione uma escola',
    SELECTED_SCHOOL: 'Escola selecionada'
  },

  SCHOOL_MANAGEMENT: {
    SCHOOL_MANAGEMENT: 'Gestão de escolas',
    NEW_SCHOOL: 'Cadastrar nova escola',
    EDUCATION_UNIT: 'Unidade de Ensino',
    LEARNING_PHASE: 'Etapa de Ensino',
    SITUATION: 'Situação',
    DELETE_SUCCESS: 'Vínculo removido com sucesso.',
    CONFIRM_DELETE:
      'Confirma a exclusão da solicitação de vínculo com escola para acesso ao PNLD?'
  },

  PRIVACY_POLICY: {
    TITLE: 'Política de Privacidade'
  },
  STUDENT_MANAGEMENT: {
    TITLE: 'Gestão de estudantes',
    NEW_STUDENT: 'Cadastrar novo estudante',
    EDUCATION_UNIT: 'Unidade de Ensino',
    YEAR: 'Ano/Série',
    SITUATION: 'Situação',
    CONFIRM_DELETE:
      'Confirma a exclusão da solicitação de acesso do estudante ao Portal do Livro Digital do PNLD?'
  },
  WARNING_TAG: {
    EDIT_MODE:
      'Atenção, você está alterando um registro previamente cadastrado. Para gravar as novas informações você precisa seguir até a etapa de resumo das informações e acionar o botão <b>Avançar</b>.'
  },
  REQUEST_STATUS: {
    INCOMPLETE: 'Incompleto',
    APPROVED: 'Aprovado',
    PENDING: 'Pendente',
    DISAPPROVED: 'Reprovado'
  },
  TOOLTIP: {
    EDIT_STUDENT: 'Editar perfil de estudante',
    DELETE_STUDENT: 'Excluir perfil de estudante',
    EDIT_SCHOOL: 'Editar escola',
    DELETE_SCHOOL: 'Excluir escola',
    EDIT_PERSONAL_INFO: 'Editar informações pessoais',
    EDIT_LOCALITY: 'Editar localidade',
    EDIT_EDUCATION_UNIT: 'Editar unidade de ensino',
    EDIT_LEARNING_PHASE: 'Editar etapa da educação de atuação'
  },
  ACCESSIBILITY: {
    CONFIRM_PROFILE_BUTTON: 'Confirmar cadastro de perfil',
    CONFIRM_GUARDIAN_BUTTON: 'Confirmar requisição de acesso por guardião',
    BACK_BUTTON: 'Voltar',
    SELECT: {
      PARANT_DEGREE: 'Selecione o grau de parentesco com o estudante',
      SCHOOL: 'Selecione a escola',
      SEGMENT: 'Selecione o segmento',
      YEAR: 'Selecione o ano/série',
      TYPE_LITERARY: 'Selecione o tipo de obra',
      COMPONENT: 'Selecione o componente'
    }
  }
};
