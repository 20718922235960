import React from 'react';
import { useController } from 'react-hook-form';
import InputMask from 'react-input-mask';

import useFormError from 'hooks/useFormError';
import PropTypes from 'prop-types';

import {
  ErrorHelperText,
  StyledErrorIcon
} from 'components/ControlGroup/styles';
import { UncontrolledTextField } from 'components/TextField';

const CustomInputMask = ({ inputRef, ...props }) => (
  <InputMask ref={inputRef} {...props} maskChar={null} />
);

const MaskedTextField = ({
  mask,
  beforeMaskedStateChange,
  name,
  id,
  formMethods,
  defaultValue,
  isDisabled = false,
  supressErrorText = false,
  ...rest
}) => {
  const { control, errors } = formMethods;
  const {
    field: { ref, value, onChange, ...inputProps }
  } = useController({
    name,
    control,
    defaultValue
  });

  const errorProps = useFormError(errors, name, supressErrorText);
  const hasError = !!errorProps.error;

  const maskedValue =
    typeof value !== 'undefined' && value !== null ? `${value}` : '';

  return (
    <UncontrolledTextField
      ref={ref}
      value={maskedValue}
      onChange={e => {
        onChange(e?.target?.value || e);
      }}
      error={hasError}
      helperText={
        !supressErrorText &&
        hasError && (
          <ErrorHelperText>
            <StyledErrorIcon />
            {errorProps.helperText}
          </ErrorHelperText>
        )
      }
      disabled={isDisabled}
      InputProps={{
        inputComponent: CustomInputMask,
        inputProps: {
          mask,
          beforeMaskedStateChange,
          maskPlaceholder: null,
          id: id || name
        }
      }}
      FormHelperTextProps={{
        sx: { marginLeft: 0 }
      }}
      {...inputProps}
      {...rest}
    />
  );
};

MaskedTextField.defaultProps = {
  id: undefined,
  defaultValue: '',
  label: '',
  mask: undefined,
  beforeMaskedStateChange: undefined
};

MaskedTextField.propTypes = {
  id: PropTypes.string,
  formMethods: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  mask: PropTypes.string,
  beforeMaskedStateChange: PropTypes.func,
  defaultValue: PropTypes.string
};

export default MaskedTextField;
