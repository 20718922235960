import { formatString } from 'helpers/formatString';

import {
  GUARDIANS_REQUEST_ACCESS,
  REQUEST_ACCESS,
  STUDENT_REQUEST_ACCESS,
  TEACHER_REQUEST_ACCESS
} from '../constants/api';

export default class AddressService {
  /**
   * @param {object} props
   * @param {import('adapters/axios').Axios} props.http
   */

  constructor({ http }) {
    this.http = http;
  }

  async getCheckTeacherAccessRequest(cpf, inep, learningLevel) {
    const url = formatString(REQUEST_ACCESS.GET_CHECK_TEACHER_ACCESS_REQUEST, {
      cpf,
      inep,
      learningLevel
    });
    return this.http.get(url, {
      skipErrorHandling: true
    });
  }

  async getStateList() {
    return this.http.get(REQUEST_ACCESS.GET_STATE_LIST);
  }

  async getCityList(initials) {
    const url = formatString(REQUEST_ACCESS.GET_CITY_LIST, {
      initials
    });
    return this.http.get(url);
  }

  async getSchoolList(ibge) {
    const url = formatString(REQUEST_ACCESS.GET_SCHOOL_LIST, {
      ibge
    });
    return this.http.get(url);
  }

  async getLearningLevelList(inep) {
    const url = formatString(REQUEST_ACCESS.GET_LEARNING_LEVEL_LIST, {
      inep
    });
    return this.http.get(url);
  }

  async getLearningPhaseList(inep) {
    const url = formatString(REQUEST_ACCESS.GET_LEARNING_PHASE_LIST, {
      inep
    });

    return this.http.get(url);
  }

  async postTeacherRequest(payload) {
    return this.http.post(REQUEST_ACCESS.POST_TEACHER_REQUEST, payload);
  }

  async postStudens(payload) {
    return this.http.post(REQUEST_ACCESS.POST_SELECT_PROFILE, payload, {
      skipErrorHandling: true
    });
  }

  async postAcceptTermsOfUse(payload) {
    return this.http.post(
      REQUEST_ACCESS.REGISTER_TERMS_OF_USE_UPDATED,
      payload
    );
  }

  async updateAcceptTermsOfUse(payload) {
    return this.http.put(REQUEST_ACCESS.REGISTER_TERMS_OF_USE_UPDATED, payload);
  }

  async checkTermsOfUseUpdated({ termVersion }) {
    return this.http.get(REQUEST_ACCESS.REGISTER_TERMS_OF_USE_UPDATED, {
      params: {
        termVersion
      }
    });
  }

  async configureUserAccess() {
    return this.http.get(REQUEST_ACCESS.CONFIGURE_USER_ACCESS);
  }

  // REQUEST ACCESS TEACHER
  async getRequestAccessTeacher(pageNumber, pageSize) {
    return this.http.get(TEACHER_REQUEST_ACCESS.TEACHER_REQUEST_ACCESS, {
      params: {
        page: pageNumber,
        size: pageSize
      }
    });
  }

  async postRequestAccessTeacher(payload) {
    return this.http.post(
      TEACHER_REQUEST_ACCESS.TEACHER_REQUEST_ACCESS,
      payload
    );
  }

  async putRequestAccessTeacherFinish({ id }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.PUT_TEACHER_REQUEST_ACCESS_FINISH,
      { id }
    );
    return this.http.put(url);
  }

  async putRequestAccessTeacher({ payload, id }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.PUT_TEACHER_REQUEST_ACCESS,
      { id }
    );
    return this.http.put(url, payload);
  }

  async deleteRequestAccessTeacher({ id }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.DELETE_TEACHER_REQUEST_ACCESS,
      { id }
    );
    return this.http.delete(url);
  }

  async putRequestAccessTeacherEmail({ id, email }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.PUT_TEACHER_REQUEST_ACCESS_EMAIL,
      { id, email }
    );
    return this.http.put(url);
  }

  async putRequestAccessTeacherLocality({ cityId, id }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.PUT_TEACHER_REQUEST_ACCESS_LOCALITY,
      { id, cityId }
    );
    return this.http.put(url);
  }

  async putRequestAccessTeacherLearningPhase({ learningPhaseId, id }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.PUT_TEACHER_REQUEST_ACCESS_LEARNING_PHASE,
      { id, learningPhaseId }
    );
    return this.http.put(url);
  }

  async putRequestAccessTeacherSchool({ inep, id }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.PUT_TEACHER_REQUEST_ACCESS_SCHOOL,
      { id, inep }
    );
    return this.http.put(url);
  }

  async putRequestAccessTeacherAllData({ payload, id }) {
    const url = formatString(
      TEACHER_REQUEST_ACCESS.PUT_TEACHER_REQUEST_ACCESS,
      { id }
    );
    return this.http.put(url, payload);
  }

  // REQUEST ACCESS GUARDIANS
  async putRequestAccessGuardiansAllData({ payload, id }) {
    const url = formatString(
      GUARDIANS_REQUEST_ACCESS.PUT_GUARDIANS_REQUEST_ACCESS_EDIT_ALL_DATA,
      { id }
    );

    return this.http.put(url, payload);
  }

  async getRequestAccessGuardians(pageNumber, pageSize) {
    return this.http.get(
      GUARDIANS_REQUEST_ACCESS.GET_GUARDIANS_REQUEST_ACCESS,
      {
        params: {
          page: pageNumber,
          size: pageSize
        }
      }
    );
  }

  async postRequestAccessGuardians(payload) {
    return this.http.post(
      GUARDIANS_REQUEST_ACCESS.POST_GUARDIANS_REQUEST_ACCESS,
      payload
    );
  }

  async putRequestAccessGuardians({ payload, id }) {
    const url = formatString(
      GUARDIANS_REQUEST_ACCESS.PUT_GUARDIANS_REQUEST_ACCESS,
      { id }
    );

    return this.http.put(url, payload, { skipErrorHandling: true });
  }

  async putRequestAccessGuardiansEmail({ id, email }) {
    const url = formatString(
      GUARDIANS_REQUEST_ACCESS.PUT_GUARDIANS_REQUEST_ACCESS_EMAIL,
      { id, email }
    );

    return this.http.put(url);
  }

  async putRequestAccessGuardiansLearningLevel({ learningLevelId, id }) {
    const url = formatString(
      GUARDIANS_REQUEST_ACCESS.PUT_GUARDIANS_REQUEST_ACCESS_LEARNING_PHASE,
      { id, learningLevelId }
    );

    return this.http.put(url);
  }

  async putRequestAccessGuardiansLearningPhase({ learningPhaseId, id }) {
    const url = formatString(
      GUARDIANS_REQUEST_ACCESS.PUT_GUARDIANS_REQUEST_ACCESS_LEARNING_PHASE,
      { id, learningPhaseId }
    );

    return this.http.put(url);
  }

  async putRequestAccessGuardiansVerifyInformation({ id }) {
    const url = formatString(
      GUARDIANS_REQUEST_ACCESS.PUT_GUARDIANS_REQUEST_ACCESS_VERIFY_INFORMATION,
      { id }
    );
    return this.http.put(url);
  }

  async getDegreeOfKinshipList() {
    return this.http.get(GUARDIANS_REQUEST_ACCESS.GET_DEGREE_OF_KINSHIP_LIST);
  }

  async getDisabilityList() {
    return this.http.get(GUARDIANS_REQUEST_ACCESS.GET_DISABILITY_LIST);
  }

  async deleteRequestAccessGuardians({ id }) {
    const url = formatString(
      GUARDIANS_REQUEST_ACCESS.DELETE_GUARDIANS_REQUEST_ACCESS,
      { id }
    );
    return this.http.delete(url);
  }

  // REQUEST ACCESS STUDENT
  async getRequestAccessStudent(pageNumber, pageSize) {
    return this.http.get(STUDENT_REQUEST_ACCESS.STUDENT_REQUEST_ACCESS, {
      params: {
        page: pageNumber,
        size: pageSize
      }
    });
  }

  async postRequestAccessStudent(payload) {
    return this.http.post(
      STUDENT_REQUEST_ACCESS.STUDENT_REQUEST_ACCESS,
      payload,
      { skipErrorHandling: true }
    );
  }

  async putRequestAccessStudent(payload) {
    const { id } = payload;

    const url = formatString(
      STUDENT_REQUEST_ACCESS.EDIT_STUDENT_REQUEST_ACCESS,
      {
        id
      }
    );
    return this.http.put(url, payload);
  }

  async putRequestAccessStudentAllData({ payload, id }) {
    const url = formatString(
      STUDENT_REQUEST_ACCESS.PUT_STUDENT_REQUEST_ACCESS_EDIT_ALL_DATA,
      { id }
    );
    return this.http.put(url, payload);
  }

  async putRequestAccessStudentLocality({ cityId, id }) {
    const url = formatString(
      STUDENT_REQUEST_ACCESS.PUT_STUDENT_REQUEST_ACCESS_LOCALITY,
      { id, cityId }
    );

    return this.http.put(url);
  }

  async putRequestAccessStudentSchool({ inep, id }) {
    const url = formatString(
      STUDENT_REQUEST_ACCESS.PUT_STUDENT_REQUEST_ACCESS_SCHOOL,
      { id, inep }
    );

    return this.http.put(url);
  }

  async putRequestAccessStudentLearningPhase({ learningPhaseId, id }) {
    const url = formatString(
      STUDENT_REQUEST_ACCESS.PUT_STUDENT_REQUEST_ACCESS_LEARNING_PHASE,
      { id, learningPhaseId }
    );

    return this.http.put(url);
  }

  async putRequestAccessStudentFinish({ id }) {
    const url = formatString(
      STUDENT_REQUEST_ACCESS.PUT_STUDENT_REQUEST_ACCESS_FINISH,
      { id }
    );
    return this.http.put(url);
  }
}
