export default class RequestAccessUseCases {
  /**
   * @param {object} props
   * @param {import('./requestAccess.service').default} props.requestAccessService
   */
  constructor({ requestAccessService }) {
    this.requestAccessService = requestAccessService;
  }

  async getCheckTeacherAccessRequest(cpf, inep, learningLevel) {
    return this.requestAccessService.getCheckTeacherAccessRequest(
      cpf,
      inep,
      learningLevel
    );
  }

  async getStateList() {
    return this.requestAccessService.getStateList();
  }

  async getCityList(initials) {
    return this.requestAccessService.getCityList(initials);
  }

  async getSchoolList(ibge) {
    return this.requestAccessService.getSchoolList(ibge);
  }

  async getLearningLevelList(inep) {
    return this.requestAccessService.getLearningLevelList(inep);
  }

  async getLearningPhaseList(inep) {
    return this.requestAccessService.getLearningPhaseList(inep);
  }

  async postTeacherRequest(payload) {
    return this.requestAccessService.postTeacherRequest({
      ...payload
    });
  }

  async postStudens(payload) {
    return this.requestAccessService.postStudens({
      ...payload
    });
  }

  async checkTermsOfUse(needUpdateTermsAndUse, payload) {
    return needUpdateTermsAndUse
      ? this.requestAccessService.updateAcceptTermsOfUse(payload)
      : this.requestAccessService.postAcceptTermsOfUse(payload);
  }

  async checkTermsOfUseUpdated({ termVersion }) {
    return this.requestAccessService.checkTermsOfUseUpdated({ termVersion });
  }

  async configureUserAccess() {
    return this.requestAccessService.configureUserAccess();
  }

  // REQUEST ACCESS TEACHER
  async getRequestAccessTeacher(page, size) {
    return this.requestAccessService.getRequestAccessTeacher(page, size);
  }

  async postRequestAccessTeacher(payload) {
    return this.requestAccessService.postRequestAccessTeacher(payload);
  }

  async putRequestAccessTeacher(payload) {
    return this.requestAccessService.putRequestAccessTeacher(payload);
  }

  async putRequestAccessTeacherFinish({ id }) {
    return this.requestAccessService.putRequestAccessTeacherFinish({
      id
    });
  }

  async putRequestAccessTeacherEmail(payload) {
    return this.requestAccessService.putRequestAccessTeacherEmail(payload);
  }

  async putRequestAccessTeacherLocality(payload) {
    return this.requestAccessService.putRequestAccessTeacherLocality(payload);
  }

  async putRequestAccessTeacherLearningPhase(payload) {
    return this.requestAccessService.putRequestAccessTeacherLearningPhase(
      payload
    );
  }

  async putRequestAccessTeacherSchool(payload) {
    return this.requestAccessService.putRequestAccessTeacherSchool(payload);
  }

  async deleteRequestAccessTeacher({ id }) {
    return this.requestAccessService.deleteRequestAccessTeacher({
      id
    });
  }

  async putRequestAccessTeacherAllData(payload) {
    return this.requestAccessService.putRequestAccessTeacherAllData(payload);
  }

  // REQUEST ACCESS GUARDIANS
  async getRequestAccessGuardians(page, size) {
    return this.requestAccessService.getRequestAccessGuardians(page, size);
  }

  async postRequestAccessGuardians(payload) {
    return this.requestAccessService.postRequestAccessGuardians(payload);
  }

  async putRequestAccessGuardians(payload) {
    return this.requestAccessService.putRequestAccessGuardians(payload);
  }

  async putRequestAccessGuardiansEmail(payload) {
    return this.requestAccessService.putRequestAccessGuardiansEmail(payload);
  }

  async putRequestAccessGuardiansVerifyInformation(payload) {
    return this.requestAccessService.putRequestAccessGuardiansVerifyInformation(
      payload
    );
  }

  async getDegreeOfKinshipList() {
    return this.requestAccessService.getDegreeOfKinshipList();
  }

  async getDisabilityList() {
    return this.requestAccessService.getDisabilityList();
  }

  async deleteRequestAccessGuardians({ id }) {
    return this.requestAccessService.deleteRequestAccessGuardians({
      id
    });
  }

  async putRequestAccessGuardiansAllData(payload) {
    return this.requestAccessService.putRequestAccessGuardiansAllData(payload);
  }

  // REQUEST ACCESS STUDENT
  async getRequestAccessStudent(page, size) {
    return this.requestAccessService.getRequestAccessStudent(page, size);
  }

  async postRequestAccessStudent(payload) {
    return this.requestAccessService.postRequestAccessStudent(payload);
  }

  async putRequestAccessStudent(payload) {
    return this.requestAccessService.putRequestAccessStudent(payload);
  }

  async putRequestAccessStudentAllData(payload) {
    return this.requestAccessService.putRequestAccessStudentAllData(payload);
  }

  async putRequestAccessStudentFinish(payload) {
    return this.requestAccessService.putRequestAccessStudentFinish(payload);
  }

  async putRequestAccessStudentLocality(payload) {
    return this.requestAccessService.putRequestAccessStudentLocality(payload);
  }

  async putRequestAccessStudentSchool(payload) {
    return this.requestAccessService.putRequestAccessStudentSchool(payload);
  }

  async putRequestAccessStudentLearningPhase(payload) {
    return this.requestAccessService.putRequestAccessStudentLearningPhase(
      payload
    );
  }
}
