import React from 'react';

import { Grid, Box, Container } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import { StyledWarningTag } from './styles';

const EditWarning = ({ show }) => {
  const translate = useLangContext();
  if (!show) return null;

  return (
    <Grid item xs={12}>
      <Container>
        <Box mb={4}>
          <StyledWarningTag
            warning={{
              message: translate('WARNING_TAG:EDIT_MODE'),
              options: { variant: 'warning', hideCloseButton: true }
            }}
          />
        </Box>
      </Container>
    </Grid>
  );
};

export default EditWarning;
