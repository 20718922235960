import React, { useCallback, useEffect, useState } from 'react';

import { Container, Grid, Typography } from '@mui/material';

import history from 'framework/history';
import { SKELETON_TYPES } from 'helpers/constants/skeletonTypes';
import { ROLES } from 'helpers/roles';

import { useLangContext } from 'components/Globalization';
import SkeletonModels from 'components/SkeletonModels';
import wrapper from 'components/Wrapper';

import { INITIAL_PAGE_ROUTES } from 'modules/InitialPage/constants/routes';
import { requestFunctions } from 'modules/RequestAccess/constants/requestFunctions';
import ROLES_MAP from 'modules/RequestAccess/constants/rolesMap';

import { REQUEST_STATUS } from '../../../../constants/requestStatusEnum';
import { REQUEST_ACCESS_ROUTES } from '../../../../constants/routes';
import { useRequestAccessContext } from '../../../../Context';
import {
  Card,
  DescriptionCard,
  StyledButton,
  StyledImage,
  TitleCard,
  Wrapper
} from '../../../../styles';

const REQUEST_STEP_TERMS = 5;

const roleCheck = {
  isGuardians: role => role === ROLES.ROLE_GUARDIAN,
  isStudent: role => role === ROLES.ROLE_STUDENT
};

const SelectProfile = ({ userAccess }) => {
  const { requestAvailableOptions } = userAccess;

  const translate = useLangContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCard, setIsLoadingCard] = useState(true);

  const { setRequestData, setRequestFunctions, setIsEditing } =
    useRequestAccessContext();

  const fetchData = useCallback(async service => {
    setIsLoading(true);
    try {
      const resp = await service();

      return resp;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    return [];
  }, []);

  const redirectToIdentify = () => {
    history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
  };

  const getCurrentRequest = requestedData =>
    requestedData.find(
      item => item.requestStatus !== REQUEST_STATUS.DISAPPROVED
    );

  const isAllDisapproved = requestedData =>
    requestedData.every(
      request => request.requestStatus === REQUEST_STATUS.DISAPPROVED
    ).length === requestedData.length;

  const containsStatus = (statusList, status) => statusList.includes(status);

  const handleStudentRedirect = useCallback(
    (requestedData, managementPage, requestPage) => {
      const currentRequest = getCurrentRequest(requestedData);

      setRequestData({
        role: ROLES.ROLE_STUDENT,
        ...currentRequest
      });

      if (isAllDisapproved(requestedData) || !currentRequest) {
        return redirectToIdentify();
      }
      if (
        containsStatus(
          [REQUEST_STATUS.INCOMPLETE],
          currentRequest.requestStatus
        )
      ) {
        return history.push(requestPage);
      }

      return history.push(managementPage);
    },
    [setRequestData]
  );

  const handleRedirect = useCallback(
    (role, requestedData, managementPage, requestPage) => {
      const { totalElements, content } = requestedData;
      const firstRequest = content[0] || {};
      const { requestStatus, tutorEmail, email, stepNumber } = firstRequest;

      const isRoleGuardian = roleCheck.isGuardians(role);

      const identifyEmail = isRoleGuardian ? tutorEmail : email;

      setRequestData({
        ...firstRequest,
        role
      });

      const statusRequestApproved = containsStatus(
        [REQUEST_STATUS.APPROVED],
        requestStatus
      );

      const routeMap = {
        multipleElements: totalElements > 1,
        statusRequestPendingOrDisapproved: containsStatus(
          [REQUEST_STATUS.PENDING, REQUEST_STATUS.DISAPPROVED],
          requestStatus
        ),
        isStatusRequestApproved: statusRequestApproved,
        isGuardianWithApprove: isRoleGuardian && statusRequestApproved,
        termStep: stepNumber === REQUEST_STEP_TERMS,
        noEmail: !identifyEmail
      };

      const {
        multipleElements,
        statusRequestPendingOrDisapproved,
        isStatusRequestApproved,
        isGuardianWithApprove,
        termStep,
        noEmail
      } = routeMap;

      const redirectToManagement =
        multipleElements ||
        statusRequestPendingOrDisapproved ||
        isGuardianWithApprove;

      if (redirectToManagement) {
        return history.push(managementPage);
      }
      if (isStatusRequestApproved) {
        return history.push(INITIAL_PAGE_ROUTES.INITIAL_PAGE);
      }
      if (termStep) {
        return history.push(REQUEST_ACCESS_ROUTES.CONSENT_AGREEMENT);
      }
      if (noEmail) {
        return redirectToIdentify();
      }
      return history.push(requestPage);
    },
    [setRequestData]
  );

  const handleSelectProfile = useCallback(
    async selectedRole => {
      const requestedFunctions = requestFunctions({ selectedRole });
      const { fetchDataByRole, role, managementPage, requestPage } =
        requestedFunctions;

      setRequestFunctions(requestedFunctions.requestListFunctions);

      const requestedData = await fetchData(fetchDataByRole);

      if (roleCheck.isStudent(role)) {
        handleStudentRedirect(requestedData, managementPage, requestPage);
      } else {
        handleRedirect(role, requestedData, managementPage, requestPage);
      }
    },
    [fetchData, handleRedirect, handleStudentRedirect, setRequestFunctions]
  );

  useEffect(() => {
    setRequestData({});
    setIsEditing(false);
  }, [setIsEditing, setRequestData]);

  const profileMessages = {
    GUARDIAN: {
      altText: 'ALT_TXT.GUARDIAN',
      selectMessage: 'NAME_TXT.GUARDIAN_SELECT',
      registerMessage: 'NAME_TXT.GUARDIAN_REGISTER',
      registerProfile: 'REGISTER_GUARDIAN_PROFILE'
    },
    STUDENT: {
      altText: 'ALT_TXT.STUDENT',
      selectMessage: 'NAME_TXT.STUDENT_SELECT',
      registerMessage: 'NAME_TXT.STUDENT_REGISTER',
      registerProfile: 'REGISTER_STUDENT_PROFILE'
    },
    TEACHER: {
      altText: 'ALT_TXT.TEACHER',
      selectMessage: 'NAME_TXT.TEACHER_SELECT',
      registerMessage: 'NAME_TXT.TEACHER_REGISTER',
      registerProfile: 'REGISTER_TEACHER_PROFILE'
    }
  };

  useEffect(() => {
    setIsLoadingCard(false);
  }, []);

  return (
    <Container>
      <Grid
        item
        xs={12}
        sx={{
          marginBottom: 4,
          flexDirection: 'column'
        }}
      >
        {isLoadingCard ? (
          <SkeletonModels type={SKELETON_TYPES.TITLE_SKELETON} />
        ) : (
          <Typography
            variant="h4"
            textAlign="center"
            component="h1"
            role="heading"
          >
            {translate('SELECT_PROFILE_TITLE')}
          </Typography>
        )}
      </Grid>
      {isLoadingCard ? (
        <SkeletonModels type={SKELETON_TYPES.CARD_SKELETON} />
      ) : (
        <Wrapper container spacing={5} justifyContent="center" role="listitem">
          {ROLES_MAP?.map(profile => {
            const { role, name, description, image } = profile;
            const { altText, selectMessage, registerMessage, registerProfile } =
              profileMessages[name];
            const enableCard = requestAvailableOptions.includes(role);

            return (
              <Grid
                item
                key={name}
                xs={12}
                lg={4}
                display="flex"
                justifyContent="center"
                role="listitem"
              >
                <Card disabled={!enableCard}>
                  <StyledImage src={image} alt={translate(altText)} />
                  <TitleCard name={translate(selectMessage)}>
                    {translate(name)}
                  </TitleCard>
                  <DescriptionCard>{translate(description)}</DescriptionCard>
                  <StyledButton
                    disabled={isLoading || !enableCard}
                    onClick={() => handleSelectProfile(role)}
                    role="button"
                    name={translate(registerMessage)}
                    aria-label={translate(registerMessage)}
                    id="select-profile-button"
                  >
                    {translate(registerProfile)}
                  </StyledButton>
                </Card>
              </Grid>
            );
          })}
        </Wrapper>
      )}
    </Container>
  );
};

export default wrapper(SelectProfile, {
  namespace: 'SELECT_PROFILE',
  mapState: state => ({
    userGovBr: state.auth.userGovBr,
    userAccess: state.userAccess
  }),
  route: { path: REQUEST_ACCESS_ROUTES.SELECT_PROFILE },
  breadcrumb: 'SELECT_PROFILE'
});
