import React, { useCallback } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Button } from '@mui/material';

import history from 'framework/history';

import Checkbox from 'components/Checkbox';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from 'modules/RequestAccess/constants/routes';
import SECTION_TITLE from 'modules/RequestAccess/submodules/RequestAccessGuardian/constants/sectionTitle';

import { ROLES } from '../../../../../../helpers/roles';
import { useRequestAccessContext } from '../../../../Context';
import { SummaryBox } from '../../../RequestAccessTeacher/styles';
import Section from './Section';
import { ButtonWrapper, SummaryTitle } from './styles';

const SummaryInfo = () => {
  const translate = useLangContext();
  const formMethods = useForm();

  const { fnBack, fnSetCurrentStep } = useStepperContext();
  const { requestData, requestFunctions, setIsEditing, isEditing } =
    useRequestAccessContext();

  const { role } = requestData;

  const { editAllData, summary } = requestFunctions;

  const { redirect, sections, submitFn, steps } = summary;

  const { control } = formMethods;

  const infoIsCorrect = useWatch({
    control,
    name: 'acceptInfoIsCorrect'
  });

  const roleHasInitialPersonalInfo = role !== ROLES.ROLE_GUARDIAN;

  const handlePressEdit = sectionTitle => {
    setIsEditing(true);

    if (
      roleHasInitialPersonalInfo &&
      sectionTitle === SECTION_TITLE.PERSONAL_INFO
    ) {
      history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
    }
    fnSetCurrentStep(steps[sectionTitle]);
  };

  const handleRedirect = useCallback(() => {
    history.push(redirect);
  }, [redirect]);

  const handleSubmit = useCallback(async () => {
    try {
      const { id } = requestData;

      if (isEditing) {
        try {
          await editAllData({
            payload: requestData,
            id
          });

          setIsEditing(false);
          handleRedirect();
        } catch (error) {
          console.error(error);
        }
      }

      await submitFn({
        id
      });

      handleRedirect();
    } catch (error) {
      console.error(error);
    }
  }, [
    editAllData,
    handleRedirect,
    isEditing,
    requestData,
    setIsEditing,
    submitFn
  ]);

  return (
    <SummaryBox>
      <SummaryTitle>
        {role === ROLES.ROLE_STUDENT
          ? translate('SUMMARY.STUDENT_TITLE')
          : translate('SUMMARY.TITLE')}
      </SummaryTitle>

      {sections.map(section => (
        <Section
          key={section.title}
          section={section}
          handlePressEdit={() => handlePressEdit(section.title)}
          requestData={requestData}
        />
      ))}

      <Checkbox
        formMethods={formMethods}
        name="acceptInfoIsCorrect"
        label={translate('SUMMARY.INFO_IS_CORRECT')}
      />

      <ButtonWrapper>
        <Button variant="outlined" onClick={fnBack}>
          {translate('COMMONS:BACK')}
        </Button>
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={!infoIsCorrect}
        >
          {translate('COMMONS:DONE')}
        </Button>
      </ButtonWrapper>
    </SummaryBox>
  );
};

export default wrapper(SummaryInfo, { namespace: 'REQUEST_ACCESS_COMMONS' });
