import LiteraryDidacticWorksListTab from 'modules/App/LiteraryDidacticWorksList';
import DownloadWork from 'modules/DownloadWork';
import InitialPage from 'modules/InitialPage';
import LiteraryDidacticWorks from 'modules/LiteraryDidacticWorks';
import LiteraryDidacticWorksDetail from 'modules/LiteraryDidacticWorks/submodules/LiteraryDidacticWorksDetail';
import RequestAccess from 'modules/RequestAccess';
import RequestList from 'modules/RequestList';
import NewRequest from 'modules/RequestList/submodules/NewRequest';
import ResourcesWorks from 'modules/ResourcesWorks';
import StepPasswordBook from 'modules/StepPasswordBook';
import ConfirmUpdate from 'modules/UpdateRegister/submodules/ConfirmUpdate';
import Register from 'modules/UpdateRegister/submodules/UpdateRegister';

export const adminRouteProvider = {
  routes: [InitialPage],
  links: [],
  menus: []
};

export const teacherRouteProvider = {
  routes: [
    LiteraryDidacticWorks,
    LiteraryDidacticWorksListTab,
    LiteraryDidacticWorksDetail,
    RequestList,
    NewRequest,
    DownloadWork,
    Register,
    ConfirmUpdate,
    RequestAccess,
    ResourcesWorks
  ],
  links: [],
  menus: []
};

export const studentRouteProvider = {
  routes: [
    LiteraryDidacticWorks,
    LiteraryDidacticWorksListTab,
    LiteraryDidacticWorksDetail,
    DownloadWork,
    Register,
    ConfirmUpdate,
    RequestAccess
  ],
  links: [],
  menus: []
};

export const defaultRouteProvider = {
  routes: [RequestAccess, RequestAccess, StepPasswordBook],
  links: [],
  menus: []
};
