import { useMemo } from 'react';

import { createTheme } from '@mui/material/styles';

import { useThemeContext } from 'components/Theme';

export const useAppTheme = () => {
  const { type } = useThemeContext();

  return useMemo(() => {
    const isDark = type === 'dark';

    const paletteDark = {
      type: 'dark',
      primary: {
        main: '#fff',
        light: '#fff',
        dark: '#fff',
        contrastText: '#000'
      },
      secondary: {
        main: '#E9DEB4'
      },
      interactive: {
        main: '#d27723'
      },
      success: {
        main: '#168821',
        light: '#CDEDDD',
        dark: '#04620d',
        contrastText: '#fff'
      },
      error: { main: '#E60000', light: '#FBD9D8', contrastText: '#fff' },
      warning: {
        main: '#FFCD07',
        light: '#FEF7DA',
        dark: '#CC8422',
        contrastText: '#333333'
      },
      info: {
        main: '#155BCB',
        light: '#D4E5FF',
        dark: '#1d7e8b',
        contrastText: '#fff'
      },
      background: {
        copyright: '#000',
        default: '#000',
        light: '#000',
        white: '#000'
      },
      text: { primary: '#fff', light: '#fff' },
      border: { primary: '#fff', light: '#fff' },
      auxColors: {
        boxShadow: '#00000029',
        separator: '#707070',
        separatorHorizontal: '#D6D6D6'
      },
      focusVisible: {
        main: '#c226de'
      }
    };

    const paletteLight = {
      primary: {
        main: '#23415B',
        light: '#5992ed',
        dark: '#071d41',
        contrastText: '#fff'
      },
      secondary: {
        main: '#E9DEB4'
      },
      interactive: {
        main: '#d27723'
      },
      success: {
        main: '#168821',
        light: '#CDEDDD',
        dark: '#04620d',
        contrastText: '#fff'
      },
      error: { main: '#E60000', light: '#FBD9D8', contrastText: '#fff' },
      warning: {
        main: '#FFCD07',
        light: '#FEF7DA',
        dark: '#CC8422',
        contrastText: '#333333'
      },
      info: {
        main: '#155BCB',
        light: '#D4E5FF',
        dark: '#1d7e8b',
        contrastText: '#fff'
      },
      background: {
        copyright: '#071D41',
        default: '#E5E5E5',
        light: '#fafafa',
        white: '#ffffff',
        blue: '#DBE8FB'
      },
      text: {
        primary: '#333',
        light: '#DDE1E5',
        dark: '#000',
        gray: '#555555'
      },
      border: { primary: '#fafafa', light: '#CCCCCC' },
      auxColors: {
        boxShadow: '#00000029',
        separator: '#707070',
        separatorHorizontal: '#D6D6D6'
      },
      focusVisible: {
        main: '#c226de'
      }
    };

    const getFocusVisibleStyle = theme => ({
      '&.Mui-focusVisible': {
        outline: `2px solid ${theme.palette.focusVisible.main}`
      }
    });

    const baseTheme = createTheme({
      palette: isDark ? paletteDark : paletteLight,
      typography: {
        fontFamily: 'Rawline'
      }
    });

    const theme = createTheme(baseTheme, {
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              borderRadius: 24,
              ...getFocusVisibleStyle(baseTheme)
            }
          }
        },
        MuiInputLabel: {
          styleOverrides: {
            outlined: {
              fontSize: '14px',
              transform: 'none',
              color: baseTheme.palette.text.dark,
              fontFamily: 'Rawline',
              fontWeight: 600
            }
          }
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: baseTheme.palette.primary.main,
              '&.MuiIconButton-colorPrimary': {
                background: baseTheme.palette.primary.main,
                color: baseTheme.palette.primary.contrastText,
                '&:hover': {
                  background: baseTheme.palette.primary.dark
                }
              },
              ...getFocusVisibleStyle(baseTheme)
            },
            secondary: {
              backgroundColor: baseTheme.palette.secondary.main,
              '&:hover': {
                backgroundColor: baseTheme.palette.secondary.main
              }
            }
          }
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              '&.MuiTableCell-head': {
                background: baseTheme.palette.background.default,
                color: baseTheme.palette.primary.main
              },
              '&.MuiTableCell-body': {
                background: baseTheme.palette.background.light
              },
              ...getFocusVisibleStyle(baseTheme)
            }
          }
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              ...getFocusVisibleStyle(baseTheme)
            }
          }
        }
      },
      typography: {
        button: {
          fontSize: 14,
          textTransform: 'capitalize'
        },
        h4: {
          color: baseTheme.palette.text.primary,
          marginBottom: 24
        },
        h5: {
          marginBottom: 12,
          color: baseTheme.palette.text.primary
        },
        p: {
          color: baseTheme.palette.text.primary
        }
      }
    });

    return theme;
  }, [type]);
};
