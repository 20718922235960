export const REQUEST_ACCESS = {
  GET_CHECK_TEACHER_ACCESS_REQUEST:
    '/educational-network-teacher-request/validate/teacher-cpf/{cpf}/educational-institution/{inep}/learning-level/{learningLevel}',
  GET_STATE_LIST: '/address/state',
  GET_CITY_LIST: '/address/city/{initials}',
  GET_SCHOOL_LIST: '/educational-institution/{ibge}',
  GET_LEARNING_LEVEL_LIST: '/educational-institution/{inep}/learning-level',
  POST_TEACHER_REQUEST: '/educational-network-teacher-request',
  POST_SELECT_PROFILE: '/student-educational-institution',
  REGISTER_TERMS_OF_USE_UPDATED: '/register-terms-of-use',
  GET_LEARNING_PHASE_LIST: '/educational-institution/{inep}/learning-phase',
  CONFIGURE_USER_ACCESS: '/request-access/allowed-roles'
};

export const TEACHER_REQUEST_ACCESS = {
  TEACHER_REQUEST_ACCESS: '/request-access-teacher',
  POST_TEACHER_REQUEST_ACCESS: '/request-access-teacher',
  PUT_TEACHER_REQUEST_ACCESS: '/request-access-teacher/{id}',
  PUT_TEACHER_REQUEST_ACCESS_FINISH:
    '/request-access-teacher/{id}/finish-request',
  DELETE_TEACHER_REQUEST_ACCESS: '/request-access-teacher/{id}',
  PUT_TEACHER_REQUEST_ACCESS_EMAIL:
    '/request-access-teacher/{id}/email/{email}',
  PUT_TEACHER_REQUEST_ACCESS_LOCALITY:
    '/request-access-teacher/{id}/locality/{cityId}',
  PUT_TEACHER_REQUEST_ACCESS_SCHOOL:
    '/request-access-teacher/{id}/institution/{inep}',
  PUT_TEACHER_REQUEST_ACCESS_LEARNING_PHASE:
    '/request-access-teacher/{id}/learning-level/{learningPhaseId}'
};

export const GUARDIANS_REQUEST_ACCESS = {
  GET_GUARDIANS_REQUEST_ACCESS: '/request-access-student/tutor-requests',
  POST_GUARDIANS_REQUEST_ACCESS: '/request-access-student/tutor-request',
  PUT_GUARDIANS_REQUEST_ACCESS_EDIT_ALL_DATA:
    '/request-access-student/tutor-request/{id}',
  PUT_GUARDIANS_REQUEST_ACCESS:
    '/request-access-student/tutor-request/{id}/student-informations',
  PUT_GUARDIANS_REQUEST_ACCESS_EMAIL:
    '/request-access-student/tutor-request/{id}/tutor-email/{email}',
  GET_DEGREE_OF_KINSHIP_LIST:
    '/request-access-student/tutor/degree-of-kinship/list',
  GET_DISABILITY_LIST: '/request-access-student/tutor/physical-disability/list',
  PUT_GUARDIANS_REQUEST_ACCESS_LEARNING_PHASE:
    '/request-access-student/tutor-request/{id}/learning-phase/{learningPhaseId}',
  PUT_GUARDIANS_REQUEST_ACCESS_CONSENT_TERMS:
    '/request-access-student/tutor-request/{id}/finish-request',
  DELETE_GUARDIANS_REQUEST_ACCESS: '/request-access-student/{id}',
  PUT_GUARDIANS_REQUEST_ACCESS_VERIFY_INFORMATION:
    '/request-access-student/tutor-request/{id}/verify-information'
};

export const STUDENT_REQUEST_ACCESS = {
  PUT_STUDENT_REQUEST_ACCESS_LOCALITY:
    '/request-access-student/{id}/locality/{cityId}',
  PUT_STUDENT_REQUEST_ACCESS_SCHOOL:
    '/request-access-student/{id}/institution/{inep}',
  PUT_STUDENT_REQUEST_ACCESS_LEARNING_PHASE:
    '/request-access-student/{id}/learning-phase/{learningPhaseId}',
  PUT_STUDENT_REQUEST_ACCESS_EDIT_ALL_DATA: '/request-access-student/{id}',
  PUT_STUDENT_REQUEST_ACCESS_FINISH:
    '/request-access-student/{id}/finish-request',
  STUDENT_REQUEST_ACCESS: '/request-access-student',
  EDIT_STUDENT_REQUEST_ACCESS:
    '/request-access-student/{id}/student-informations'
};
