import styled from 'styled-components';

import { FormLabel, Grid, Typography } from '@mui/material';

import { StyledBox } from 'modules/RequestAccess/submodules/Steps/fragments/styles';

export const StyledBoxWithOpacity = styled(StyledBox)`
  opacity: ${props => (props.disabled ? 0.45 : 1)};
  width: 462px;
`;

export const BaloonDetailContainer = styled.div`
  width: 20px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BaloonDetail = styled.div`
  width: 14px;
  height: 14px;
  transform: rotate(45deg);
  margin-left: 16px;
  margin-right: -8px;
  background-color: #dbe8fb;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    margin-bottom: -8px;
  }
`;

export const InfoImage = styled.img`
  width: 128px;
  height: 128px;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    width: 74px;
    height: 74px;
    margin-bottom: 4px;
  }
`;

export const StyledFormLabel = styled(FormLabel)`
  font-weight: 600;
  font-size: 14px;
  color: #000000;
`;

export const StyledFormTitle = styled(Typography)`
  font-weight: 600 !important;
  font-size: 16px !important;
`;

export const StyledFormSubtitle = styled(Typography)`
  font-weight: 400;
  font-size: 16px;
  color: #555;
`;

export const StyledGrid = styled(Grid)`
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    flex-direction: column !important;
  }

  @media (min-width: ${props => props.theme.breakpoints.values.sm}px) {
    flex-direction: row !important;
  }
`;
