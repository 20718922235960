import styled from 'styled-components';

import { Cancel } from '@mui/icons-material';

export const ErrorHelperText = styled.div`
  display: inline-flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.contrastText};
  font-size: 0.875rem;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.palette.error.main};
`;

export const StyledErrorIcon = styled(Cancel)`
  &.MuiSvgIcon-root {
    font-size: 1rem;
    margin-right: 4px;
  }
`;
