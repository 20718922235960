import React from 'react';

import { List } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import {
  MenuContainer,
  MenuItem,
  MenuLink,
  StyledDrawer,
  StyledListItem
} from './style';

const PrivateMenu = ({ show, menus, close, headerHeight }) => {
  const translate = useLangContext();

  if (!show) return null;
  if (!menus || menus.length === 0) return null;

  return (
    <StyledDrawer
      open={show}
      anchor="left"
      onClose={close}
      headerHeight={headerHeight}
      BackdropProps={{
        sx: { backgroundColor: 'transparent' }
      }}
    >
      <MenuContainer onClick={close} onKeyDown={close}>
        <List
          sx={{
            paddingTop: 0
          }}
        >
          {menus.map((menu, idx) => {
            const key = `menu-item-${idx}`;
            return (
              <StyledListItem button key={key}>
                <MenuLink to={menu.to}>
                  <MenuItem primary={translate(menu.label)} />
                </MenuLink>
              </StyledListItem>
            );
          })}
        </List>
      </MenuContainer>
    </StyledDrawer>
  );
};

export default wrapper(PrivateMenu, {
  namespace: 'PRIVATE_MENU'
});
