import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { Redirect } from 'react-router-dom';

import { useQueryParams } from 'hooks/useQueryParams';

import { Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import PublicContainer from 'components/PublicContainer';
import wrapper from 'components/Wrapper';

import { ALTERNATIVE_LOGIN } from 'modules/Auth/constants/alternativeLogin';
import { GOVBR } from 'modules/Auth/constants/govbr';
import { AUTH_ROUTES } from 'modules/Auth/constants/routes';
import { DOWNLOAD_WORK_ROUTES } from 'modules/DownloadWork/constants/routes';

import LogoComplete from 'assets/img/logo-completo.png';
import LogoFnde from 'assets/img/logo-fnde.svg';

import { authActions } from '../../../../framework/store/slices/auth.slice';
import store from '../../../../framework/store/store';
import { authService } from '../../../../services/providers/auth';
import { INITIAL_PAGE_ROUTES } from '../../../InitialPage/constants/routes';
import PrivacyPolicy from './fragments/PrivacyPolicy';
import {
  ButtonAccess,
  LeftContainer,
  RightContainer,
  StyledGrid,
  MainTitle,
  WrapperTitle,
  StyledText
} from './style';

import './style.css';

const Login = ({ isAuthenticated }) => {
  const translate = useLangContext();

  const query = useQueryParams();

  const [cookies, setCookies] = useCookies(['privacyPolicy']);
  const [hidePrivacyPolicy, setHidePrivacyPolicy] = useState(
    !cookies.privacyPolicy
  );

  const isAlternativeLoginEnabled =
    window.env.REACT_APP_LOGIN_ALTERNATIVO_ENABLED.toLowerCase() === 'true';

  const redirectUrl = `${GOVBR.LOGIN_URL}&state=${
    query.redirectTo
      ? btoa(JSON.stringify({ redirectTo: query.redirectTo }))
      : ''
  }`;

  const govBrLogin = () => {
    authService.setIsWaitingGovBrAuthLogout();
    store.dispatch(authActions.isWaitingGovBrLogout(true));

    window.location.href = redirectUrl;
  };

  const alternativeLogin = () => {
    authService.setIsWaitingGovBrAuthLogout();
    store.dispatch(authActions.isWaitingGovBrLogout(true));

    const url = ALTERNATIVE_LOGIN.LOGIN_URL;

    window.location.href = url;
  };

  const onCloseConfirmPrivacyPolicy = () => {
    setCookies('privacyPolicy', true, { path: '/' });
    setHidePrivacyPolicy(false);
  };

  if (isAuthenticated) {
    return (
      <Redirect
        to={
          redirectUrl
            ? DOWNLOAD_WORK_ROUTES.DOWNLOAD_WORK
            : INITIAL_PAGE_ROUTES.INITIAL_PAGE
        }
      />
    );
  }

  return (
    <PublicContainer>
      <StyledGrid container hidePrivacyPolicy={hidePrivacyPolicy}>
        <Grid item xs={12} lg={6}>
          <LeftContainer>
            <WrapperTitle>
              <img src={LogoFnde} alt="FNDE" />
              <MainTitle>{translate('DISCLAIMER')}</MainTitle>
            </WrapperTitle>
          </LeftContainer>
        </Grid>
        <Grid item container xs={12} lg={6}>
          <RightContainer>
            <img src={LogoComplete} alt={translate('PRIVATE_HEADER:TITLE')} />
            <StyledText variant="h4" component="h2">
              {translate('WELCOME')}
            </StyledText>
            <ButtonAccess
              variant="contained"
              onClick={govBrLogin}
              id="loginButton"
            >
              {translate('SIGNIN')}
            </ButtonAccess>
            {isAlternativeLoginEnabled && (
              <ButtonAccess
                variant="contained"
                onClick={alternativeLogin}
                id="alternativeLoginButton"
                mt="16"
              >
                {translate('ALTERNATIVE_LOGIN')}
              </ButtonAccess>
            )}
          </RightContainer>
        </Grid>
      </StyledGrid>
      <PrivacyPolicy
        hide={hidePrivacyPolicy}
        close={onCloseConfirmPrivacyPolicy}
      />
    </PublicContainer>
  );
};

export default wrapper(Login, {
  namespace: 'LOGIN',
  mapState: state => ({
    isAuthenticated: state.auth.isAuthenticated
  }),
  route: {
    path: AUTH_ROUTES.LOGIN
  }
});
