import React from 'react';

import { useStepper } from 'hooks/useStepper';

import { Grid } from '@mui/material';

import wrapper from 'components/Wrapper';

import EditWarning from 'modules/RequestAccess/fragments/EditWarning';

import { REQUEST_ACCESS_ROUTES } from '../../constants/routes';
import { useRequestAccessContext } from '../../Context';
import EducationUnit from '../Steps/fragments/EducationUnit';
import LearningLevel from '../Steps/fragments/LearningLevel';
import Locality from '../Steps/fragments/Locality';
import Summary from '../Steps/fragments/SummaryInformation';

const stepPages = [
  {
    title: 'REQUEST_ACCESS_COMMONS:STEPS.LOCALITY',
    Content: Locality
  },
  {
    title: 'REQUEST_ACCESS_COMMONS:STEPS.EDUCATION_UNIT',
    Content: EducationUnit
  },

  {
    title: 'REQUEST_ACCESS_COMMONS:STEPS.LEARNING_PHASE',
    Content: LearningLevel
  },
  {
    title: 'REQUEST_ACCESS_COMMONS:STEPS.FINISH',
    Content: Summary
  }
];

const RequestAccessTeacher = () => {
  const { requestData, isEditing, redirectIfRoleIsUndefined } =
    useRequestAccessContext();
  const { stepNumber = 1, role } = requestData || {};

  const [Stepper] = useStepper({
    stepPages,
    initialStep: stepNumber - 1
  });

  redirectIfRoleIsUndefined(role);

  return (
    <Grid container spacing={3} py={3}>
      <Grid item xs={12}>
        <Grid justifyContent="center" flexDirection="column">
          <EditWarning show={isEditing} />
          <Stepper />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default wrapper(RequestAccessTeacher, {
  route: { path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_TEACHER }
});
