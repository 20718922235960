import styled from 'styled-components';

import { Box } from '@mui/material';

export const StyledBox = styled(Box)`
  max-width: 600px;
  margin: 50px auto;
  padding: 40px;
  border-radius: 5px;
  background: ${props => props.theme.palette.background.white};
  box-shadow: 0px 3px 6px ${props => props.theme.palette.auxColors.boxShadow};
`;
