import styled from 'styled-components';

import { Typography } from '@mui/material';

import backgroundCard from 'assets/img/background-card.png';

const customBreakpoint = '1660px';

export const CardDesktopTitle = styled(Typography)`
  &.MuiTypography-root {
    color: ${props => props.theme.palette.primary.main};
    font-weight: bold;
    ${props => props.theme.breakpoints.down('md')} {
      display: none;
    }
    ${props => props.theme.breakpoints.up('sm')} {
      margin-bottom: 14px;
    }
  }
`;

export const CardMobileTitle = styled(Typography)`
  &.MuiTypography-root {
    width: 100%;
    color: ${props => props.theme.palette.primary.main};
    font-weight: bold;
    margin-top: 16px;
    ${props => props.theme.breakpoints.down('md')} {
      text-align: center;
      margin-bottom: 23px;
      font-weight: 600;
    }
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background: url(${backgroundCard}) center / cover;
  border-radius: 8px;
  align-items: center;
  padding: 0 0 0 40px;
  height: 160px;
  @media (max-width: ${customBreakpoint}) {
    padding: 20px 0 20px 20px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    height: 230px;
    background-position: 65%;
    flex-direction: column;
    padding: 0 16px;
  }
`;

export const DownloadAppContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  @media (max-width: ${customBreakpoint}) {
    gap: 20px;
  }
  ${props => props.theme.breakpoints.down('md')} {
    flex-direction: column;
    gap: 16px;
    align-items: flex-start;
    margin-bottom: 24px;
  }
`;

export const DownloadAppImage = styled.img`
  width: 95.42px;
  height: 32px;
  @media (max-width: ${customBreakpoint}) {
    width: 85px;
    height: auto;
  }
  ${props => props.theme.breakpoints.down('md')} {
    width: 119.27px;
    height: 40px;
  }
`;

export const QrCodeImage = styled.img`
  width: 55px;
  height: 55px;
  @media (max-width: ${customBreakpoint}) {
    width: 45px;
    height: auto;
  }
  ${props => props.theme.breakpoints.down('lg')} {
    margin-left: 15px;
  }
`;

export const SmartphoneImage = styled.img`
  margin-top: -24px;
  @media (max-width: ${customBreakpoint}) {
    margin-left: ${props => props.ml || '-50px'};
  }
`;

export const SmartphoneMobileImage = styled.img``;
