import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import { configureStore } from '@reduxjs/toolkit';

import { isDevelopment } from 'helpers/environment';

import reducer from './root.reducer';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(thunk)
});

if (isDevelopment() && module.hot) {
  module.hot.accept('framework/store/root.reducer', () => {
    const newRootReducer = require('framework/store/root.reducer').default;
    store.replaceReducer(newRootReducer);
  });
}
export const persistor = persistStore(store);

export default store;
