import React, { useCallback, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { MASKS } from 'constants/masks';
import { formatCPF } from 'utils/textFormatUtils';

import { yupResolver } from '@hookform/resolvers/yup';
import { Paper, Grid, Box, Typography } from '@mui/material';

import history from 'framework/history';
import { ROLES } from 'helpers/roles';

import { ConfirmDialog } from 'components/Dialog';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import MaskedTextField from 'components/MaskedTextField';
import TextField from 'components/TextField';

import { notificationUseCases } from 'modules/Notification/providers';

import {
  REQUEST_ACCESS_ROUTES,
  REQUEST_ACCESS_STUDENT_ROUTES
} from '../../../../../../constants/routes';
import { useRequestAccessContext } from '../../../../../../Context';
import { FormTitle, StyledActionButton } from '../../../../../../styles';
import { schema } from './schema';
import StudentForm from './StudentForm';

const IdentifyForm = ({ user }) => {
  const translate = useLangContext();
  const { requestData, setRequestData, requestFunctions } =
    useRequestAccessContext();
  const [openModal, setOpenModal] = useState(false);

  const { cpf, name } = user;

  const { email, id, tutorCpf, tutorEmail, role, physicalDisability } =
    requestData || {};

  const { create, redirect } = requestFunctions;

  const isGuardian = role === ROLES.ROLE_GUARDIAN;
  const isStudent = role === ROLES.ROLE_STUDENT;

  const redirectToSelectProfile = () => {
    history.push(REQUEST_ACCESS_ROUTES.SELECT_PROFILE);
  };

  const formMethods = useForm({
    resolver: yupResolver(schema),
    context: { isStudent },
    defaultValues: {
      ...requestData,
      physicalDisability: physicalDisability || [],
      cpf: formatCPF(cpf || tutorCpf),
      email: isGuardian ? tutorEmail : email
    }
  });

  const {
    control,
    formState: { isDirty }
  } = formMethods;

  const handleCancel = () => {
    redirectToSelectProfile();
  };

  const handleCreate = useCallback(
    async formData => {
      const { email: emailFormData } = formData;

      const postPayload = isGuardian
        ? {
            tutorCpf: cpf,
            tutorEmail: emailFormData
          }
        : { ...formData, cpf, name, email: emailFormData };

      const resp = await create(postPayload);
      return resp;
    },
    [isGuardian, cpf, name, create]
  );

  const handleEdit = useCallback(
    async formData => {
      const {
        email: emailFormData,
        hasPhysicalDisability,
        physicalDisability: physicalDisabilityFormData,
        ...rest
      } = formData;

      const customFields = {
        email: emailFormData,
        hasPhysicalDisability,
        physicalDisability: hasPhysicalDisability
          ? physicalDisabilityFormData
          : []
      };

      const payload = {
        ...requestData,
        ...rest,
        ...customFields
      };

      return payload;
    },
    [requestData]
  );

  const redirectNotAllowed = useCallback(() => {
    history.push(REQUEST_ACCESS_STUDENT_ROUTES.NOT_ALLOWED);
  }, []);

  const handleErrorMessage = useMemo(() => {
    const errorActions = {
      'student.request.already.started': () => setOpenModal(true),
      'student.is.under.twelve': () => redirectNotAllowed(),
      default: () => notificationUseCases.error('ERROR:DEFAULT_ERROR')
    };

    return errorCode => {
      const action = errorActions[errorCode] || errorActions.default;
      action();
    };
  }, [setOpenModal, redirectNotAllowed]);

  const handleSubmit = useCallback(
    async formData => {
      if (isDirty) {
        try {
          const resp = !id
            ? await handleCreate(formData)
            : await handleEdit(formData);
          setRequestData({ ...resp, role });
          history.push(redirect);
        } catch (error) {
          handleErrorMessage(error.response?.data?.message);
        }
      } else {
        history.push(redirect);
      }
    },
    [
      handleCreate,
      handleEdit,
      handleErrorMessage,
      id,
      isDirty,
      redirect,
      role,
      setRequestData
    ]
  );

  const handleConfirmDialog = useCallback(() => {
    setOpenModal(false);
    redirectToSelectProfile();
  }, []);

  const emailWatch = useWatch({
    control,
    name: 'email'
  });

  const arialLabel = translate(
    isStudent ? 'COMMONS:ACCESSIBILITY_GO_FORWARD' : 'COMMONS:GO_FORWARD'
  );

  return (
    <Box mt="5%" mb="5%">
      <Grid
        item
        xs={12}
        container
        justifyContent="center"
        alignItems="center"
        name="form"
      >
        <ConfirmDialog open={openModal} onConfirm={handleConfirmDialog}>
          <Box mb={1}>
            <Typography
              variant="h5"
              name={translate(
                'REQUEST_ACCESS_COMMONS:STUDENT_REQUEST_ALREADY_STARTED'
              )}
            >
              {translate(
                'REQUEST_ACCESS_COMMONS:STUDENT_REQUEST_ALREADY_STARTED'
              )}
            </Typography>
          </Box>
        </ConfirmDialog>

        <Paper style={{ width: 504, padding: 32 }}>
          <Box mb={5}>
            <FormTitle
              variant="h4"
              gutterBottom
              component="h1"
              fontSize="20px"
              name={translate('FORM_TITLE')}
              role="list"
            >
              {translate('FORM_TITLE')}
            </FormTitle>
          </Box>
          <Form formMethods={formMethods} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <MaskedTextField
                  name="cpf"
                  label={translate('COMMONS:CPF')}
                  formMethods={formMethods}
                  fullWidth
                  placeholder="000.000.000-00"
                  mask={MASKS.CPF}
                  isDisabled
                  role="textbox"
                  props="aria-required"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="email"
                  label={translate('COMMONS:EMAIL')}
                  placeholder={translate(
                    'REQUEST_ACCESS_COMMONS:EMAIL_PLACEHOLDER'
                  )}
                  fullWidth
                  formMethods={formMethods}
                  role="textbox"
                  props="aria-required"
                  aria-label={translate('RESET_PASSWORD:LINK_EMAIL')}
                />
              </Grid>
              {isStudent && <StudentForm formMethods={formMethods} />}
            </Grid>
            <Grid mt="32px" container justifyContent="space-between">
              <Box mr={1}>
                <StyledActionButton
                  variant="outlined"
                  onClick={handleCancel}
                  aria-label={translate(
                    'COMMONS:ACCESSIBILITY_CANCEL_ACCESS_REQUIREMENT'
                  )}
                >
                  {translate('COMMONS:CANCEL')}
                </StyledActionButton>
              </Box>
              <StyledActionButton
                variant="contained"
                color="primary"
                type="submit"
                disabled={!emailWatch}
                aria-label={arialLabel}
              >
                {translate('COMMONS:GO_FORWARD')}
              </StyledActionButton>
            </Grid>
          </Form>
        </Paper>
      </Grid>
    </Box>
  );
};

export default IdentifyForm;
