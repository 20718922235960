import styled from 'styled-components';

import { Box, Grid, TableCell, TableRow, Typography } from '@mui/material';

export const Title = styled(Typography)`
  &.MuiTypography-root {
    font-size: 34px;
    text-align: left;
    line-height: 40px;

    ${props => props.theme.breakpoints.down('md')} {
      font-size: 28px;
      line-height: 30px;
      width: 100%;
    }
  }
`;

export const Tag = styled.div`
  background-color: #e5a000;
  color: ${props => props.theme.palette.primary.contrastText};
  border-radius: 4px;
  padding: 4px;
  width: 100px;
`;

export const StyledBox = styled(Box)`
  ${props => props.theme.breakpoints.down('md')} {
    justify-content: left;
    margin-bottom: 10px;
  }
  ${props => props.theme.breakpoints.up('md')} {
    justify-content: right;
    margin-bottom: 24px;
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 24px 16px;
    ${props => props.theme.breakpoints.down('md')} {
      &.MuiTableCell-root {
        border: none;
        padding: 4px;
        display: flex;
        order: ${props => props.order || 0};
      }
    }
  }
`;

export const StyledTableRow = styled(TableRow)`
  ${props => props.theme.breakpoints.down('md')} {
    &.MuiTableRow-root {
      display: flex;
      margin-bottom: 48px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
      padding: 8px;
    }
  }
`;

export const MobileGrid = styled(Grid)`
  &.MuiGrid-root {
    width: auto;
  }
`;
