import * as Yup from 'yup';

function isValidISBN(str) {
  let sum = 0;
  let digit;
  let check;
  str = str.replace(/[^0-9X]/gi, '');

  if (str.length === 0) {
    return true;
  }

  const characters = new Set(Array.from(str));
  if (characters.size === 1) {
    return false;
  }

  if (str.length === 13) {
    for (let i = 0; i < 12; i += 1) {
      digit = parseInt(str[i], 10);
      if (i % 2 === 1) {
        sum += 3 * digit;
      } else {
        sum += digit;
      }
    }
    check = (10 - (sum % 10)) % 10;
    return String(check) === str[str.length - 1];
  }

  if (str.length === 10) {
    let weight = 10;
    for (let i = 0; i < 9; i += 1) {
      digit = parseInt(str[i], 10);
      sum += weight * digit;
      weight -= 1;
    }
    check = (11 - (sum % 11)) % 11;
    if (check === 10) {
      check = 'X';
    }

    return String(check) === str[str.length - 1].toUpperCase();
  }

  return false;
}

Yup.addMethod(
  Yup.string,
  'ISBN',
  function validateISBN(errorMessage = 'VALIDATION:INVALID_ISBN') {
    return this.test('ISBN', errorMessage, function testISBN(isbn) {
      const { path, createError } = this;
      return isValidISBN(isbn) || createError({ path, message: errorMessage });
    });
  }
);
