import React, { useCallback, useState, useMemo, useEffect } from 'react';

import { Container, Stepper, Step, StepLabel } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import { StepperContext } from './StepperContext';

const defaultStepPages = [
  {
    title: 'Title 1',
    Content: <> Content page title 1</>
  },
  {
    title: 'Title 2',
    Content: <> Content page title 2</>
  }
];

export const useStepper = ({
  stepPages = defaultStepPages,
  defaultValue,
  initialStep = 0,
  onFinish = () => {},
  asyncData
}) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [stepperData, setStepperData] = useState(defaultValue || {});
  const [stepOverflow, setStepOverflow] = useState(false);

  const translate = useLangContext();

  const handleReset = useCallback(() => {
    setActiveStep(0);
    setStepperData(defaultValue || {});
  }, [defaultValue]);

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => {
      const nextStep =
        prevActiveStep + 1 <= stepPages.length - 1
          ? prevActiveStep + 1
          : prevActiveStep;

      if (nextStep === prevActiveStep) {
        setStepOverflow(true);
      }

      return nextStep;
    });
  }, [stepPages.length]);

  useEffect(() => {
    if (stepOverflow) {
      onFinish(stepperData, handleReset);
      setStepOverflow(false);
    }
  }, [handleReset, onFinish, stepOverflow, stepperData]);

  const handleBack = () => {
    setActiveStep(prevActiveStep =>
      prevActiveStep - 1 >= 0 ? prevActiveStep - 1 : prevActiveStep
    );
  };

  const handleManagerData = useCallback((key, formData) => {
    setStepperData(prev => ({ ...prev, [key]: formData }));
  }, []);

  const ActiveStep = useMemo(() => {
    const { Content = null } = stepPages[activeStep];
    return Content;
  }, [activeStep, stepPages]);

  useEffect(() => {
    if (asyncData) setStepperData(defaultValue);
  }, [asyncData, defaultValue]);

  useEffect(() => {
    if (initialStep) setActiveStep(initialStep);
  }, [initialStep]);

  const StepperContainer = useCallback(
    stepProps => (
      <StepperContext.Provider
        value={{
          fnNext: handleNext,
          fnBack: handleBack,
          fnReset: handleReset,
          stepperData,
          fnSetData: setStepperData,
          fnSetDataByKey: handleManagerData,
          fnSetCurrentStep: setActiveStep,
          activeStep
        }}
      >
        <Container>
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{ background: 'none' }}
          >
            {stepPages.map(item => (
              <Step key={item.title}>
                <StepLabel>{translate(item.title)}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <ActiveStep {...stepProps} />
        </Container>
      </StepperContext.Provider>
    ),
    [
      activeStep,
      handleManagerData,
      handleNext,
      handleReset,
      stepPages,
      stepperData,
      translate
    ]
  );

  return [StepperContainer, stepperData, setStepperData];
};
