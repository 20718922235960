import React from 'react';

import PropTypes from 'prop-types';

import { SKELETON_TYPES } from 'helpers/constants/skeletonTypes';

import ButtonSkeleton from './ButtonSkeleton';
import CardGroupSkeleton from './CardGroupSkeleton';
import TableSkeleton from './TableSkeleton';
import TitleSkeleton from './TitleSkeleton';

const SKELETONS = {
  [SKELETON_TYPES.TABLE_SKELETON]: <TableSkeleton />,
  [SKELETON_TYPES.TITLE_SKELETON]: <TitleSkeleton />,
  [SKELETON_TYPES.CARD_SKELETON]: <CardGroupSkeleton />,
  [SKELETON_TYPES.BUTTON_SKELETON]: <ButtonSkeleton />
};

const SkeletonModels = ({ type }) => SKELETONS[type];

SkeletonModels.propTypes = {
  type: PropTypes.oneOf(Object.values(SKELETON_TYPES)).isRequired
};

export default SkeletonModels;
