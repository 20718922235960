import React from 'react';
import { Trans } from 'react-i18next';

import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Typography
} from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import {
  StyledTitle,
  ThickDivider
} from 'modules/LiteraryDidacticWorks/styles';

import { RESOURCES_WORKS_ROUTES } from './constants/routes';
import { educationResources } from './fragments/educationResources';
import {
  List,
  StyledDivider,
  StyledOpenInNewIcon,
  StyledStack
} from './styles';

const ResourcesWorks = () => {
  const translate = useLangContext();

  return (
    <Container maxWidth="xl">
      <Box mb="32px">
        <StyledTitle component="h1">
          {translate('LITERARY_DIDACTIC_WORKS:TITLE_RESOURCES_WORKS')}
        </StyledTitle>
        <ThickDivider />
      </Box>
      <Grid container>
        <Box marginTop={2} marginBottom={2}>
          <Trans
            i18nKey="LITERARY_DIDACTIC_WORKS:SUBTITLE_RESOURCES"
            t={translate}
          />
        </Box>
        <Grid container spacing={2}>
          {educationResources.map(item => (
            <Grid item xs={12} md={3}>
              <Paper elevation={3}>
                <StyledStack direction="row" spacing={1}>
                  <Typography variant="h6" fontWeight="bold">
                    {translate(item.title)}
                  </Typography>
                  <Typography variant="body2">
                    {translate(item.subtitle)}
                  </Typography>
                </StyledStack>
                {item.submenu.map(submenu => (
                  <>
                    <List component="a" href={submenu.link}>
                      <Typography variant="body2">
                        {translate(submenu.title)}
                      </Typography>
                      <IconButton>
                        <StyledOpenInNewIcon />
                      </IconButton>
                    </List>
                    <StyledDivider />
                  </>
                ))}
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default wrapper(ResourcesWorks, {
  namespace: 'RESOURCES_WORKS',
  route: {
    path: RESOURCES_WORKS_ROUTES.RESOURCES_WORKS,
    breadcrumb: 'RESOURCES_WORKS'
  }
});
