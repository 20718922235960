import React from 'react';
import { Trans } from 'react-i18next';

import { Box, Container, Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_STUDENT_ROUTES } from 'modules/RequestAccess/constants/routes';

import notAllowedImg from 'assets/img/not-allowed.png';

import {
  CustomImage,
  RequestCompletedDescription,
  RequestCompletedTitle
} from './styles';

const RequestNotAllowed = () => {
  const translate = useLangContext();

  return (
    <Container maxWidth="md">
      <Box mt={3}>
        <Grid
          container
          spacing={4}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
        >
          <Grid item xs={12} md={3}>
            <Box maxWidth={100} display="block" alignContent="center">
              <CustomImage src={notAllowedImg} alt="" />
            </Box>
          </Grid>
          <Grid item xs={12} md={9}>
            <RequestCompletedTitle>{translate('TITLE')}</RequestCompletedTitle>
            <RequestCompletedDescription>
              <Trans
                t={translate}
                i18nKey="SUBTITLE"
                components={{
                  br: <br />
                }}
              />
            </RequestCompletedDescription>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default wrapper(RequestNotAllowed, {
  namespace: 'REQUEST_NOT_ALLOWED',
  route: { path: REQUEST_ACCESS_STUDENT_ROUTES.NOT_ALLOWED }
});
