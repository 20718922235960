import React from 'react';

import { Box, Grid, List } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import linux from 'assets/img/logo-linux.svg';
import windows from 'assets/img/logo-windows.svg';
import desktop from 'assets/img/notebook_initial.png';

import { CardContainer, CardDesktopTitle, SmartphoneImage } from '../styles';

export const operatingSystem = [
  {
    title: 'Windows',
    image: windows,
    link: 'https://pnld-lip.s3.sa-east-1.amazonaws.com/leitor-interativo-pnld.exe'
  },
  {
    title: 'Linux',
    image: linux,
    link: 'https://pnld-lip.s3.sa-east-1.amazonaws.com/leitor-interativo-pnld.AppImage'
  }
];

const DownloadDesktop = () => {
  const translate = useLangContext();
  return (
    <CardContainer>
      <Grid container>
        <Grid item sm={12}>
          <CardDesktopTitle>{translate('DESKTOP.CARD_TITLE')}</CardDesktopTitle>
        </Grid>
        {operatingSystem.map(item => (
          <Box mt="24px" mr="8px" textAlign="center">
            <Grid item sm={12}>
              <List component="a" href={item.link}>
                <SmartphoneImage src={item.image} ml="0" />
                <CardDesktopTitle variant="body2">
                  {item.title}
                </CardDesktopTitle>
              </List>
            </Grid>
          </Box>
        ))}
      </Grid>
      <Box ml="24px">
        <SmartphoneImage src={desktop} />
      </Box>
    </CardContainer>
  );
};

export default wrapper(DownloadDesktop, { namespace: 'DOWNLOAD_CARDS' });
