import React, { useCallback } from 'react';

import { authUseCases } from 'services/providers/auth';

import { Box, Button, Container, Grid } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_STUDENT_ROUTES } from 'modules/RequestAccess/constants/routes';

import { Description, Title } from './styles';

const RequestCompleted = () => {
  const translate = useLangContext();

  const handleLogout = useCallback(async () => {
    await authUseCases.signOut();
  }, []);

  return (
    <Container maxWidth="md">
      <Box mt={3}>
        <Grid
          container
          spacing={2}
          justifyContent={{ xs: 'center', md: 'flex-end' }}
        >
          <Grid item xs={12}>
            <Title>{translate(`TITLE`)}</Title>
            <Description>{translate(`DESCRIPTION`)}</Description>
          </Grid>
          <Grid item container justifyContent="flex-end" xs={12}>
            <Button
              variant="contained"
              id="logoutButton"
              onClick={handleLogout}
            >
              {translate('LOGOUT')}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default wrapper(RequestCompleted, {
  namespace: 'REQUEST_APPROVED',
  route: { path: REQUEST_ACCESS_STUDENT_ROUTES.REQUEST_APPROVED }
});
