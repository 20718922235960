import styled from 'styled-components';

import { BorderColor } from '@mui/icons-material';

export const SummaryTitle = styled.h1`
  font-size: 20px;
  line-height: 23px;
  font-weight: 700;
  margin-bottom: 8px;
`;

export const SummarySectionTitle = styled.h2`
  font-size: 17px;
  line-height: 19px;
  font-weight: 700;
  margin: 3px 8px 3px 0;
`;

export const RestumeItem = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

export const Section = styled.div`
  width: 100%;
`;

export const SectionTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const EditButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

export const InnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: ${props => props.theme.palette.background.white};
  padding: 32px;
  box-shadow: 0px 1px 6px rgba(51; 51; 51; 0.16);
  width: 462px;
  flex-direction: column;
  margin-bottom: 120px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  margin-top: 12px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: ${props => props.theme.palette.border.light};
  margin: 8px 0 16px 0;
`;

export const SummaryInfo = styled.p`
  font-size: 17px;
  font-weight: 500;
  margin-bottom: 20px;
`;

export const BorderColorIcon = styled(BorderColor)`
  &.MuiSvgIcon-root {
    font-size: 16px;
    cursor: pointer;
  }
`;
