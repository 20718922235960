import styled from 'styled-components';

import { StyledBox } from '../Steps/fragments/styles';

export const SchoolName = styled.div`
  color: ${props => props.theme.palette.text.primary};
  border: 1px solid ${props => props.theme.palette.border.light};
  padding: 15px;
  border-radius: 3px;
  background-color: ${props => props.theme.palette.border.primary};
  cursor: pointer;
`;

export const SummaryBox = styled(StyledBox)`
  max-width: 462px;
  padding: 40px 32px;
`;
