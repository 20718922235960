const CONNECTION_MODE = {
  READONLY: 'readonly',
  READWRITE: 'readwrite'
};

export class IndexedDBStorage {
  constructor() {
    this.db = null;
    this.dbOpenPromise = this.openDatabase();
    this.note = {};
    this.dbVersion = 3;
    this.dataMemory = {};
  }

  set(key, value) {
    this.dataMemory[key] = value;
    this._set(key, value);
    return this.dataMemory[key];
  }

  get(key) {
    return Object.prototype.hasOwnProperty.call(this.dataMemory, key)
      ? this.dataMemory[key]
      : undefined;
  }

  remove(key) {
    this._remove(key);
    return delete this.dataMemory[key];
  }

  clear() {
    this.dataMemory = {};
    this._removeAll();
    return this.dataMemory;
  }

  async sync() {
    const objectStore = await this.getObjectStore(CONNECTION_MODE.READONLY);
    const request = objectStore.getAll();

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        this.dataMemory = request.result.reduce((acc, item) => {
          acc[item.id] = item.value;
          return acc;
        }, {});

        resolve('SUCCESS');
      };

      request.onerror = event => {
        console.error('Error fetching data:', event.target.error);
        reject(event.target.error);
      };
    });
  }

  openDatabase() {
    const request = indexedDB.open('userDB', this.dbVersion);

    return new Promise((resolve, reject) => {
      request.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('cognitoIdentity')) {
          db.createObjectStore('cognitoIdentity', { keyPath: 'id' });
        }
      };

      request.onsuccess = () => {
        this.db = request.result;
        resolve(this.db);
      };

      request.onerror = event => {
        reject(event.target.errorCode);
        console.error('Database error:', event.target.errorCode);
      };
    });
  }

  isCognitoValid() {
    return this._getKeyCognito().length >= 4;
  }

  async removeTokenCognito() {
    this._getKeyCognito().forEach(key => {
      this.remove(key);
    });
  }

  async getObjectStore(mode) {
    if (!this.db) {
      await this.dbOpenPromise;
    }

    const transaction = this.db.transaction(['cognitoIdentity'], mode);
    return transaction.objectStore('cognitoIdentity');
  }

  async _set(id, value) {
    const objectStore = await this.getObjectStore(CONNECTION_MODE.READWRITE);

    const objectStoreRequest = objectStore.add({ id, value });

    objectStoreRequest.onsuccess = () => {
      this.note = 'Request successful.';
    };
  }

  async _get(key) {
    const objectStore = await this.getObjectStore(CONNECTION_MODE.READONLY);

    const request = objectStore.getAll(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        const data = request.result.find(item => item.id === key)?.value;
        resolve(data);
      };

      request.onerror = event => {
        console.error('Error fetching data:', event.target.error);
        reject(event.target.error);
      };
    });
  }

  async _remove(key) {
    const objectStore = await this.getObjectStore(CONNECTION_MODE.READWRITE);

    const request = objectStore.delete(key);

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        const data = request.result;
        resolve(data);
      };

      request.onerror = event => {
        console.error('Error fetching data:', event.target.error);
        reject(event.target.error);
      };
    });
  }

  async _removeAll() {
    const objectStore = await this.getObjectStore(CONNECTION_MODE.READWRITE);

    const request = objectStore.clear();

    return new Promise((resolve, reject) => {
      request.onsuccess = () => {
        const data = request.result;
        resolve(data);
      };

      request.onerror = event => {
        console.error('Error fetching data:', event.target.error);
        reject(event.target.error);
      };
    });
  }

  _getKeyCognito() {
    const filter = Object.keys(this.dataMemory).filter(
      key => key.indexOf('CognitoIdentityServiceProvider') >= 0
    );

    return filter;
  }
}
