import React from 'react';

import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import { REQUEST_STATUS } from 'modules/RequestAccess/constants/requestStatusEnum';

const DeleteButton = ({ row, onClick = () => {} }) => {
  const translate = useLangContext();
  const { requestStatus } = row;
  if (
    [
      REQUEST_STATUS.INCOMPLETE,
      REQUEST_STATUS.APPROVED,
      REQUEST_STATUS.DISAPPROVED
    ].includes(requestStatus)
  ) {
    return (
      <Tooltip title={translate('TOOLTIP:DELETE_STUDENT')} arrow>
        <IconButton onClick={onClick}>
          <Delete />
        </IconButton>
      </Tooltip>
    );
  }
  return null;
};

export default DeleteButton;
