import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { generatePath, useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { AsyncLoad } from 'components/AsyncLoad';
import BookCard from 'components/BookCard';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { LITERARY_DIDACTIC_WORKS_ROUTES } from 'modules/LiteraryDidacticWorks/constants/routes';
import { HomeWarnings } from 'modules/LiteraryDidacticWorks/fragments/HomeWarnings';

import { literaryDidacticWorksUseCases } from '../../LiteraryDidacticWorks/provider';
import { Schema } from '../../LiteraryDidacticWorks/schema';
import {
  ButtonGrid,
  FilterContent,
  NewStyledSelectField,
  StyledForm,
  StyledFontBold,
  ButtonClearFilters,
  StyledSegmentName,
  StyledDividerVertical,
  ThinDivider
} from '../../LiteraryDidacticWorks/styles';
import { StyledContainer } from './styles';

const LiteraryDidacticWorksListTab = ({ role, licensesPendingGeneration }) => {
  const isRoleStudent = role === 'ROLE_STUDENT';

  const history = useHistory();

  const [schoolSegment, setSchoolSegment] = useState([]);
  const [yearOptions, setYearOptions] = useState([]);
  const [typeLiteraryOptions, setTypeLiteraryOptions] = useState([]);
  const [componetOptions, setComponentOptions] = useState([]);
  const [works, setWorks] = useState([]);
  const [segment, setSegment] = useState('');
  const [selectOptions, setSelectOptions] = useState({
    segment: [],
    series: [],
    typeLiterary: []
  });

  const [titles, setTitles] = useState({
    segment: {},
    series: {},
    typeLiterary: {}
  });

  const translate = useLangContext();

  const formMethods = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      segmentId: '',
      seriesId: '',
      typeLiterary: ''
    },
    context: {
      isRoleStudent
    }
  });
  const { control, reset } = formMethods;

  const {
    inep: watchInep,
    segmentId: watchSegment,
    seriesId: watchSerie,
    typeLiterary: watchTypeLiterary,
    componentId: watchComponentId
  } = useWatch({
    control,
    name: ['inep', 'segmentId', 'seriesId', 'typeLiterary', 'componentId']
  });

  const handleFilter = async formData => {
    try {
      let response = null;
      if (isRoleStudent) {
        const payload = {
          componentId: formData.componentId ? formData.componentId : null,
          inep: watchInep,
          objectType: 'DIDACTIC',
          segmentId: segment
        };
        response = await literaryDidacticWorksUseCases.postBooksStudentSearch(
          payload
        );
        setWorks(response);
      } else {
        response = await literaryDidacticWorksUseCases.postBooksTeacherSearch(
          formData
        );
      }
      setWorks(response);
    } catch (error) {
      console.error(error);
    }
  };

  const clearFilters = useCallback(
    field => {
      reset({
        inep: '',
        segmentId: '',
        seriesId: '',
        typeLiterary: '',
        componentId: '',
        ...field
      });
      setYearOptions([]);
      setTypeLiteraryOptions([]);
      setComponentOptions([]);
    },
    [reset]
  );

  const schoolOptions = useMemo(() => {
    if (!schoolSegment) return [];
    const data = [
      ...schoolSegment.map(({ inep, schoolName }) => ({
        id: inep,
        name: schoolName
      }))
    ];
    return data;
  }, [schoolSegment]);

  const fetchSchoolSegment = useCallback(async () => {
    try {
      if (isRoleStudent) {
        const response =
          await literaryDidacticWorksUseCases.getSchoolSegmentStudentList();
        setSchoolSegment(response);
      } else {
        const response =
          await literaryDidacticWorksUseCases.getSchoolSegmentList();
        setSchoolSegment(response);
      }
    } catch (error) {
      console.error(error);
    }
  }, [isRoleStudent]);

  const segmentOptions = useMemo(() => {
    const obj = schoolSegment?.find(o => o.inep === watchInep);
    if (!obj) return [];

    let data = null;
    if (isRoleStudent) {
      data = [{ id: obj.segment.id, name: obj.segment.description }];
      setSegment(obj.segment.id);
    } else {
      data = [
        ...obj.segmentList.map(({ id, description }) => ({
          id,
          name: description
        }))
      ];
    }
    setSelectOptions(prev => ({ ...prev, segment: data }));
    return data;
  }, [isRoleStudent, schoolSegment, watchInep]);

  const handleView = data => {
    const path = generatePath(
      LITERARY_DIDACTIC_WORKS_ROUTES.LITERARY_DIDACTIC_WORKS_DETAIL,
      {
        volumeCode: data.volumeCode
      }
    );

    history.push({
      pathname: path,
      state: {
        workInfo: data,
        inep: watchInep
      }
    });
  };

  const fetchSerie = useCallback(async () => {
    try {
      const response = await literaryDidacticWorksUseCases.getGradeList(
        watchInep,
        watchSegment
      );
      if (!response) return [];
      const data = [
        ...response.map(({ itemId, name }) => ({
          id: itemId,
          name
        }))
      ];
      setSelectOptions(prev => ({ ...prev, series: data }));
      setYearOptions(data);
    } catch (error) {
      console.error(error);
    }
    return [];
  }, [watchInep, watchSegment]);

  const fetchTypeLiterary = useCallback(async () => {
    try {
      let response = null;
      if (isRoleStudent) {
        response =
          await literaryDidacticWorksUseCases.getTypeLiteraryStudentList(
            watchInep,
            segment
          );
      } else {
        response = await literaryDidacticWorksUseCases.getTypeLiteraryList(
          watchInep,
          watchSegment,
          watchSerie
        );
      }
      if (!response) return [];
      const data = [
        ...response.map(item => ({
          id: item,
          name: translate(`TYPE_LITERARY_OPTIONS.${item}`)
        }))
      ];
      setSelectOptions(prev => ({ ...prev, typeLiterary: data }));
      setTypeLiteraryOptions(data);
    } catch (error) {
      console.error(error);
    }
    return [];
  }, [isRoleStudent, segment, translate, watchInep, watchSegment, watchSerie]);

  const fetchComponent = useCallback(async () => {
    try {
      let response = null;
      if (isRoleStudent) {
        response = await literaryDidacticWorksUseCases.getComponentStudentList(
          watchInep,
          segment
        );
      } else {
        response = await literaryDidacticWorksUseCases.getComponentList(
          watchInep,
          watchSegment,
          watchSerie,
          watchTypeLiterary
        );
      }
      if (!response) return [];
      const data = [
        ...response.map(({ itemId, name }) => ({
          id: itemId,
          name
        }))
      ];
      setComponentOptions(data);
    } catch (error) {
      console.error(error);
    }
    return [];
  }, [
    isRoleStudent,
    segment,
    watchInep,
    watchSegment,
    watchSerie,
    watchTypeLiterary
  ]);

  useEffect(() => {
    if (watchInep) {
      if (isRoleStudent) {
        setTitles(prev => ({
          ...prev,
          segment: selectOptions.segment.find(t => t.id === segment)
        }));
      }
    }
  }, [isRoleStudent, segment, selectOptions.segment, watchInep]);

  useEffect(() => {
    if (watchSegment) {
      setTitles(prev => ({
        ...prev,
        segment: selectOptions.segment.find(t => t.id === watchSegment)
      }));
      fetchSerie();
    }
  }, [fetchSerie, selectOptions.segment, watchSegment]);

  useEffect(() => {
    if (watchSerie || (segment && watchInep !== '')) {
      setTitles(prev => ({
        ...prev,
        serie: selectOptions.series.find(t => t.id === watchSerie)
      }));
      fetchTypeLiterary();
    }
  }, [fetchTypeLiterary, segment, selectOptions.series, watchInep, watchSerie]);

  useEffect(() => {
    if (watchTypeLiterary) {
      setTitles(prev => ({
        ...prev,
        typeLiterary: selectOptions.typeLiterary.find(
          t => t.id === watchTypeLiterary
        )
      }));
      fetchComponent();
    }
  }, [fetchComponent, selectOptions.typeLiterary, watchTypeLiterary]);

  useEffect(() => {
    if (isRoleStudent) {
      reset({
        inep: schoolSegment[0]?.inep,
        segmentId: '',
        serieId: '',
        typeLiterary: '',
        componentId: ''
      });
    }
  }, [isRoleStudent, reset, schoolSegment]);

  useEffect(() => {
    setWorks([]);
  }, [
    watchInep,
    watchSegment,
    watchSerie,
    watchTypeLiterary,
    watchComponentId
  ]);

  return (
    <StyledContainer>
      <HomeWarnings userInfo={{ isRoleStudent, licensesPendingGeneration }} />

      <StyledForm formMethods={formMethods} onSubmit={handleFilter}>
        <Typography
          variant="h2"
          component="h2"
          fontSize={{ lg: '16px', md: '16px', sm: '16px', xs: '16px' }}
          mt="23px"
        >
          {translate('LITERARY_DIDACTIC_WORKS:SUBTITLE_WORKS')}
          <StyledFontBold>
            {translate('LITERARY_DIDACTIC_WORKS:SUBTITLE_WORKS_BOLD')}
          </StyledFontBold>{' '}
          {translate('LITERARY_DIDACTIC_WORKS:SUBTITLE_WORKS_END')}
        </Typography>
        <FilterContent>
          <Grid container spacing={2} mt={2}>
            <AsyncLoad promiseFn={fetchSchoolSegment}>
              <Grid item xs={12} md>
                <NewStyledSelectField
                  name="inep"
                  idKey="id"
                  label={translate('SCHOOL')}
                  placeholder={translate('COMMONS:SELECT')}
                  displayEmpty
                  aria-label={translate('ACCESSIBILITY:SELECT.SCHOOL')}
                  options={schoolOptions}
                  formMethods={formMethods}
                />
              </Grid>
              {!isRoleStudent && (
                <Grid item xs={12} md>
                  <NewStyledSelectField
                    name="segmentId"
                    idKey="id"
                    label={translate('SEGMENT')}
                    placeholder={translate('COMMONS:SELECT')}
                    displayEmpty
                    options={segmentOptions}
                    formMethods={formMethods}
                    aria-label={translate('ACCESSIBILITY:SELECT.SEGMENT')}
                  />
                </Grid>
              )}
            </AsyncLoad>
            {!isRoleStudent && (
              <Grid item xs={12} md>
                <NewStyledSelectField
                  name="seriesId"
                  idKey="id"
                  label={translate('YEAR')}
                  placeholder={translate('COMMONS:SELECT')}
                  displayEmpty
                  options={yearOptions}
                  formMethods={formMethods}
                  aria-label={translate('ACCESSIBILITY:SELECT.YEAR')}
                />
              </Grid>
            )}
            <Grid item xs={12} md>
              <NewStyledSelectField
                name="typeLiterary"
                idKey="id"
                label={translate('TYPE_LITERARY')}
                placeholder={translate('COMMONS:SELECT')}
                displayEmpty
                options={typeLiteraryOptions}
                formMethods={formMethods}
                aria-label={translate('ACCESSIBILITY:SELECT.TYPE_LITERARY')}
              />
            </Grid>
            <Grid item xs={12} md>
              <NewStyledSelectField
                name="componentId"
                idKey="id"
                label={translate('COMPONENT')}
                placeholder={translate('COMMONS:SELECT')}
                displayEmpty
                options={componetOptions}
                formMethods={formMethods}
                aria-label={translate('ACCESSIBILITY:SELECT.COMPONENT')}
              />
            </Grid>

            <ButtonGrid
              item
              container
              alignContent="flex-end"
              xs={12}
              md={1}
              style={{ minWidth: '264px' }}
            >
              <Box>
                <ButtonClearFilters
                  type="reset"
                  variant="outlined"
                  onClick={clearFilters}
                  aria-label={translate('CLEAN_FILTER')}
                >
                  {translate('CLEAN_FILTER')}
                </ButtonClearFilters>
              </Box>

              <Box ml={1.5}>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ marginTop: '22px', minWidth: '82px' }}
                  aria-label={translate('SEARCH')}
                >
                  {translate('SEARCH')}
                </Button>
              </Box>
            </ButtonGrid>
          </Grid>
        </FilterContent>
      </StyledForm>
      {works.length > 0 && (
        <>
          <Grid container xs={12} spacing={1} mt="28px">
            <Grid item>
              <StyledSegmentName
                fontWeight="bold"
                variant="h6"
                component="h2"
                aria-label={titles.segment.name}
              >
                {titles.segment.name}
              </StyledSegmentName>
            </Grid>
            <Grid item>
              {!isRoleStudent && (
                <StyledSegmentName
                  fontWeight="bold"
                  variant="h6"
                  component="h2"
                  aria-label={titles.serie.name}
                >
                  {titles.serie.name}
                </StyledSegmentName>
              )}
            </Grid>
            <StyledDividerVertical
              orientation="vertical"
              variant="string"
              flexItem
              aria-label={titles.typeLiterary.name}
            />
            <Grid item>
              <StyledSegmentName fontWeight="bold" variant="h6" component="h2">
                {titles.typeLiterary.name}
              </StyledSegmentName>
            </Grid>
          </Grid>
          <Grid xs={12} lg={12}>
            <ThinDivider />
          </Grid>
          <Grid container spacing={2} mt="16px">
            {works.map(item => (
              <Grid item xs={6} md={3} mb="24px" key={item.volumeCode}>
                <BookCard
                  data={item}
                  handleView={() => handleView(item)}
                  isRoleStudent={isRoleStudent}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </StyledContainer>
  );
};

export default wrapper(LiteraryDidacticWorksListTab, {
  namespace: 'LITERARY_DIDACTIC_WORKS',

  mapState: state => ({
    role: state.auth.role,
    licensesPendingGeneration: state.auth.user.licensesPendingGeneration
  }),
  route: {
    path: LITERARY_DIDACTIC_WORKS_ROUTES.LITERARY_DIDACTIC_WORKS_OLD
  }
});
