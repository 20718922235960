import * as yup from 'yup';

export const initialSchema = yup.object().shape({
  name: yup.string().nullable().required().max(255),
  degreeOfKinship: yup.string().nullable().required(),
  cpf: yup.string().nullable().required().cpf().digitsOnly(),
  hasPhysicalDisability: yup.boolean().required(),
  physicalDisability: yup
    .array()
    .of(yup.string())
    .min(1)
    .when('hasPhysicalDisability', (hasPhysicalDisability, schema) =>
      hasPhysicalDisability ? schema.required() : schema.notRequired()
    ),
  isUpperTwelveYearsOld: yup
    .boolean()
    .required()
    .test(
      'check-physical-disability',
      'REQUEST_ACCESS_COMMONS:IS_LOWER_TWELVE',
      function checkPhysicalDisability(value) {
        const { hasPhysicalDisability } = this.parent;

        return hasPhysicalDisability || !value;
      }
    ),
  email: yup.string().nullable().required().email().max(255)
});
