import React, { useCallback, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { Box, Grid, Typography } from '@mui/material';

import { ROLES } from 'helpers/roles';

import { AsyncLoad } from 'components/AsyncLoad';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import SelectField from 'components/SelectField';
import wrapper from 'components/Wrapper';

import { useRequestAccessContext } from '../../../../Context';
import { requestAccessUseCases } from '../../../../provider';
import ActionsButtons from '../ActionsButtons';
import { StyledBox } from '../styles';

const LearningLevel = () => {
  const translate = useLangContext();
  const { fnNext } = useStepperContext();

  const [learningLevelList, setLearningLevelList] = useState([]);
  const { requestData, setRequestData, requestFunctions, isEditing } =
    useRequestAccessContext();

  const { editRequestLearningPhase, role } = requestFunctions;

  const isTeacher = role === ROLES.ROLE_TEACHER;
  const {
    id,
    learningPhase,
    learningLevel: RequestedLearningLevel
  } = requestData;

  const formMethods = useForm({
    defaultValues: {
      learningLevel: learningPhase || RequestedLearningLevel
    }
  });

  const {
    control,
    reset,
    formState: { isDirty }
  } = formMethods;

  const findLearningLevelName = useCallback(
    learningPhaseId =>
      learningLevelList.find(
        learningLevel => learningLevel.id === learningPhaseId
      )?.description,
    [learningLevelList]
  );

  const submitLearningLevel = useCallback(
    async formData => {
      const { learningLevel: learningPhaseId } = formData;

      const learningPhaseName = findLearningLevelName(learningPhaseId);
      const data = isTeacher
        ? {
            learningLevel: learningPhaseId,
            learningLevelName: learningPhaseName
          }
        : {
            learningPhase: learningPhaseId,
            learningPhaseName
          };

      if (isDirty) {
        if (isEditing) {
          setRequestData(prevState => ({
            ...prevState,
            ...data,
            role
          }));

          fnNext();
          return;
        }

        try {
          const resp = await editRequestLearningPhase({
            learningPhaseId,
            id
          });

          setRequestData({ ...resp, role });
        } catch (error) {
          console.error(error);
        }
      }
      setRequestData(prevState => ({
        ...prevState,
        learningPhaseName
      }));
      fnNext();
    },
    [
      isDirty,
      fnNext,
      findLearningLevelName,
      isTeacher,
      isEditing,
      setRequestData,
      role,
      editRequestLearningPhase,
      id
    ]
  );

  const findLearningPhase = useCallback(
    resp =>
      resp.find(
        learningLevel => learningLevel.id === requestData.learningPhase
      ),
    [requestData.learningPhase]
  );

  const findLearningLevel = useCallback(
    resp =>
      resp.find(
        learningLevel => learningLevel.id === requestData.learningLevel
      ),
    [requestData.learningLevel]
  );

  const getLearningLevelList = useCallback(async () => {
    if (!requestData?.inep) return;
    try {
      let resp;
      if (!isTeacher) {
        resp = await requestAccessUseCases.getLearningPhaseList(
          requestData.inep
        );
      } else {
        resp = await requestAccessUseCases.getLearningLevelList(
          requestData.inep
        );
      }

      setLearningLevelList(resp);

      if (!findLearningPhase(resp) && !findLearningLevel(resp)) {
        reset('learningLevel');
      }
    } catch (error) {
      console.error(error);
    }
  }, [
    findLearningLevel,
    findLearningPhase,
    isTeacher,
    requestData.inep,
    reset
  ]);

  const learningLevel = useWatch({
    control,
    name: 'learningLevel'
  });

  return (
    <StyledBox>
      <Typography component="h3" variant="h6">
        {translate('SELECT_LEARNING_PHASE')}
      </Typography>
      <Form formMethods={formMethods} onSubmit={submitLearningLevel}>
        <Grid item xs={12}>
          <Box mt={3}>
            <AsyncLoad promiseFn={getLearningLevelList}>
              <SelectField
                name="learningLevel"
                label={translate('SELECT_LEARNING_PHASE_PLACEHOLDER')}
                placeholder={translate('COMMONS:SELECT')}
                optionRenderer={option => option.description}
                displayEmpty
                options={learningLevelList}
                formMethods={formMethods}
              />
            </AsyncLoad>
          </Box>
        </Grid>

        <ActionsButtons confirmButtonDisable={!learningLevel} />
      </Form>
    </StyledBox>
  );
};

export default wrapper(LearningLevel, { namespace: 'REQUEST_ACCESS_COMMONS' });
