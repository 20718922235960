import SECTION_TITLE from './sectionTitle';

const SECTIONS = [
  {
    title: SECTION_TITLE.PERSONAL_INFO,
    items: [
      {
        label: 'EMAIL',
        value: requestData => requestData.email
      }
    ]
  },
  {
    title: SECTION_TITLE.LOCALITY,
    items: [
      {
        label: 'UF',
        value: requestData => requestData.uf
      },
      {
        label: 'CITY',
        value: requestData => requestData.cityName
      }
    ]
  },
  {
    title: SECTION_TITLE.SCHOOL,
    items: [
      {
        label: 'SCHOOL',
        value: requestData =>
          `${requestData.schoolName} - INEP ${requestData.inep}`
      }
    ]
  },
  {
    title: SECTION_TITLE.STAGE_EDUCATION,
    items: [
      {
        label: 'SCHOOL_STEP',
        value: requestData => requestData.learningLevelName
      }
    ]
  }
];
export default SECTIONS;
