import { useCallback } from 'react';

import { userAccessActions } from 'framework/store/slices/userAccess.slice';
import store from 'framework/store/store';

import { requestAccessUseCases } from 'modules/RequestAccess/provider';

export function useUserAccess() {
  const configureUserAccess = useCallback(async () => {
    let resp;
    try {
      resp = await requestAccessUseCases.configureUserAccess();

      await store.dispatch(userAccessActions.setUserAccess(resp));
      return resp;
    } catch (error) {
      console.error(error);
    }

    return {};
  }, []);

  return { configureUserAccess };
}
