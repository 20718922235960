import React, { useCallback, useMemo, useState, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  matchPath,
  useHistory
} from 'react-router-dom';

import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from './constants/routes';
import { RequestAccessContext } from './Context';
import { requestAccessUseCases } from './provider';
import RequestAccessGuardian from './submodules/RequestAccessGuardian';
import ConsentAgreement from './submodules/RequestAccessGuardian/fragments/ConsentAgreement';
import RequestAccessStudent from './submodules/RequestAccessStudent';
import NotAllowed from './submodules/RequestAccessStudent/fragments/NotAllowed';
import RequestAccessTeacher from './submodules/RequestAccessTeacher';
import SchoolManagement from './submodules/SchoolManagement';
import IdentifyUser from './submodules/Steps/fragments/IdentifyUser';
import RequestApproved from './submodules/Steps/fragments/RequestApproved';
import RequestCompleted from './submodules/Steps/fragments/RequestCompleted';
import SelectProfile from './submodules/Steps/fragments/SelectProfile';
import StudentManagement from './submodules/StudentManagement';
import TermsOfUse from './submodules/TermsOfUse';

const studentRoutes = [
  { component: RequestAccessStudent },
  {
    component: StudentManagement
  },
  {
    component: SchoolManagement
  },
  {
    component: NotAllowed
  },
  {
    component: RequestApproved
  }
];

const guardiansRoutes = [
  {
    component: RequestAccessGuardian
  },
  {
    component: ConsentAgreement
  }
];

const teacherRoutes = [
  {
    component: RequestAccessTeacher
  }
];

const routes = [
  {
    component: TermsOfUse,
    getParams: ({ termVersion, needsSign }) => ({ termVersion, needsSign })
  },
  {
    component: SelectProfile
  },
  {
    component: IdentifyUser
  },
  {
    component: SchoolManagement
  },
  {
    component: RequestCompleted
  },

  ...guardiansRoutes,
  ...teacherRoutes,
  ...studentRoutes
];

const termVersion = '16102024';
/* ATENÇÃO: termVersion deve conter a última versão dos termos de uso
   ao aplicar qualquer alteração no texto dos termos de uso, termVersion deve ser atualizado com a nova versão.
*/

const RequestAccess = () => {
  const [loading, setLoading] = useState(true);
  const [termsOfUseNeedsSign, setTermsOfUseNeedsSign] = useState({});
  const [requestData, setRequestData] = useState(null);
  const [requestFunctions, setRequestFunctions] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const { pathname } = useLocation();
  const history = useHistory();

  const checkTermsOfUseUpdated = useCallback(async () => {
    setLoading(true);

    try {
      const resp = await requestAccessUseCases.checkTermsOfUseUpdated({
        termVersion
      });
      const { needSign, needUpdateTermsAndUse } = resp;

      setTermsOfUseNeedsSign({ needSign, needUpdateTermsAndUse });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const redirectIfRoleIsUndefined = role => {
    if (!role) {
      history.push(REQUEST_ACCESS_ROUTES.SELECT_PROFILE);
    }
  };

  const RedirectToTerms = useMemo(() => {
    const isPathTermsOfUse = matchPath(pathname, {
      path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS
    });
    const hasTermsOfUsePendency =
      termsOfUseNeedsSign.needSign || termsOfUseNeedsSign.needUpdateTermsAndUse;

    if (!loading && isPathTermsOfUse?.isExact) {
      return (
        <Redirect
          to={hasTermsOfUsePendency ? TermsOfUse.path : SelectProfile.path}
        />
      );
    }

    return null;
  }, [
    loading,
    pathname,
    termsOfUseNeedsSign.needSign,
    termsOfUseNeedsSign.needUpdateTermsAndUse
  ]);

  useEffect(() => {
    checkTermsOfUseUpdated();
  }, [checkTermsOfUseUpdated]);

  return (
    <RequestAccessContext.Provider
      value={{
        requestData,
        setRequestData,
        requestFunctions,
        setRequestFunctions,
        isEditing,
        redirectIfRoleIsUndefined,
        setIsEditing
      }}
    >
      <Switch>
        {RedirectToTerms}

        {routes?.map(route => (
          <Route
            path={route.component.path}
            exact
            key={route.component.path}
            render={() => (
              <route.component.Component
                {...(route.getParams?.({
                  termVersion,
                  needsSign: termsOfUseNeedsSign
                }) ?? {})}
              />
            )}
          />
        ))}
      </Switch>
    </RequestAccessContext.Provider>
  );
};

export default wrapper(RequestAccess, {
  namespace: 'REQUEST_ACCESS',
  route: {
    path: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS,
    exact: false,
    breadcrumb: 'REQUEST_ACCESS'
  }
});
