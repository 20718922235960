import styled from 'styled-components';

export const RequestCompletedTitle = styled.h1`
  font-size: 34px;
  line-height: 40px;
  margin-bottom: 32px;
  font-weight: 400;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 24px;
    font-weight: 700;
    text-align: center;
  }
`;

export const RequestCompletedDescription = styled.h2`
  margin-bottom: 16px;
  max-width: 704px;
  font-weight: 400;
  font-size: 16px;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 14px;
    text-align: center;
  }
`;

export const RequestCompletedInfo = styled.h2`
  font-size: 16px;
  margin-bottom: 52px;
  font-weight: 400;

  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    font-size: 14px;
    text-align: center;
    margin-bottom: 32px;
  }
`;

export const CustomImage = styled.img`
  max-width: 100%;
`;
