import SECTION_TITLE from './sectionTitle';

const SECTIONS = [
  {
    title: SECTION_TITLE.PERSONAL_INFO,
    items: [
      {
        label: 'REQUEST_ACCESS_COMMONS:IS_UPPER_TWELVE',
        value: requestData =>
          requestData.isUpperTwelveYearsOld ? 'COMMONS:YES' : 'COMMONS:NO'
      },
      {
        label: 'COMMONS:EMAIL',
        value: requestData => requestData.email
      },
      {
        label: 'REQUEST_ACCESS_COMMONS:DISABILITIES:STUDENT_DISABILITY',
        value: requestData =>
          requestData.hasPhysicalDisability ? 'COMMONS:YES' : 'COMMONS:NO'
      },
      {
        label: 'REQUEST_ACCESS_COMMONS:DISABILITIES:DISABILITY_DESCRIPTION',
        value: requestData =>
          requestData.physicalDisability?.length
            ? requestData.physicalDisability.map(
                item => `REQUEST_ACCESS_COMMONS:DISABILITIES.${item}`
              )
            : 'COMMONS:NO'
      }
    ]
  },
  {
    title: SECTION_TITLE.LOCALITY,
    items: [
      {
        label: 'REQUEST_ACCESS_COMMONS:UF',
        value: requestData => requestData.uf
      },
      {
        label: 'REQUEST_ACCESS_COMMONS:CITY',
        value: requestData => requestData.cityName
      }
    ]
  },
  {
    title: SECTION_TITLE.SCHOOL,
    items: [
      {
        label: 'REQUEST_ACCESS_COMMONS:SCHOOL',
        value: requestData =>
          `${requestData.schoolName} - INEP ${requestData.inep}`
      }
    ]
  },
  {
    title: SECTION_TITLE.YEAR_CLASS,
    items: [
      {
        label: 'REQUEST_ACCESS_COMMONS:LEARNING_LEVEL',
        value: requestData => requestData.learningPhaseName
      }
    ]
  }
];

export default SECTIONS;
