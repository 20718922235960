import React from 'react';

import { Container, Grid, Typography } from '@mui/material';

import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { REQUEST_ACCESS_ROUTES } from 'modules/RequestAccess/constants/routes';
import { useRequestAccessContext } from 'modules/RequestAccess/Context';
import EditWarning from 'modules/RequestAccess/fragments/EditWarning';

import IdentifyForm from './fragments/Form';
import { UserName } from './styles';

const IdentifyUser = ({ auth: { user, userGovBr } }) => {
  const translate = useLangContext();
  const { isEditing, requestData, redirectIfRoleIsUndefined } =
    useRequestAccessContext();
  const { role } = requestData || {};

  redirectIfRoleIsUndefined(role);

  const userData = user?.cpf ? user : userGovBr;

  return (
    <>
      <Grid container justifyContent="center" flexDirection="column">
        <EditWarning show={isEditing} />
      </Grid>
      <Container>
        <Grid item xs={12}>
          <UserName variant="h3" component="h1">
            {translate('HELLO')}, {userData?.name}!
          </UserName>
          <Typography
            name={translate('FILL_DATA', {
              role: translate(`ROLES:${role}`)
            })}
          >
            {translate('FILL_DATA', {
              role: translate(`ROLES:${role}`)
            })}
          </Typography>
          <IdentifyForm user={userData} />
        </Grid>
      </Container>
    </>
  );
};

export default wrapper(IdentifyUser, {
  namespace: 'IDENTIFY_USER',
  mapState: state => ({
    auth: state.auth
  }),
  route: { path: REQUEST_ACCESS_ROUTES.IDENTIFY_USER }
});
