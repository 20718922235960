import React from 'react';

import { Edit } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';

import { useLangContext } from 'components/Globalization';

import { REQUEST_STATUS } from 'modules/RequestAccess/constants/requestStatusEnum';

const EditButton = ({ row, redirect }) => {
  const translate = useLangContext();
  const { requestStatus } = row;
  if (requestStatus === REQUEST_STATUS.INCOMPLETE) {
    return (
      <Tooltip title={translate('TOOLTIP:EDIT_SCHOOL')} arrow>
        <IconButton onClick={() => redirect(row)}>
          <Edit />
        </IconButton>
      </Tooltip>
    );
  }
  return null;
};

export default EditButton;
