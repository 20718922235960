import { Link } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { Close, Contrast, DensityMedium } from '@mui/icons-material';
import { IconButton, Container as MuiContainer } from '@mui/material';

import { APP_MEASURES } from 'modules/App/constants/measures';

export const BrandContainer = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const ButtonContrast = styled(Contrast)`
  &.MuiSvgIcon-root {
    font-size: 16px;
  }
`;

export const ButtonWrapperDensity = styled(IconButton)`
  display: flex;
  align-items: center;
  &.MuiButtonBase-root {
    padding-top: 0;
  }
`;

export const ButtonDensityMedium = styled(DensityMedium)`
  &.MuiSvgIcon-root {
    font-size: 20px;
    color: ${props => props.theme.palette.text.light};
    vertical-align: middle;
  }
`;

export const ButtonClose = styled(Close)`
  &.MuiSvgIcon-root {
    font-size: 20px;
    color: ${props => props.theme.palette.text.light};
    vertical-align: middle;
    cursor: pointer;
  }
`;
export const Container = styled.div`
  height: ${`${APP_MEASURES.HEADER_HEIGHT}px`};
  min-height: ${`${APP_MEASURES.HEADER_HEIGHT}px`};
  max-height: ${`${APP_MEASURES.HEADER_HEIGHT}px`};
  width: 100%;
  background-color: ${props => props.theme.palette.background.light};
  box-shadow: 2px 2px 8px ${props => props.theme.palette.border.light};
`;

export const Header = styled.header`
  align-items: center;
  background: ${props => props.theme.palette.primary.main};
  border-bottom: 1px solid ${props => props.theme.palette.border.primary};
  box-shadow: 0px 3px 6px ${props => props.theme.palette.auxColors.boxShadow};
  display: flex;
  flex-wrap: wrap;
  height: 145px;
  justify-content: space-between;
  opacity: 1;
  padding: 24px;
  position: relative;
  width: 100%;
  z-index: 2;
`;

export const HeaderContent = styled(MuiContainer)`
  display: flex;
  align-items: center;
  padding-right: 10px;
`;

export const LinksContainer = styled.div`
  align-items: center;
  border-right: 1px solid ${props => props.theme.palette.primary.contrastText};
  display: flex;
  justify-content: flex-start;
  padding: 5px 24px 5px 24px;
  margin-right: 24px;
  a {
    color: ${props => props.theme.palette.secondary.main};
    font-size: 12px;
    margin-left: 24px;
    font-weight: 600;
  }
  @media (max-width: ${props => props.theme.breakpoints.values.sm}px) {
    padding-right: 0px;
    margin-right: 12px;
    width: 30%;
  }
`;

export const MenuContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
`;

export const MenuOptions = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
`;

export const HeaderBrand = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 300px;
  padding: 0px;
  padding-right: 20px;
  cursor: pointer;

  & * {
    cursor: pointer;
  }

  ${props => css`
    transition: background-color ${props.theme.animationDuration} ease-in-out;

    /* &:hover,
    &:active,
    &:focus {
      background-color: ${props.theme.palette.background.default};
    } */
  `}
`;

export const NavBrand = styled.img`
  cursor: pointer;
`;

export const Title = styled.h5`
  margin: 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: default;

  color: ${props => props.theme.palette.grey[400]};
`;

export const MenuButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;

  ${props => css`
    transition: background-color ${props.theme.animationDuration} ease-in-out;

    &:hover,
    &:active,
    &:focus {
      background-color: ${props.theme.palette.background.default};
    }
  `}
`;

export const TitlePage = styled.p`
  border-left: 1px solid ${props => props.theme.palette.border.light};
  color: ${props => props.theme.palette.text.light};
  font-size: 14px;
  font-weight: 500;
  margin-left: 16px;
  padding: 8px 0 8px 16px;
`;

export const TitleSecondary = styled.h5`
  align-self: flex-end;
  font-size: 24px;
  font-weight: 400;
  width: 100%;
  color: ${props => props.theme.palette.text.light};
`;
