import * as Yup from 'yup';

Yup.addMethod(
  Yup.string,
  'cpf',
  function cpfValidation(errorMessage = 'VALIDATION:INVALID_CPF') {
    return this.test('cpf', errorMessage, function testCPF(cpf) {
      if (!cpf) {
        return false;
      }
      const { path, createError } = this;

      cpf = cpf.replace(/\D/g, '');

      const characters = new Set(Array.from(cpf));
      if (characters.size === 1) {
        return false;
      }

      let soma = 0;
      let resto;
      for (let i = 1; i <= 9; i += 1)
        soma += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
      resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(cpf.substring(9, 10), 10))
        return createError({ path, message: errorMessage });
      soma = 0;
      for (let j = 1; j <= 10; j += 1)
        soma += parseInt(cpf.substring(j - 1, j), 10) * (12 - j);
      resto = (soma * 10) % 11;
      if (resto === 10 || resto === 11) resto = 0;
      if (resto !== parseInt(cpf.substring(10, 11), 10))
        return createError({ path, message: errorMessage });
      return true;
    });
  }
);
