const BASE_ROUTE = '/request-access';

export const REQUEST_ACCESS_ROUTES = {
  REQUEST_ACCESS: BASE_ROUTE,
  TERMS_OF_USE: `${BASE_ROUTE}/terms-of-use`,
  SELECT_PROFILE: `${BASE_ROUTE}/select-profile`,
  IDENTIFY_USER: `${BASE_ROUTE}/identify-user`,
  REQUEST_ACCESS_TEACHER: `${BASE_ROUTE}/teacher`,
  REQUEST_ACCESS_GUARDIANS: `${BASE_ROUTE}/guardians`,
  REQUEST_ACCESS_STUDENT: `${BASE_ROUTE}/student`,
  SCHOOL_MANAGEMENT: `${BASE_ROUTE}/school-management`,
  STUDENT_MANAGEMENT: `${BASE_ROUTE}/student-management`,
  SUCCESS: `${BASE_ROUTE}/success`,
  REQUEST_COMPLETED: `${BASE_ROUTE}/completed`,
  REQUEST_ACCESS_GUARDIAN: `${BASE_ROUTE}/guardian`,
  CONSENT_AGREEMENT: `${BASE_ROUTE}/guardian-consent-agreement`
};

export const REQUEST_ACCESS_STUDENT_ROUTES = {
  NOT_ALLOWED: `${BASE_ROUTE}/student/not-allowed`,
  REQUEST_APPROVED: `${BASE_ROUTE}/student/approved`
};

export const REQUEST_ACCESS_TEACHER_ROUTES = {
  REQUEST_COMPLETED: `${BASE_ROUTE}/teacher/completed`
};
