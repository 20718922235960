import * as yup from 'yup';

const studentSchema = {
  isUpperTwelveYearsOld: yup.string().when('$isStudent', {
    is: true,
    then: yup.string().required()
  }),
  hasPhysicalDisability: yup.string().when('$isStudent', {
    is: true,
    then: yup.string().required()
  }),
  physicalDisability: yup
    .array()
    .of(yup.string())
    .when(['$isStudent', 'hasPhysicalDisability'], {
      is: (isStudent, hasPhysicalDisability) =>
        isStudent && hasPhysicalDisability !== 'false',
      then: yup.array().min(1).of(yup.string()).required()
    })
};
export const schema = yup.object().shape({
  cpf: yup.string().required().cpf().digitsOnly(),
  email: yup.string().required().email().max(255),
  ...studentSchema
});
