import React from 'react';
import { useController } from 'react-hook-form';

import useFormError from 'hooks/useFormError';
import { isArray, isFunction, merge, mergeWith } from 'lodash';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import MuiTextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';

import {
  ErrorHelperText,
  StyledErrorIcon
} from 'components/ControlGroup/styles';

function customizedMerge(objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
  if (isFunction(objValue)) {
    return objValue;
  }
  return objValue;
}

const MuiTextFieldCustom = styled(MuiTextField)`
  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}
  ${props =>
    props.$fullHeight &&
    css`
      &.MuiFormControl-root,
      .MuiInputBase-multiline {
        height: 100% !important;
      }
      .MuiInputBase-inputMultiline {
        height: 100% !important;
        overflow-y: auto;
      }
      .MuiInputBase-multiline {
        padding: 10px 0;
      }
    `}
`;

const styles = theme => ({
  inputRoot: {
    borderRadius: 3,
    backgroundColor: theme.palette.background.light,
    border: `1px solid ${theme.palette.border.light}`,
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '&.Mui-disabled': {
      '& .MuiInputAdornment-root': {
        color: theme.palette.grey[400]
      }
    },
    '& fieldset legend span': {
      display: 'none'
    }
  },
  inputRootRefact: {
    borderRadius: 3,
    border: '1px solid #A9A9A9',
    padding: 0,
    'label + &': {
      marginTop: theme.spacing(3)
    },
    '&.Mui-disabled': {
      '& .MuiInputAdornment-root': {
        color: theme.palette.grey[400]
      }
    }
  },
  input: {
    fontSize: '1em',
    padding: '8px 10px',
    '&:focus': {
      borderColor: '#80bdff'
    }
  },
  inputLabelRoot: {
    color: theme.palette.text.primary,
    '&$formLabelFocused': {
      color: theme.palette.text.primary
    }
  },
  formLabelFocused: {}
});

export const UncontrolledTextField = withStyles(styles)(
  React.forwardRef(
    (
      {
        setupColor,
        fullHeight,
        classes,
        InputProps: InputPropsProp = {},
        InputLabelProps: InputLabelPropsProp = {},
        children,
        maxLength,
        ...inputProps
      },
      ref
    ) => {
      const InputProps = mergeWith(
        InputPropsProp,
        {
          disableunderline: 'true',
          classes: {
            root:
              setupColor === 'gray'
                ? classes.inputRootRefact
                : classes.inputRoot,
            input: classes.input
          },
          inputProps: {
            maxLength
          }
        },
        customizedMerge
      );

      const InputLabelProps = merge(InputLabelPropsProp, {
        shrink: true,
        classes: {
          root: classes.inputLabelRoot,
          focused: classes.formLabelFocused
        }
      });

      return (
        <MuiTextFieldCustom
          $fullHeight={fullHeight}
          inputRef={ref}
          InputProps={InputProps}
          InputLabelProps={InputLabelProps}
          {...inputProps}
        >
          {isFunction(children) ? children(inputProps.value) : children}
        </MuiTextFieldCustom>
      );
    }
  )
);

const TextField = ({
  setupColor,
  name,
  id,
  formMethods,
  defaultValue,
  children,
  onChange: onChangeProp = () => {},
  supressErrorText = false,
  ...rest
}) => {
  const { control, errors } = formMethods;
  const {
    field: { ref, value, onChange, ...inputProps }
  } = useController({
    name,
    control,
    defaultValue
  });

  const errorProps = useFormError(errors, name, supressErrorText);
  const computedId = id || name;
  const hasError = !!errorProps.error;

  return (
    <UncontrolledTextField
      setupColor={setupColor}
      id={computedId}
      ref={ref}
      value={value}
      onChange={e => {
        onChangeProp(e);
        onChange(e?.target?.value || e);
      }}
      error={hasError}
      helperText={
        !supressErrorText &&
        hasError && (
          <ErrorHelperText>
            <StyledErrorIcon />
            {errorProps.helperText}
          </ErrorHelperText>
        )
      }
      FormHelperTextProps={{
        sx: { marginLeft: 0 }
      }}
      {...inputProps}
      {...rest}
    >
      {isFunction(children) ? children(value) : children}
    </UncontrolledTextField>
  );
};

TextField.defaultProps = {
  defaultValue: '',
  label: '',
  fullWidth: true,
  fullHeight: false,
  disabled: false,
  children: null
};

TextField.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func
  ]),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullHeight: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  disabled: PropTypes.bool
};

export default TextField;
