/* eslint-disable class-methods-use-this */
const keyPrefix = 'v2_';
export default class CookieManager {
  _get(keyCookie) {
    return this._getKeyCognito().find(
      currentCookie => currentCookie.key === keyPrefix + keyCookie
    )?.value;
  }

  _getKeyCognito() {
    return this._getAllCookie().filter(
      cookie =>
        cookie.key.indexOf(`${keyPrefix}CognitoIdentityServiceProvider`) >= 0
    );
  }

  _getAllCookie() {
    return document.cookie.split(';').map(currentCookie => {
      const [key, value] = currentCookie
        .split('=')
        .map(s => decodeURIComponent(s.trim()));
      return { key, value };
    });
  }

  get(key) {
    this.refrashDate(key);
    return this._get(key);
  }

  isCognitoValid() {
    return this._getKeyCognito().length === 2;
  }

  removeTokenCognito() {
    this._getKeyCognito().forEach(cookie => {
      this.remove(cookie.key);
    });
  }

  set(key, value) {
    document.cookie = `${encodeURIComponent(
      keyPrefix + key
    )}=${encodeURIComponent(
      value
    )}; expires=${this.getExpireDate().toUTCString()}; Domain=${
      window.env.REACT_APP_COGNITO_COOKIE_DOMAIN
    }; path=/; Secure;`;
    return value;
  }

  clear() {
    this._getKeyCognito().forEach(cookie => {
      this.remove(cookie.key);
    });
  }

  getExpireDate() {
    const date = new Date();
    date.setHours(date.getHours() + 2);
    return date;
  }

  refrashDate(key) {
    const cookie = this._get(key);
    if (cookie) this.set(key, cookie);
  }

  remove(key) {
    document.cookie = `${encodeURIComponent(keyPrefix + key)}=; Domain=${
      window.env.REACT_APP_COGNITO_COOKIE_DOMAIN
    }; Max-Age=0; path=/; Secure;`;
  }
}
