import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import { MASKS } from 'constants/masks';
import { useStepperContext } from 'hooks/useStepper/StepperContext';

import { yupResolver } from '@hookform/resolvers/yup';
import { Button, FormLabel, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';

import history from 'framework/history';

import { AsyncLoad } from 'components/AsyncLoad';
import ControlGroup from 'components/ControlGroup';
import { ConfirmDialog } from 'components/Dialog';
import Form from 'components/Form';
import { useLangContext } from 'components/Globalization';
import MaskedTextField from 'components/MaskedTextField';
import SelectField from 'components/SelectField';
import TextField from 'components/TextField';

import { notificationUseCases } from 'modules/Notification/providers';
import { REQUEST_ACCESS_ROUTES } from 'modules/RequestAccess/constants/routes';
import { useRequestAccessContext } from 'modules/RequestAccess/Context';
import { requestAccessUseCases } from 'modules/RequestAccess/provider';
import ActionsButtons from 'modules/RequestAccess/submodules/Steps/fragments/ActionsButtons';

import guardiansLogo from 'assets/img/guardians.png';

import { initialSchema } from './schema';
import {
  BaloonDetail,
  BaloonDetailContainer,
  InfoImage,
  StyledBoxWithOpacity,
  StyledFormSubtitle,
  StyledFormTitle,
  StyledGrid
} from './styles';

function PersonalInformation() {
  const [isAbleToFillForm, setIsAbleToFillForm] = useState(false);
  const [degreeList, setDegreeList] = useState([]);
  const [disabilityList, setDisabilityList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const translate = useLangContext();
  const { requestData, setRequestData, requestFunctions, isEditing } =
    useRequestAccessContext();
  const { role, cpf: requestedCpf } = requestData || {};

  const { fnNext } = useStepperContext();

  const formMethods = useForm({
    resolver: yupResolver(initialSchema),
    mode: 'onChange',
    defaultValues: {
      ...requestData
    }
  });

  const getDisabilityList = useCallback(async () => {
    try {
      const resp = await requestAccessUseCases.getDisabilityList();

      const disabilityListOptions = resp.map(item => ({
        id: item,
        name: item
      }));

      setDisabilityList(disabilityListOptions);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getDegreeOptionsList = useCallback(async () => {
    try {
      const resp = await requestAccessUseCases.getDegreeOfKinshipList();

      const degreeListOptions = resp?.map(item => ({
        id: item,
        name: item
      }));

      setDegreeList(degreeListOptions);
    } catch (error) {
      console.error(error);
    }
  }, []);

  const { watch } = formMethods;
  const formValues = watch();
  const isFormEmpty = Object.values(formValues).every(value => !value);

  const {
    formState: { isDirty, isValid },
    trigger,
    control
  } = formMethods;

  const isUpperWatch = useWatch({
    control,
    name: `isUpperTwelveYearsOld`
  });

  const hasPhysicalDisabilityWatch = useWatch({
    control,
    name: 'hasPhysicalDisability'
  });

  useEffect(() => {
    trigger('isUpperTwelveYearsOld');
  }, [hasPhysicalDisabilityWatch, trigger]);

  const isValidForm = isValid;

  const submitPersonalInformation = useCallback(
    async data => {
      if (isDirty) {
        const { cpf } = data;
        const isLocalEditing = cpf === requestedCpf;

        const preparedData = {
          ...data,
          hasPhysicalDisability: data.hasPhysicalDisability,
          physicalDisability: data.hasPhysicalDisability
            ? data.physicalDisability
            : []
        };

        const { editRequestStepOne } = requestFunctions;

        const { id } = requestData;

        if (isEditing || isLocalEditing) {
          setRequestData(prevState => ({
            ...prevState,
            ...preparedData,
            role
          }));
          fnNext();
          return;
        }

        try {
          const resp = await editRequestStepOne({
            payload: { ...preparedData },
            id
          });

          setRequestData({ ...resp, role });

          fnNext();
        } catch (error) {
          if (
            error.response?.data?.message === 'student.request.already.started'
          ) {
            setOpenModal(true);
          } else {
            notificationUseCases.error('ERROR:DEFAULT_ERROR');
          }
        }
      } else {
        fnNext();
      }
    },
    [
      isDirty,
      requestedCpf,
      requestFunctions,
      requestData,
      isEditing,
      setRequestData,
      fnNext,
      role
    ]
  );

  const handleBack = () => {
    history.push(REQUEST_ACCESS_ROUTES.IDENTIFY_USER);
  };

  const handleAbleToFillForm = () => {
    setIsAbleToFillForm(true);

    localStorage.setItem('isAbleToFillForm', true);
  };

  useEffect(() => {
    if (!isFormEmpty) {
      setIsAbleToFillForm(true);
    } else {
      setIsAbleToFillForm(false);
    }
  }, [isFormEmpty]);

  useEffect(() => {
    if (!requestData) history.push(REQUEST_ACCESS_ROUTES.SELECT_PROFILE);
  }, [requestData]);

  const getFormValidation = useCallback(() => {
    if (isUpperWatch) {
      return !!formValues.physicalDisability;
    }

    return isValidForm;
  }, [isValidForm, isUpperWatch, formValues]);

  const handleConfirmDialog = useCallback(() => {
    setOpenModal(false);
    history.push(REQUEST_ACCESS_ROUTES.STUDENT_MANAGEMENT);
  }, []);

  return (
    <Box py={3} pl={3} mt={2}>
      <Grid container justifyContent="center" alignItems="center">
        <ConfirmDialog
          open={openModal}
          onConfirm={handleConfirmDialog}
          confirmButtonText={translate('COMMONS:OK')}
        >
          <Box mb={1}>
            <Typography variant="h5">
              {translate(
                'REQUEST_ACCESS_COMMONS:STUDENT_REQUEST_ALREADY_STARTED'
              )}
            </Typography>
          </Box>
        </ConfirmDialog>

        {!isAbleToFillForm && isFormEmpty && (
          <StyledGrid md={10} sm={12} container item>
            <InfoImage src={guardiansLogo} alt="Guardians" />
            <BaloonDetailContainer>
              <BaloonDetail />
            </BaloonDetailContainer>
            <Grid md={8} sm={12} item>
              <Box
                p={3}
                sx={{
                  backgroundColor: '#DBE8FB',
                  boxShadow: '9px 9px 6px 0px rgba(0, 0, 0, 0.16)'
                }}
              >
                <StyledFormTitle
                  variant="h3"
                  component="h1"
                  name={translate('FORM_TITLE')}
                >
                  {translate('FORM_TITLE')}
                </StyledFormTitle>
                <Box mt={3}>
                  <StyledFormSubtitle name={translate('FORM_DESCRIPTION')}>
                    {translate('FORM_DESCRIPTION')}
                  </StyledFormSubtitle>
                </Box>
                <Box mt={3}>
                  <Grid
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Button
                      sx={{
                        fontSize: '14px',
                        lineHeight: '16px'
                      }}
                      variant="contained"
                      onClick={handleAbleToFillForm}
                      aria-label={translate(
                        'ACCESSIBILITY:CONFIRM_GUARDIAN_BUTTON'
                      )}
                    >
                      {translate('FORM_CONFIRM')}
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </StyledGrid>
        )}
        <StyledBoxWithOpacity disabled={!isAbleToFillForm}>
          <Typography
            component="h3"
            variant="h6"
            name={translate('PERSONAL_INFO')}
          >
            {translate('PERSONAL_INFO')}
          </Typography>
          <Box mt={3}>
            <Form
              formMethods={formMethods}
              onSubmit={submitPersonalInformation}
            >
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    disabled={!isAbleToFillForm}
                    name="name"
                    label={translate('FULLNAME')}
                    placeholder={translate('FULLNAME_PLACEHOLDER')}
                    fullWidth
                    formMethods={formMethods}
                    aria-label={translate('FULLNAME_PLACEHOLDER')}
                  />
                </Grid>
                <AsyncLoad promiseFn={getDegreeOptionsList}>
                  <Grid item xs={12}>
                    <SelectField
                      isDisabled={!isAbleToFillForm}
                      name="degreeOfKinship"
                      label={translate('PARANT_DEGREE')}
                      placeholder={translate('PLACEHOLDER')}
                      displayEmpty
                      options={degreeList}
                      renderDynamically={false}
                      optionRenderer={option => translate(option.name)}
                      formMethods={formMethods}
                      aria-label={translate(
                        'ACCESSIBILITY:SELECT.PARANT_DEGREE'
                      )}
                    />
                  </Grid>
                </AsyncLoad>
                <Grid item xs={12}>
                  <MaskedTextField
                    isDisabled={!isAbleToFillForm}
                    name="cpf"
                    label={translate('COMMONS:CPF')}
                    formMethods={formMethods}
                    fullWidth
                    placeholder="000.000.000-00"
                    mask={MASKS.CPF}
                    aria-label={translate('CREATE_USER:DOCUMENT_PLACEHOLDER')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormLabel
                    component="legend"
                    aria-label={translate('STUDENT_DISABILITY')}
                  >
                    {translate('STUDENT_DISABILITY')}
                  </FormLabel>
                  <ControlGroup
                    labelRenderer={({ name }) => translate(name)}
                    formMethods={formMethods}
                    type="radio"
                    options={[
                      { id: true, name: 'COMMONS:YES' },
                      { id: false, name: 'COMMONS:NO' }
                    ]}
                    name="hasPhysicalDisability"
                    row
                    arial-label={translate('STUDENT_DISABILITY')}
                    disabled={!isAbleToFillForm}
                  />
                </Grid>
                {hasPhysicalDisabilityWatch && (
                  <AsyncLoad promiseFn={getDisabilityList}>
                    <Grid item xs={12}>
                      <SelectField
                        name="physicalDisability"
                        label={translate(
                          'REQUEST_ACCESS_COMMONS:DISABILITIES.DISABILITY_DESCRIPTION'
                        )}
                        placeholder={translate('COMMONS:SELECT')}
                        displayEmpty
                        options={disabilityList}
                        optionRenderer={option =>
                          translate(
                            `REQUEST_ACCESS_COMMONS:DISABILITIES.${option.name}`
                          )
                        }
                        renderDynamically={false}
                        formMethods={formMethods}
                        multiple
                        aria-label={translate(
                          'REQUEST_ACCESS_COMMONS:ACCESSIBILITY_PHYSICAL_DEBILITATION'
                        )}
                      />
                    </Grid>
                  </AsyncLoad>
                )}
                <Grid item xs={12}>
                  <FormLabel
                    component="legend"
                    aria-label={translate(
                      'REQUEST_ACCESS_COMMONS:IS_UPPER_TWELVE'
                    )}
                  >
                    {translate('REQUEST_ACCESS_COMMONS:IS_UPPER_TWELVE')}
                  </FormLabel>

                  <ControlGroup
                    labelRenderer={({ name }) => translate(name)}
                    formMethods={formMethods}
                    type="radio"
                    options={[
                      { id: true, name: 'COMMONS:YES' },
                      { id: false, name: 'COMMONS:NO' }
                    ]}
                    name="isUpperTwelveYearsOld"
                    row
                    disabled={!isAbleToFillForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    disabled={!isAbleToFillForm}
                    name="email"
                    label={translate('COMMONS:EMAIL')}
                    placeholder={translate(
                      'REQUEST_ACCESS_COMMONS:EMAIL_PLACEHOLDER'
                    )}
                    fullWidth
                    formMethods={formMethods}
                    aria-label={translate('RESET_PASSWORD:LINK_EMAIL')}
                  />
                </Grid>
              </Grid>
              <ActionsButtons
                confirmButtonDisable={!getFormValidation()}
                backButtonDisable={!isAbleToFillForm}
                customHandleBack={handleBack}
              />
            </Form>
          </Box>
        </StyledBoxWithOpacity>
      </Grid>
    </Box>
  );
}

export default PersonalInformation;
