import React, { useCallback } from 'react';
import { Trans } from 'react-i18next';

import { ArrowForward } from '@mui/icons-material';
import { Box, Container, Grid, Hidden } from '@mui/material';

import history from 'framework/history';
import { ROLES } from 'helpers/roles';

import { AsyncLoad } from 'components/AsyncLoad';
import DownloadApp from 'components/DownloadCards/DownloadApp';
import DownloadDesktop from 'components/DownloadCards/DownloadDesktop';
import { useLangContext } from 'components/Globalization';
import wrapper from 'components/Wrapper';

import { RESOURCES_WORKS_ROUTES } from 'modules/ResourcesWorks/constants/routes';

import { LITERARY_DIDACTIC_WORKS_ROUTES } from './constants/routes';
import { literaryDidacticWorksUseCases } from './provider';
import {
  ThickDivider,
  StyledTitle,
  SubtitleInteractiveReader,
  StyledButtonResourcesWorksTag,
  StyledAlert,
  StyledInfoIcon
} from './styles';

const LiteraryDidacticWorks = ({ role }) => {
  const translate = useLangContext();

  const isTeacher = role === ROLES.ROLE_TEACHER;

  const fetchLicensesInfo = useCallback(async () => {
    try {
      await literaryDidacticWorksUseCases.getLicensesInfo();
    } catch (error) {
      console.error(error);
    }
  }, []);

  const handleResourcesWorks = () => {
    history.push(RESOURCES_WORKS_ROUTES.RESOURCES_WORKS);
  };

  return (
    <Container maxWidth="xl">
      <AsyncLoad promiseFn={fetchLicensesInfo} />
      <StyledTitle component="h1">
        {translate('TITLE_INTERACTIVE_READER')}
      </StyledTitle>
      <ThickDivider />
      <Box mt="32px">
        <SubtitleInteractiveReader variant="h6" component="h2">
          {translate('TEXT_DOWNLOAD')}
        </SubtitleInteractiveReader>
      </Box>

      <Box mt="24px">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <DownloadApp />
          </Grid>
          <Hidden mdDown>
            <Grid item sm={6}>
              <DownloadDesktop />
            </Grid>
          </Hidden>
        </Grid>
      </Box>

      {isTeacher && (
        <StyledAlert
          icon={<StyledInfoIcon />}
          severity="info"
          action={
            <StyledButtonResourcesWorksTag
              endIcon={<ArrowForward />}
              onClick={handleResourcesWorks}
            >
              {translate('TITLE_RESOURCES_WORKS')}
            </StyledButtonResourcesWorksTag>
          }
        >
          <Trans
            t={translate}
            i18nKey="RESOURCES_WORKS_ACCESS"
            components={{ strong: <strong /> }}
          />
        </StyledAlert>
      )}
    </Container>
  );
};

export default wrapper(LiteraryDidacticWorks, {
  namespace: 'LITERARY_DIDACTIC_WORKS',
  mapState: state => ({
    role: state.auth.role
  }),
  route: {
    path: LITERARY_DIDACTIC_WORKS_ROUTES.LITERARY_DIDACTIC_WORKS,
    breadcrumb: 'LITERARY_DIDACTIC_WORKS'
  }
});
