import React from 'react';

import { v4 as uuidv4 } from 'uuid';

import { Skeleton } from '@mui/material';

const HEADER = 90;
const ROW = 40;

const SKELETON_LINES = [HEADER, ROW, ROW, ROW, ROW];

const TableSkeleton = () => (
  <>
    {SKELETON_LINES.map(height => (
      <Skeleton key={uuidv4()} height={height} width="100%" />
    ))}
  </>
);

export default TableSkeleton;
