import React, { useRef } from 'react';
import { useController } from 'react-hook-form';

import { get, isArray } from 'lodash';

import {
  Radio,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Typography,
  Divider,
  Checkbox,
  Switch
} from '@mui/material';

import { useLangContext } from 'components/Globalization';

import { ErrorHelperText, StyledErrorIcon } from './styles';

const controlTypes = {
  radio: Radio,
  checkbox: Checkbox,
  switch: Switch
};

const ControlGroup = ({
  formMethods,
  defaultValue: defaultValueProp,
  name,
  type = 'radio',
  label,
  row = false,
  options = [],
  idKey = 'id',
  labelKey = 'name',
  onChange: onChangeProp = () => {
    /* do nothing */
  },
  valueParser = value => value,
  labelRenderer,
  disabled = false,
  className,
  dynamicClassName = () => {},
  withDivider = false
}) => {
  const ControlType = controlTypes[type] || controlTypes.radio;
  const defaultValue =
    defaultValueProp || (ControlType === controlTypes.radio ? '' : []);

  const { control, errors } = formMethods;
  const inputRef = useRef();
  const translate = useLangContext();

  let hasError = false;
  let errorMessage = null;
  const error = get(errors, name);

  if (error) {
    hasError = true;
    errorMessage = translate(error.message);
  }

  const {
    field: { value, onChange, onBlur }
  } = useController({
    name,
    control,
    defaultValue
  });

  const handleCheck = (checkedId, currValue) => {
    const ids = currValue;
    const newIds = ids?.includes(checkedId)
      ? ids?.filter(id => id !== checkedId)
      : [...(ids ?? []), checkedId];
    return newIds;
  };

  const handleChange = (e, option) => {
    if (['checkbox', 'switch'].includes(type)) {
      const val = handleCheck(String(option[idKey]), value);
      const parsedVal = valueParser(val);
      onChange(parsedVal);
      onChangeProp(parsedVal);
      onBlur();
    } else {
      const parsedVal = valueParser(option[idKey]);
      onChange(parsedVal);
      onChangeProp(parsedVal);
      onBlur();
    }
  };

  const isChecked = option => {
    const optionValue = String(option[idKey]);
    if (isArray(value)) {
      return value.includes(optionValue);
    }

    return String(value) === optionValue;
  };

  return (
    <FormControl
      component="div"
      error={hasError}
      disabled={disabled}
      className={className}
    >
      {label ? (
        <FormLabel component="legend" focused={false}>
          {label}
        </FormLabel>
      ) : null}
      <FormGroup row={row}>
        {options.map((option, index) => (
          <>
            <FormControlLabel
              className={dynamicClassName(option, value) ?? ''}
              key={option[idKey]}
              checked={isChecked(option)}
              value={String(option[idKey])}
              onChange={e => handleChange(e, option)}
              control={<ControlType name={name} />}
              disabled={disabled}
              label={
                <Typography variant="body2">
                  {labelRenderer
                    ? labelRenderer(option, index)
                    : option[labelKey]}
                </Typography>
              }
              inputRef={inputRef}
            />
            {withDivider && index < options.length - 1 ? (
              <Divider style={{ margin: '16px 0px' }} />
            ) : null}
          </>
        ))}
      </FormGroup>

      {errorMessage ? (
        <ErrorHelperText>
          <StyledErrorIcon />
          {errorMessage}
        </ErrorHelperText>
      ) : null}
    </FormControl>
  );
};

export default ControlGroup;
