import { ROLES } from 'helpers/roles';

import logoGuardians from 'assets/img/logo-guardians.png';
import logoStudent from 'assets/img/logo-student.png';
import logoTeacher from 'assets/img/logo-teacher.png';

import { requestAccessUseCases } from '../provider';
import titlesGuardian from '../submodules/RequestAccessGuardian/constants/sectionTitle';
import stepsGuardians from '../submodules/RequestAccessGuardian/constants/steps';
import guardiansSummarySection from '../submodules/RequestAccessGuardian/constants/summarySections';
import titlesStudent from '../submodules/RequestAccessStudent/constants/sectionTitle';
import stepsStudent from '../submodules/RequestAccessStudent/constants/steps';
import studentSummarySection from '../submodules/RequestAccessStudent/constants/summarySections';
import titlesTeacher from '../submodules/RequestAccessTeacher/constants/sectionTitle';
import stepsTeacher from '../submodules/RequestAccessTeacher/constants/steps';
import teacherSummarySections from '../submodules/RequestAccessTeacher/constants/summarySections';
import { REQUEST_ACCESS_ROUTES } from './routes';

const studentFunctions = {
  editRequestLocality: payload =>
    requestAccessUseCases.putRequestAccessStudentLocality(payload),
  editRequestSchool: payload =>
    requestAccessUseCases.putRequestAccessStudentSchool(payload),
  editRequestLearningPhase: payload =>
    requestAccessUseCases.putRequestAccessStudentLearningPhase(payload),
  finish: payload =>
    requestAccessUseCases.putRequestAccessStudentFinish(payload)
};

const ROLES_MAP = [
  {
    role: ROLES.ROLE_STUDENT,
    requestPage: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_STUDENT,
    name: 'STUDENT',
    image: logoStudent,
    description: 'STUDENT_DESCRIPTION',
    managementPage: REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED,
    fetchData: () => requestAccessUseCases.getRequestAccessStudent(),
    editRequest: payload =>
      requestAccessUseCases.putRequestAccessStudent(payload),
    createRequest: payload =>
      requestAccessUseCases.postRequestAccessStudent(payload),
    editRequestEmail: payload =>
      requestAccessUseCases.putRequestAccessStudent(payload),
    editAllData: payload =>
      requestAccessUseCases.putRequestAccessStudentAllData(payload),
    ...studentFunctions,
    summary: {
      redirect: REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED,
      titles: titlesStudent,
      sections: studentSummarySection,
      steps: stepsStudent,
      submitFn: payload =>
        requestAccessUseCases.putRequestAccessStudentFinish(payload)
    }
  },
  {
    role: ROLES.ROLE_TEACHER,
    requestPage: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_TEACHER,
    name: 'TEACHER',
    image: logoTeacher,
    description: 'TEACHER_DESCRIPTION',
    managementPage: REQUEST_ACCESS_ROUTES.SCHOOL_MANAGEMENT,
    fetchData: () => requestAccessUseCases.getRequestAccessTeacher(),
    createRequest: payload =>
      requestAccessUseCases.postRequestAccessTeacher(payload),
    editRequest: payload =>
      requestAccessUseCases.putRequestAccessTeacher(payload),
    deleteRequest: payload =>
      requestAccessUseCases.deleteRequestAccessTeacher(payload),
    editRequestEmail: payload =>
      requestAccessUseCases.putRequestAccessTeacherEmail(payload),
    editRequestLocality: payload =>
      requestAccessUseCases.putRequestAccessTeacherLocality(payload),
    editAllData: payload =>
      requestAccessUseCases.putRequestAccessTeacherAllData(payload),
    editRequestLearningPhase: payload =>
      requestAccessUseCases.putRequestAccessTeacherLearningPhase(payload),
    editRequestSchool: payload =>
      requestAccessUseCases.putRequestAccessTeacherSchool(payload),
    summary: {
      redirect: REQUEST_ACCESS_ROUTES.REQUEST_COMPLETED,
      sections: teacherSummarySections,
      titles: titlesTeacher,
      steps: stepsTeacher,
      submitFn: payload =>
        requestAccessUseCases.putRequestAccessTeacherFinish(payload)
    }
  },
  {
    role: ROLES.ROLE_GUARDIAN,
    requestPage: REQUEST_ACCESS_ROUTES.REQUEST_ACCESS_GUARDIAN,
    name: 'GUARDIAN',
    image: logoGuardians,
    description: 'GUARDIAN_DESCRIPTION',
    managementPage: REQUEST_ACCESS_ROUTES.STUDENT_MANAGEMENT,
    fetchData: () => requestAccessUseCases.getRequestAccessGuardians(),
    editRequest: payload =>
      requestAccessUseCases.putRequestAccessGuardians(payload),
    createRequest: payload =>
      requestAccessUseCases.postRequestAccessGuardians(payload),
    editRequestStepOne: payload =>
      requestAccessUseCases.putRequestAccessGuardians(payload),
    editRequestEmail: payload =>
      requestAccessUseCases.putRequestAccessGuardiansEmail(payload),
    editAllData: payload =>
      requestAccessUseCases.putRequestAccessGuardiansAllData(payload),
    editRequestConsentTerms: payload =>
      requestAccessUseCases.putRequestAccessGuardiansConsentTerms(payload),
    deleteRequest: payload =>
      requestAccessUseCases.deleteRequestAccessGuardians(payload),
    ...studentFunctions,
    summary: {
      redirect: REQUEST_ACCESS_ROUTES.CONSENT_AGREEMENT,
      sections: guardiansSummarySection,
      titles: titlesGuardian,
      steps: stepsGuardians,
      submitFn: payload =>
        requestAccessUseCases.putRequestAccessGuardiansVerifyInformation(
          payload
        )
    }
  }
];

export default ROLES_MAP;
